import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import bootbox from 'bootbox';
import SessionService from '../../services/sessionService';
import { newRequestSocketEmit } from '../../socket';
import { successToastr, errorToastr } from '../../utils/handelError';
import config from '../../config';
import ButtonLoader from '../loaders/buttonLoader';

class RequestBoxButton extends Component {

  constructor(props) {
    super(props)

    this.state = {
      requestStatus: '',
      isLoading: false,
      userIndexInMediaPermission: -1,
      audioTimeLimit: 'open'
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.whiteBoardReduxState.activeWhiteBoardOwner.mediaPermissions !== this.props.whiteBoardReduxState.activeWhiteBoardOwner.mediaPermissions) {
      this.updateRequestStatus()
    }
  }

  updateRequestStatus = () => {

    const { whiteBoardReduxState: { activeWhiteBoardOwner }, userReduxState } = this.props

    let tempMediaPermissions = activeWhiteBoardOwner.mediaPermissions

    let getAttendeeIndex = tempMediaPermissions.findIndex(obj => (Number(obj.userId) === (Number(userReduxState.userId)) && obj.userType === 'attendee'))

    let requestStatus = ''
    let userIndexInMediaPermission = -1
    let audioTimeLimit = 'open'
    if (
      getAttendeeIndex > -1
    ) {
      requestStatus = 'Approved'
      userIndexInMediaPermission = getAttendeeIndex
      audioTimeLimit = activeWhiteBoardOwner.mediaPermissions[getAttendeeIndex].audioTimeLimit
    }

    this.setState({
      requestStatus,
      audioTimeLimit,
      userIndexInMediaPermission,
    })
  }

  sendRequest = () => {
    bootbox.confirm({
      message: config.langLabels.sendConfirm,
      centerVertical: true,
      buttons: {
        confirm: {
          label: 'Yes',
          className: 'btn-primary ripple-effect'
        },
        cancel: {
          label: 'No',
          className: 'btn-dark ripple-effect'
        }
      },
      callback: async (result) => {
        if (result) {
          try {
            let rawData = {
              userId: this.props.userReduxState.userId,
              name: this.props.userReduxState.name,
              sessionId: this.props.userReduxState.sessionInfo.id
            }
            let requestResult = await SessionService.sendRequest(this.props.userReduxState.sessionInfo.id, rawData);
            if (requestResult.data.success) {
              this.setState({
                requestStatus: 'Pending',
                isLoading: false
              });

              if (requestResult.data.data === true) {
                errorToastr(config.langLabels.reqInPendingList)
              } else {
                newRequestSocketEmit(requestResult.data.data);

                successToastr(config.langLabels.requestSent);
              }
            }
          } catch (error) {
            errorToastr(config.langLabels.pleaseTryAgain);
          }
        }
        this.setState({
          isLoading: false
        });
      }
    });
  }

  render() {
    const { requestStatus, isLoading, userIndexInMediaPermission, audioTimeLimit } = this.state
    const { whiteBoardReduxState: { activeWhiteBoardOwner }, userReduxState } = this.props

    if (userReduxState.userType !== 'attendee') {
      return <></>
    }

    return (
      <div className="requestWrapper text-center">
        {
          userReduxState.userType === 'attendee' && <>
            {
              requestStatus === '' && <Link to=""
                onClick={(e) => {
                  e.preventDefault()
                  if (!isLoading) {
                    this.setState({
                      isLoading: true
                    }, () => {
                      this.sendRequest();
                    })
                  }
                }}
                className="requestBox">
                <span classs="font-hrg d-inline-block align-middle">{config.langLabels.sendVoiceReq}</span>
                <i className="icon-voice d-inline-block align-middle"></i>
                {
                  isLoading && <ButtonLoader />
                }
              </Link>
            }

            {
              requestStatus === 'Pending' && <Link
                to=""
                className="watingBox"
                onClick={(e) => {
                  e.preventDefault()
                }}
              >
                <span classs="font-hrg d-inline-block align-middle">{config.langLabels.reqVoiceInWaiting}</span>
                <i className="icon-clock d-inline-block align-middle"></i>
              </Link>
            }

            {
              requestStatus === 'Approved' && <Link
                to=""
                className="acceptBox"
                onClick={(e) => {
                  e.preventDefault()
                }}
              >
                <span classs="font-hrg d-inline-block align-middle">
                  {config.langLabels.reqVocalAccepted((userIndexInMediaPermission > -1 && audioTimeLimit !== 'open') ? config.langLabels.timeInminutes(Number(audioTimeLimit)) : config.langLabels.open)}
                </span>
                <i className="icon-check-circle d-inline-block align-middle"></i>
              </Link>
            }
          </>
        }
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps,
    chatReduxState: state.chatProps,
    whiteBoardReduxState: state.whiteBoardProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestBoxButton)