import React, { Component } from 'react'
import { updateUser } from '../../redux/actions/userAction';
import { connect } from 'react-redux';
import { extendTimeSocketEmit } from '../../socket';
import { successToastr } from '../../utils/handelError';
import config from '../../config';
import { hideBootstrapModal } from '../../utils/commonJsFunctions';
import SessionService from '../../services/sessionService';

class ExtendTimeModal extends Component {

  state = {
    btnStatus: false,
    extendTime: 0
  }

  extendTime = async () => {
    if (this.state.extendTime) {
      this.setState({
        btnStatus: true
      })
      let userReduxState = this.props.userReduxState;
      userReduxState.sessionDuration = userReduxState.sessionDuration + this.state.extendTime
      let updateBoardIdRes = await SessionService.updateActiveBoardId(userReduxState.sessionInfo.id, { sessionDuration: userReduxState.sessionDuration })
      if (updateBoardIdRes.data.success) {
        this.props.updateUserState(userReduxState);
        successToastr(config.langLabels.timeExtended);
        this.setState({
          extendTime: 0,
          btnStatus: false
        }, () => {
          hideBootstrapModal('extendTimeModal')
          extendTimeSocketEmit({ sessionDuration: userReduxState.sessionDuration });
        })
      }
    }
  }

  render() {

    return (
      <div className="modal fade" id="extendTimeModal" tabIndex="-1" role="dialog" aria-labelledby="extendTimeModal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered w-570 extendtime-modal" role="document">
          <div className="modal-content bg-grey">
            <div className="modal-header border-bottom-0 pb-0">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="icon-close"></i>
              </button>
              <h5 className="modal-title font-neomd">{config.langLabels.toolTipExtendTime}</h5>
            </div>
            <div className="modal-body text-center" dir="rtl">
              <form className="timeList mt-4">
                <label htmlFor="extendtime1">
                  <input type="radio" onClick={(e) => {
                    this.setState({
                      extendTime: 5
                    })
                  }} name="time" id="extendtime1" className="d-none" />
                  <span>{config.langLabels.timeInminutes(5)}</span>
                </label>

                <label htmlFor="extendtime2">
                  <input type="radio" onClick={(e) => {
                    this.setState({
                      extendTime: 10
                    })
                  }} name="time" id="extendtime2" className="d-none" />
                  <span>{config.langLabels.timeInminutes(10)}</span>
                </label>

                <label htmlFor="extendtime3">
                  <input onClick={(e) => {
                    this.setState({
                      extendTime: 15
                    })
                  }} type="radio" name="time" id="extendtime3" className="d-none" />
                  <span>{config.langLabels.timeInminutes(15)}</span>
                </label>

                <label htmlFor="extendtime4">
                  <input type="radio" onClick={(e) => {
                    this.setState({
                      extendTime: 20
                    })
                  }} name="time" id="extendtime4" className="d-none" />
                  <span>{config.langLabels.timeInminutes(20)}</span>
                </label>
                <label htmlFor="extendtime8">
                  <input type="radio" onClick={(e) => {
                    this.setState({
                      extendTime: 25
                    })
                  }} name="time" id="extendtime8" className="d-none" />
                  <span>{config.langLabels.timeInminutes(25)}</span>
                </label>

                <label htmlFor="extendtime7">
                  <input type="radio" onClick={(e) => {
                    this.setState({
                      extendTime: 30
                    })
                  }} name="time" id="extendtime7" className="d-none" />
                  <span>{config.langLabels.timeInminutes(30)}</span>
                </label>
                <label htmlFor="extendtime6">
                  <input type="radio" onClick={(e) => {
                    this.setState({
                      extendTime: 35
                    })
                  }} name="time" id="extendtime6" className="d-none" />
                  <span>{config.langLabels.timeInminutes(35)}</span>
                </label>
                <label htmlFor="extendtime5">
                  <input type="radio" onClick={(e) => {
                    this.setState({
                      extendTime: 40
                    })
                  }} name="time" id="extendtime5" className="d-none" />
                  <span>{config.langLabels.timeInminutes(40)}</span>
                </label>

                {/* <a href="" className="messageLink d-inline-block font-hrg">
                  <i className="icon-chat"></i> <span> Need more ? Ask the support </span>
                </a> */}

                <div className="btn-row">
                  <button type="button"
                    disabled={this.state.btnStatus}
                    onClick={(e) => {
                      this.extendTime()
                    }} className="btn btn-primary ripple-effect w_170">{config.langLabels.save}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updateUserState: data => dispatch(updateUser(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtendTimeModal)