import { ADD_WHITE_BOARD, REMOVE_WHITE_BOARD, NEW_ACTIVE_WHITE_BOARD, NEW_ACTIVE_WHITE_BOARD_OWNER, RESET_WHITE_BOARD, UPDATE_IS_RECORDING_ON, UPDATE_START_SCREEN_SHARE } from "../reduxConst/whiteBoardConst";

export const addWhiteBoard = (data = '') => {
  return {
    type: ADD_WHITE_BOARD,
    data
  }
}

export const removeWhiteBoard = (data) => {
  return {
    type: REMOVE_WHITE_BOARD,
    data
  }
}

export const newActiveWhiteBoard = (data) => {
  return {
    type: NEW_ACTIVE_WHITE_BOARD,
    data
  }
}

export const newActiveWhiteBoardOwner = (data) => {
  return {
    type: NEW_ACTIVE_WHITE_BOARD_OWNER,
    data
  }
}

export const resetWhiteBoard = (data) => {
  return {
    type: RESET_WHITE_BOARD,
    data
  }
}

export const updateRecordingOn = (data) => {
  return {
    type: UPDATE_IS_RECORDING_ON,
    data
  }
}

export const updateStartScreenShare = (data) => {
  return {
    type: UPDATE_START_SCREEN_SHARE,
    data
  }
}