import React, { Component } from 'react'
import { connect } from 'react-redux';
import { toolTypeData } from '../../utils/toolData';
import { updateToolType } from '../../redux/actions/toolTypeAction';
import { resetReactModalForm, hideBootstrapModal } from '../../utils/commonJsFunctions'
import { regexValidURL, getSiteTypeURL, addHttpsURL } from '../../utils/customValidator'
import { addWhiteboardSocketEmit, iframeWebsiteSocketEmit } from '../../socket';
import SessionService from '../../services/sessionService';
import { addWhiteBoard } from '../../redux/actions/whiteBoardAction';
import { nowTimestamp } from '../../utils/customTimeFunctions';
import { errorToastr } from '../../utils/handelError';
import config from '../../config';
/**
 * add website modal
 */
class AddWebsiteModal extends Component {

  constructor(props) {
    super(props)

    this.state = {
      webUrl: '',
      btnLoader: false
    }
  }

  componentDidMount() {
    resetReactModalForm('addWebsitesModal', this.setWebURL)
  }

  setWebURL = (webUrl = '') => {
    this.setState({
      webUrl
    })
  }

  updateToolTypeInRedux = async (tool = '', toolOptions = {}) => {
    let result = await this.addNewBoard(toolOptions.url_video);
    if (result) {
      let data = toolTypeData(tool, toolOptions, this.props.toolTypeReduxState)
      this.props.updateToolTypeState(data)
      this.setWebURL()
      iframeWebsiteSocketEmit({ tool: tool, toolOptions: toolOptions });
    }
    hideBootstrapModal('addWebsitesModal')
  }

  addNewBoard = async (title) => {
    const { userReduxState: { sessionInfo: { id } }, whiteBoardReduxState: { whiteBoards } } = this.props
    if (whiteBoards.length < 10) {
      this.setState({
        btnLoader: true
      })
      let boardId = nowTimestamp();
      let boardType = getSiteTypeURL(title)
      let payload = {
        boardId: boardId,
        title: title,
        boardType: boardType,
        frameData: title
      }
      let resAddNewBoard = await SessionService.addNewBoard(id, payload)
      if (resAddNewBoard.data.success) {
        this.setState({
          btnLoader: false
        })
        this.props.addWhiteBoardInRedux(payload)
        addWhiteboardSocketEmit(payload);
        return true;
      }
    } else {
      errorToastr(config.langLabels.boardAddLimitExceed)
    }
    return false;
  }

  render() {

    const { webUrl, btnLoader } = this.state

    return (
      <div className="modal fade" id="addWebsitesModal" tabIndex="-1" role="dialog" aria-labelledby="addWebsitesModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered w-570 add-video-modal" role="document">
          <div className="modal-content bg-grey">
            <div className="modal-header border-bottom-0 pb-0">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="icon-close"></i>
              </button>
              <h5 className="modal-title font-neomd">{config.langLabels.addWebsite}</h5>
            </div>
            <div className="modal-body pt-2" dir="rtl">
              <p className="font-hrg title text-center">{config.langLabels.websiteInfoMsg}</p>

              <form>
                <div className="form-group">

                  <input
                    type="text"
                    className="form-control"
                    placeholder={config.langLabels.websiteLink}
                    value={webUrl}
                    dir="ltr"
                    onChange={(e) => {
                      this.setWebURL(e.target.value)
                    }}
                  />

                  <button value="Refresh" type="button" className="valueRefresh p-0 btn btn-light bg-transparent border-0">
                    <i className="icon-cancel"></i>
                  </button>
                  {
                    (webUrl.length > 0 && !regexValidURL(webUrl)) && <span className="text-danger">{config.langLabels.errMsgValidWebsite}</span>
                  }
                </div>

                {
                  regexValidURL(webUrl) && <div className="previewVideo d-flex align-items-center">
                    <div className="previewVideo__videobox">
                      {
                        regexValidURL(webUrl) && <iframe title="preview-frame" src={addHttpsURL(webUrl)}></iframe>
                      }
                    </div>
                    {/* <div className="previewVideo__videodetail text-right">
                    <h3 className="font-neomd">Distance Learning Center - Faculty of Forensic Sciences</h3>
                    <p className="font-hrg">Interested in studying forensic science online? We offer certificate and undergraduate programs</p>
                  </div> */}
                  </div>
                }

                <div className="text-center btn-row">
                  <button
                    className="btn btn-dark ripple-effect w_170 ml-2"
                    type="button"
                    data-target="#addModal"
                    data-toggle="modal"
                    onClick={() => hideBootstrapModal('addWebsitesModal')}
                  >{config.langLabels.back}</button>
                  <button
                    disabled={btnLoader}
                    className="btn btn-primary ripple-effect w_170"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      if (regexValidURL(webUrl)) {
                        this.updateToolTypeInRedux('video', { url_video: addHttpsURL(webUrl) })
                      }
                    }}
                  >
                    {config.langLabels.addWebsite}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    toolTypeReduxState: state.toolTypeProps,
    whiteBoardReduxState: state.whiteBoardProps,
    userReduxState: state.userProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updateToolTypeState: data => dispatch(updateToolType(data)),
    addWhiteBoardInRedux: (data) => dispatch(addWhiteBoard(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddWebsiteModal)
