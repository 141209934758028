const engLang = {
  sessionAuth: "Session authentication failed.",
  sessionNotStarted: "Session Not Started Yet",
  sessionNotFound: "Session link not exist",
  pollPosted: "Poll published successfully.",
  pollFailed: "Poll posting failed.",
  pollAnswerPosted: "Answer submitted successfully.",
  timeExtended: "Time extended successfully.",
  endSessionMesg: "Are you sure you want to end session?",
  sendConfirm: "Are you sure request will be sent to teacher?",
  requestSent: "Vocal request sent successfully.",
  documentUploaded: "Document added successfully.",
  timeExtendRequested: "Time extended request sent.",
  changingBoardMsg: "Changing board please wait",
  sessionCompleted: "Session completed",
  preSessionStartMsg: "Session will start shortly",
  pleaseTryAgain: "Please try again after some time",
  searchAttendee: "Search Attendee",
  reqInPendingList: "Your request is already in pending list",
  sendVoiceReq: "Send Vocal Request",
  reqVoiceInWaiting: "Sent, Waiting For approve",
  boardAddLimitExceed: "Board add limit exceeded",
  allowedVideosFrom: "Accepts to add video from : Facebook, youtube, twitter",
  websiteInfoMsg: "You can browse the site and browse in the blackboard",
  viewResStudents: "View results for students",
  allowDocMsg: "Accepts to add document from : Word, Excel, PDF, Text and images",
  allowPptMsg: "Accepts only presentation file",
  extTimeReqMsg: "Need more help? Ask the support",
  sessionHoldMsg: "Oops!!! Preseter is offline till presenter back online session is on the hold",
  presenterArrived: "Presenter Arrived & Session Started",
  alertClearAllBoard: "Are you sure you want to clear whiteboard?",
  alertDeleteBoard: "Are you sure you want to delete this whiteboard?",
  alertEnterBoardName: "Please enter the Board Name",
  alertFolderName: "Please enter the folder title",
  alertRenameFolder: "Please enter the new folder title",
  alertRenameFile: "Please enter the new file name",
  alertDeleteFolder: "Are you sure you want to delete the selected folder",
  alertDeleteFile: "Are you sure you want to delete the selected file",

  errMsgValidVideoURL: "Please enter valid Youtube/Facebook/Twitter URL",
  errMsgValidWebsite: "Please enter valid website URL",
  errMsgQField: "Question field is required",
  errMsgOptionField: "Option field is required",
  errMsgSelectFile: "Sorry, Please select other file",
  errMsgNoFolder: "Sorry, We didn't found any folders of you created",
  errMsgNoFile: "Sorry, We didn't found any files in this Folder",
  errMsgMedia: "Please allow Camera and Microphone permission",
  errScreenShareStop: "Screen share stoped due to network connection",
  errMsgOnJoin: "Please wait for presenter to start the session",
  errMsgOnAlreadyJoin: "Session already joined",
  pnf: "404 - The Page can't be found",

  attendance: "Attendance",
  chat: "Chat",
  available: "Avaliable",
  open: "Open",
  absent: "Absent",
  requests: "Requests",
  questions: "Questions",
  all: "All",
  newBoard: "New Board",
  deleteBoard: "Delete",
  reply: "Reply",
  addContent: "Add Content",
  videos: "Videos",
  documents: "Documents",
  polls: "Polls",
  poll: "Poll",
  presentation: "Presentation",
  websiteSurfing: "Website Surfing",
  browseUploadLib: "Browse & upload library",
  addVideo: "Add Video",
  back: "Back",
  videoLink: "Video Link",
  addWebsite: "Add website",
  websiteLink: "Website link",
  option: "Option",
  addAns: "Add an answer",
  addQ: "Add a questionnaire",
  choices: "Choices",
  closeQ: "Close questionnaire",
  sendQ: "Send the questionnaire",
  aQuestion: "A questionnaire",
  resultPublished: "Result published",
  folderCreated: "Folder created",
  folderdeleted: "Folder deleted successfully",
  folderRename: "Folder renamed successfully",
  fileUploaded: "File uploaded",
  filedeleted: "File deleted successfully",
  fileRename: "File renamed successfully",
  library: "Library",
  searchFilePlaceholder: "Search for a file",
  files: "Files",
  delete: "Delete",
  deleteFolder: "Delete Folder",
  deleteFile: "Delete File",
  rename: "Rename",
  uploadFile: "Upload file",
  addFileOnBoard: "Add file on board",
  addDoc: "Add Document",
  addPPT: "Add Presentation",
  save: "Save",
  submit: "Submit",
  selectOneOpt: "Options : Select one",
  pleaseWait: "Please wait",
  setting: "Setting",
  videoSound: "Video & sound",
  testConnection: "Test Connection",
  testCompleted: "Test Completed",
  browser: "Browser",
  camMic: "Camera/Mic",
  downloadSpeed: "Download Speed",
  uploadspeed: "Upload speed",
  loading: "Loading",
  oops: "Oops!",
  addingNewBoard: "Adding new board",

  toolTipEndSession: "End Session",
  toolTipSettings: "Settings",
  toolTipChat: "Chat",
  toolTipFullScreen: "Full screen",
  toolTipHideShowAllAudios: "Hide/Show all audios",
  toolTipHideShowAllVideos: "Hide/Show all videos",
  toolTipExtendTime: "Extend Time",
  toolTipStopScreenShare: "Stop screen share",
  toolTipClearBoard: "Clear Board",
  toolTipUndo: "Undo",
  toolTipRedo: "Redo",
  toolTipColorPicker: "Color Picker",
  toolTipTable: "Table",
  toolTipArrows: "Arrows",
  toolTipDoubleHeadArrow: "Double head arrow",
  toolTipCurvedArrow: "Curved arrow",
  toolTipSingleHeadArrow: "Single head arrow",
  toolTipLine: "Line",
  toolTipShapes: "Shapes",
  toolTipHeartFill: "Heart Fill",
  toolTipPentagonFill: "Pentagon Fill",
  toolTipTriangleFill: "Triangle Fill",
  toolTipSquareFill: "Square Fill",
  toolTipPolygonFill: "Polygon Fill",
  toolTipFontagonFill: "Diamond Fill",
  toolTipCircleFill: "Circle Fill",
  toolTipRectangleFill: "Rectangle Fill",
  toolTipHeartOutline: "Heart Outline",
  toolTipPentagonOutline: "Pentagon Outline",
  toolTipTriangleOutline: "Triangle Outline",
  toolTipSquareOutline: "Square Outline",
  toolTipPolygonOutline: "Polygon Outline",
  toolTipFontagonOutline: "Diamond Outline",
  toolTipCircleOutline: "Circle Outline",
  toolTipRectangleOutline: "Rectangle Outline",
  toolTipEraser: "Eraser",
  toolTipHighliter: "Highliter",
  toolTipText: "Text",
  toolTipPencil: "Pencil",
  toolTipSelector: "Selector",

  audioVideoLimitExceed: (mediaType) => `Limit exceed for ${mediaType} add`,
  minutesInTime: (time) => `Minutes ${time}`,
  timeInminutes: (time) => `${time} Minutes`,
  sessionStartAfter: (time) => `Session will start after ${time}`,
  reqFrom: (name) => `Request From ${name}`,
  reqVocalAccepted: (time) => `Accepted, ${time}`,
  percentageCompleted: (fileName, percent) => `${fileName} - ${percent}% complete`,
  extDurationReq: (time) => `Extend Duration Requested : ${time} Minutes`
}

export default engLang;