import React, { Component } from 'react'
import { connect } from 'react-redux';
import kurentoUtils from "kurento-utils";
import { Link } from "react-router-dom";
import $ from 'jquery';
import bootbox from 'bootbox';
import {
  startPresenterCallVideoSocketOn,
  iceCandidateSocketOn,
  onIceCandidateSocketEmit,
  videoFromUserSocketEmit,
  presenterArrivedSocketOn,
  joinRoomAttendeeSocketEmit,
  newUserArrivedSocketOn,
  existingUsersSocketOn,
  receiveVideoAnswerSocketOn,
  participantLeftSocketOn,
  disConnectSocketOn,
  allowMediaSocketEmit,
  allowMediaSocketOn,
  allowMediaFromSocketOn,
  stopMediaSocketOn,
  stopMediaSelfSocketOn,
  stopMediaFromSocketOn,
  stopMediaSocketEmit,
  sessionOnHoldSocketOn,
  mediaFromUsersSocketOn,
  afterJoinRoomSocketOn,
  activeWhiteboardOwnerSocketEmit
} from '../../socket';
import { newActiveWhiteBoardOwner } from '../../redux/actions/whiteBoardAction';
import config from '../../config';
import AudioGrantedView from '../audioGrantedView';
import { updatePresenterVideoMute } from '../../redux/actions/audioVideoAction';
import { errorToastr } from '../../utils/handelError';
import { pageLoader } from '../../utils/commonJsFunctions';

var users = [];


function onError(error) {
  console.log(error);
}

function showSpinner() {
  for (var i = 0; i < arguments.length; i++) {
    arguments[i].poster = '/assets/images/transparent-1px.png';
    arguments[i].style.background = 'center 28px transparent url("/assets/images/spinner.gif") no-repeat';
  }
}

function hideSpinner() {
  for (var i = 0; i < arguments.length; i++) {
    arguments[i].src = '';
    arguments[i].poster = '/assets/images/webrtc.png';
    arguments[i].style.background = '';
  }
}

class GroupCallVideo extends Component {

  state = {
    videoScreen: false,
    participants: [],
    receivePeers: {},
    isOwnVideoAllow: false,
    isOwnAudioAllow: false,
    isMediaAllow: false,
    videoEnabled: true,
    audioEnabled: true,
    isMuted: true
  }

  componentDidMount() {
    if (Number(this.props.userReduxState.userId) !== Number(this.props.userReduxState.sessionId)) {
      startPresenterCallVideoSocketOn(this.startStream);
    }
    iceCandidateSocketOn(this.iceCandidateOnSocket);
    presenterArrivedSocketOn(this.presenterArrived);
    newUserArrivedSocketOn(this.newUserArrived);
    existingUsersSocketOn(this.existingUsers);
    receiveVideoAnswerSocketOn(this.receiveVideoAnswer);
    participantLeftSocketOn(this.participantLeft);
    disConnectSocketOn(this.disConnect);
    allowMediaSocketOn(this.allowMedia);
    allowMediaFromSocketOn(this.allowMediaFrom);
    stopMediaSocketOn(this.stopMedia);
    stopMediaSelfSocketOn(this.stopMediaSelf);
    stopMediaFromSocketOn(this.stopMediaFrom);
    sessionOnHoldSocketOn(this.sessionOnHold);
    mediaFromUsersSocketOn(this.mediaFromUsers);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.audioVideoReduxState.presenterVideoMute !== this.props.audioVideoReduxState.presenterVideoMute) {
      this.setState({
        isMuted: this.props.audioVideoReduxState.presenterVideoMute
      })
    }

    if (prevProps.whiteBoardReduxState.activeWhiteBoardOwner.actionTaken !== this.props.whiteBoardReduxState.activeWhiteBoardOwner.actionTaken) {
      this.setState({
        participants: this.props.whiteBoardReduxState.activeWhiteBoardOwner.mediaPermissions
      }, () => {
        const { userReduxState } = this.props;
        let actionData = this.props.whiteBoardReduxState.activeWhiteBoardOwner.actionTaken;
        if (Object.keys(actionData).length > 0) {
          let userId = actionData.userInfo.attendeeId || actionData.userInfo.userId;
          if (Number(userId) === Number(userReduxState.userId) && userReduxState.userType === 'attendee') {
            if (actionData.action === 'start' && this.state.isMediaAllow === false) {

              let userPerm = this.state.participants.find(user => {
                return (Number(user.userId) === Number(userId) && user.userType === 'attendee')
              })
              let data = {
                video: actionData.userInfo.isVideoPermission,
                audio: actionData.userInfo.isAudioPermission,
                audioTimeLimit: userPerm.audioTimeLimit,
                name: userPerm.name,
                userId: Number(userId),
                userType: 'attendee'
              }
              this.setState({
                isMediaAllow: true,
                videoEnabled: true,
                audioEnabled: true,
              }, () => {
                allowMediaSocketEmit(data);
              })

            } else if (actionData.action === 'stop' && this.state.isMediaAllow) {
              let data = {
                userId: Number(userId),
                userType: 'attendee'
              }
              this.setState({
                isMediaAllow: false
              }, () => {
                stopMediaSocketEmit(data);
              })


            }
          }
        }
      });
    }
  }

  createVideoElement = (id, data) => {

    if (data.userType === 'presenter') {
      let video = document.getElementById(`presenterVideoMain`);
      if (Number(this.props.userReduxState.userId) === Number(this.props.userReduxState.sessionId)) {
        this.props.updatePresenterVideoMuteState(false)
      } else {
        setTimeout(() => {
          pageLoader('hide');
          bootbox.alert({
            message: config.langLabels.presenterArrived,
            className: 'presenterSession',
            callback: () => {
              this.props.updatePresenterVideoMuteState(false);
            }
          });
        }, 2000);
      }
      return video;
    } else {
      let allowVideo = (data.video || data.video === undefined) ? true : false;
      let allowAudio = (data.audio || data.audio === undefined) ? true : false;

      let htmlElementClass = (allowAudio && allowVideo) ? 'userVideo' : 'userAudio'
      let htmlElementType = (allowAudio && allowVideo) ? 'video' : 'audio'
      let div = document.createElement("div");
      div.className = `${htmlElementClass}`;
      div.id = `div${id}`;
      let video = document.createElement(htmlElementType);
      video.id = id;
      if (htmlElementType === "audio") {
        video.style.display = "none";
      }
      video.autoplay = true;
      // video.controls = (allowAudio && allowVideo) ? false : true;
      div.appendChild(video);
      const userVideoDiv = document.getElementById(`${htmlElementClass}s`);
      userVideoDiv.appendChild(div);
      return video;
    }
  }

  mediaFromUsers = (data) => {
    if (Array.isArray(data.users)) {
      this.setState({
        participants: data.users
      }, () => {
        data.users.forEach(user => {
          this.receiveVideoFrom(user);
        });
      })
    }
  }

  sessionOnHold = () => {
    let mediaPermission = this.props.whiteBoardReduxState.activeWhiteBoardOwner.mediaPermissions;
    const { userReduxState: { userId, userType } } = this.props;
    mediaPermission.map((user) => {
      if (Number(user.userId) === Number(userId) && user.userType === userType) {
        this.stopStream();
      }
    })
  }

  allowMedia = (data) => {
    this.startStream(data.audio, data.video);
  }

  stopMedia = () => {
    this.stopStream();
  }

  stopMediaSelf = (data) => {
    this.setState({
      isMediaAllow: false
    }, () => {
      stopMediaSocketEmit(data);
    })
  }

  allowMediaFrom = (data) => {
    this.receiveVideoFrom(data);
  }

  disConnect = () => {
    const { userReduxState: { userId, userType, sessionId } } = this.props;
    let { receivePeers } = this.state;
    if (receivePeers[`${userId}${userType}`]) {
      receivePeers[`${userId}${userType}`].webRtcPeer.peerConnection.close();
      receivePeers[`${userId}${userType}`].webRtcPeer.dispose();
      window.location.reload();
    }
  }

  startAttendeeStream = (data) => {
    this.startStream(data.audio, data.video);
  }

  stopMediaFrom = (data) => {
    this.receiveVideoFrom(data);
  }

  participantLeft = (data) => {
    let { receivePeers } = this.state;
    let participant = receivePeers[`${data.userId}${data.userType}`];
    if (participant) {
      participant.webRtcPeer.dispose();
      $(`#div${data.userId}${data.userType}`).remove();
      if ($(`#div_audio_${data.userId}${data.userType}`).length) {
        $(`#div_audio_${data.userId}${data.userType}`).remove();
      }
      delete receivePeers[`${data.userId}${data.userType}`];
      this.setState({
        receivePeers
      }, () => {
        this.resetMediaPermissions({
          userId: data.userId,
          userType: 'attendee'
        })
      })
    }
  }

  resetMediaPermissions = (data) => {
    const { userReduxState: { userId, name, sessionInfo: { presenterId, presenterName } }, whiteBoardReduxState: { activeWhiteBoardOwner } } = this.props

    let tempMediaPermissions = activeWhiteBoardOwner.mediaPermissions
    let getAttendeeIndex = tempMediaPermissions.findIndex(obj => (Number(obj.userId) === Number(data.userId) && obj.userType === data.userType))

    if (getAttendeeIndex > -1) {
      tempMediaPermissions.splice(getAttendeeIndex, 1)
    }

    let newOwner = {
      ...activeWhiteBoardOwner,
      mediaPermissions: tempMediaPermissions,
      actionTaken: { userInfo: data, action: 'stop' }
    };

    this.props.newActiveWhiteBoardOwnerState(newOwner);
  }

  receiveVideoAnswer = (data) => {
    let { receivePeers } = this.state;
    receivePeers[`${data.userId}${data.userType}`].webRtcPeer.processAnswer(
      data.sdpAnswer,
      function (error) {
        if (error) return;
      }
    );

    receivePeers[`${data.userId}${data.userType}`].webRtcPeer.peerConnection.oniceconnectionstatechange = ev => {

      if (receivePeers[`${data.userId}${data.userType}`].webRtcPeer.peerConnection.iceConnectionState === "connected") {
        this.setState({
          [data.userId + data.userType]: 'connected'
        });
      }
      if (receivePeers[`${data.userId}${data.userType}`].webRtcPeer.peerConnection.iceConnectionState === "disconnected") {
        if (data.userType === "presenter") {
          // window.location.reload()
        }
      }
    }
  }

  existingUsers = (data) => {
    const { userReduxState: { userId, userType, sessionId } } = this.props;
    users = data.users;
    if (userType !== "presenter") {
      this.onExistingUsers(users);
    }
  }

  onExistingUsers = (data) => {
    let { receivePeers } = this.state;
    let that = this;
    const { userReduxState: { userId, userType, sessionId } } = this.props;
    if (userType === "presenter") {
      let constraints = {
        audio: true,
        video: true
      };

      let options = {
        localVideo: document.getElementById("presenterVideo"),
        mediaConstraints: constraints,
        onicecandidate: this.onIceCandidate,
        configuration: config.ICE_SERVER_CONFIG
      };
      receivePeers[`${userId}${userType}`] = {};

      receivePeers[`${userId}${userType}`].webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options, function (error) {
        if (error) {
          return console.error(error);
        }
        this.generateOffer(that.onOffer);
      });

      that.setState({
        receivePeers
      })
    }
    data.forEach(that.receiveVideoFrom);
  }


  newUserArrived = (data) => {
    users.push(data);
    if (data.userType === "presenter") {
      this.receiveVideoFrom(data);
    }
  }

  presenterArrived = (data) => {
    const { userReduxState: { userId, userType, sessionId } } = this.props;
    let paramsData = {
      room: sessionId,
      userId: Number(userId),
      userType
    };
    joinRoomAttendeeSocketEmit(paramsData);
    afterJoinRoomSocketOn(this.receiveVideoFrom, data);
  }


  receiveVideoFrom = (data) => {
    let that = this;
    let { receivePeers } = this.state
    if (!receivePeers[`${data.userId}${data.userType}`]) {
      let video = this.createVideoElement(`${data.userId}${data.userType}`, data);
      var options = {
        remoteVideo: video,
        onicecandidate: function onIceCandidate(candidate) {
          onIceCandidateSocketEmit({
            candidate,
            userType: data.userType,
            userId: data.userId
          });
        },
        configuration: config.ICE_SERVER_CONFIG
      };
      showSpinner(video);
      this.setState({
        [data.userId + data.userType]: 'loading'
      });
      receivePeers[`${data.userId}${data.userType}`] = {};
      receivePeers[`${data.userId}${data.userType}`].webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, function (error) {
        if (error) {
          return console.error(error);
        }
        this.generateOffer(function (error, sdpOffer) {
          if (error) {
            return console.error(error);
          }

          videoFromUserSocketEmit({
            sdpOffer,
            userType: data.userType,
            userId: data.userId
          });
        });
      });

      that.setState({
        receivePeers
      });
    }
  }

  startStream = (audio = true, video = true) => {
    navigator.mediaDevices.getUserMedia({ audio, video })
      .then((stream) => {
        let setStateFor = (audio && video) ? 'isOwnVideoAllow' : 'isOwnAudioAllow';
        let htmlTagForAV = (audio && video) ? 'presenterVideo' : 'presenterAudio';

        this.setState({
          [setStateFor]: true
        }, () => {
          let { receivePeers } = this.state
          let that = this
          const { userReduxState: { userId, userType } } = this.props
          let constraints = {
            audio: audio,
            video: video
          };
          let options = {
            localVideo: document.getElementById(htmlTagForAV),
            mediaConstraints: constraints,
            onicecandidate: this.onIceCandidate,
            configuration: config.ICE_SERVER_CONFIG
          };
          showSpinner(document.getElementById(htmlTagForAV));
          receivePeers[`${userId}${userType}`] = {};
          receivePeers[`${userId}${userType}`].webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options, function (error) {
            if (error) {
              return console.error(error);
            }
            this.generateOffer(that.onOffer);
          });
          //  if (receivePeers[`${userId}${userType}`].webRtcPeer.peerConnection.getSenders()[1]) {
          // receivePeers[`${userId}${userType}`].webRtcPeer.peerConnection.getSenders()[1].track.enabled = video;
          //}
          that.setState({
            receivePeers,
          });
        })
      })
      .catch((error) => {
        errorToastr("The camera/mic permission is restricted");
      });
  }

  stopStream = () => {
    let { receivePeers } = this.state;
    const { userReduxState: { userId, userType } } = this.props;
    // stopMediaSocketEmit({
    //   userType: userType,
    //   userId: Number(userId)
    // });
    let participant = receivePeers[`${userId}${userType}`];
    if (participant) {
      participant.webRtcPeer.dispose();
      delete receivePeers[`${userId}${userType}`];
      this.setState({
        isOwnVideoAllow: false,
        isOwnAudioAllow: false,
        [userId + userType]: null
      });
    }
  }

  onIceCandidate = (candidate) => {
    const { userReduxState: { userId, userType } } = this.props
    if (userId && userType && candidate) {
      onIceCandidateSocketEmit({
        candidate,
        userType: userType,
        userId: userId
      });
    }
  }

  onOffer = (error, sdpOffer) => {
    if (error) return onError(error);
    const { userReduxState: { userId, userType } } = this.props
    videoFromUserSocketEmit({
      sdpOffer,
      userType: userType,
      userId: userId
    });
  }

  iceCandidateOnSocket = (data) => {
    let { receivePeers } = this.state
    receivePeers[`${data.userId}${data.userType}`].webRtcPeer.addIceCandidate(
      data.candidate,
      function (error) {
        if (error) {
          console.error("Error adding candidate: " + error);
          return;
        }
      }
    );

    this.setState({
      receivePeers
    })
  }


  muteVideoToggle = () => {
    const { userReduxState: { userId, userType } } = this.props;
    let videoEnabled = this.state.videoEnabled;
    this.setState({
      videoEnabled: (videoEnabled) ? false : true
    }, () => {
      this.state.receivePeers[`${userId}${userType}`].webRtcPeer.peerConnection.getSenders()[1].track.enabled = this.state.videoEnabled;
    })
  }

  muteAudioToggle = () => {
    const { userReduxState: { userId, userType } } = this.props;
    let audioEnabled = this.state.audioEnabled;
    this.setState({
      audioEnabled: (audioEnabled) ? false : true
    }, () => {
      this.state.receivePeers[`${userId}${userType}`].webRtcPeer.peerConnection.getSenders()[0].track.enabled = this.state.audioEnabled;
    })
  }

  toggleIsMuted = () => {
    this.setState(prevState => {
      return {
        isMuted: !prevState.isMuted
      }
    })
  }

  resetMediaPermissionsByStopButton = (data) => {
    const { userReduxState: { userId, name, sessionInfo: { presenterId, presenterName } }, whiteBoardReduxState: { activeWhiteBoardOwner } } = this.props

    let tempMediaPermissions = activeWhiteBoardOwner.mediaPermissions
    let getAttendeeIndex = tempMediaPermissions.findIndex(obj => (Number(obj.userId) === Number(data.userId) && obj.userType === data.userType))

    if (getAttendeeIndex > -1) {
      tempMediaPermissions.splice(getAttendeeIndex, 1)
    }

    let newOwner = {
      ...activeWhiteBoardOwner,
      mediaPermissions: tempMediaPermissions,
      actionTaken: { userInfo: data, action: 'stop' }
    };

    activeWhiteboardOwnerSocketEmit(newOwner)
  }

  render() {
    let { videoScreen, isOwnVideoAllow, isOwnAudioAllow, audioEnabled, videoEnabled, isMediaAllow, participants, isMuted } = this.state;
    const { userReduxState: { userId, userType }, whiteBoardReduxState } = this.props
    return (
      <>
        <div className="multipleStream__left" id="userAudios">
          {
            (isOwnVideoAllow) && <div id="userVideoPresenterVideo" className={`userVideo ${(videoScreen) ? 'videoScreen-big' : ''}`}>
              <div className="image text-center">
                <video id="presenterVideo" poster="/assets/images/default-img.png" width="100%" autoPlay></video>
              </div>
              <ul className="userVideo__bottom list-inline d-flex mb-0">
                <li>
                  <Link to=""
                    onClick={(e) => {
                      e.preventDefault();
                      this.muteAudioToggle();
                    }}
                    className={(audioEnabled) ? "active" : ''}
                  >
                    <i className={(audioEnabled) ? "icon-voice" : "icon-voice-off"}></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault();
                      this.muteVideoToggle();
                    }}
                    className={(videoEnabled) ? "active" : ''}
                  >
                    <i className={(videoEnabled) ? "icon-videocam" : "icon-videocam-off"}></i>
                  </Link>
                </li>
                <li className="ml-auto">
                  <Link to="" onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      videoScreen: (videoScreen) ? false : true
                    })
                  }} className="videoFullscreen">
                    <i className={`${(videoScreen) ? 'icon-fullscreen-exit' : 'icon-fullscreen'}`}></i>
                  </Link>
                </li>
              </ul>
            </div>
          }

          {
            (userType === 'supervisor') && <div className={`userVideo`}>
              <div className="image text-center">
                <video id="presenterVideoMain" poster="/assets/images/default-img.png" muted={isMuted} width="100%" autoPlay></video>

              </div>
              <ul className="userVideo__bottom list-inline d-flex mb-0">
                <li>
                  <Link to="" className={`videoView__videoIcon rounded-circle text-center ${isMuted ? "" : "active"}`} onClick={(e) => {
                    e.preventDefault()
                    this.toggleIsMuted()
                  }}>
                    <i className={`${isMuted ? "icon-volume-off" : "icon-volume"}`}></i>
                  </Link>
                </li>
              </ul>
            </div>
          }

          {
            (isOwnAudioAllow) && <div className={`userAudio`} >
              <div className="image text-center" style={{ display: "none" }}>
                <audio id="presenterAudio" autoPlay></audio>
              </div>
            </div>
          }

          <div id="audioList">
            {
              (participants.length > 0) && <>
                {
                  participants.filter(obj => (obj.audio && obj.userType === 'attendee')).map((data, index) => {
                    if (data.video === false) {
                      if (Number(data.userId) === Number(userId) && data.userType === userType) {
                        if (isMediaAllow) {
                          return <AudioGrantedView
                            loadingState={this.state[data.userId + data.userType]}
                            key={index}
                            data={data}
                            participants={participants}
                            muteAudioToggle={this.muteAudioToggle}
                            audioEnabled={audioEnabled}
                            isOwnAudioAllow={isOwnAudioAllow}
                          />
                        }
                      } else {
                        return <AudioGrantedView
                          loadingState={this.state[data.userId + data.userType]}
                          key={index}
                          data={data}
                          participants={participants}
                          muteAudioToggle={this.muteAudioToggle}
                          audioEnabled={audioEnabled}
                          isOwnAudioAllow={isOwnAudioAllow}
                          resetMediaPermissionsByStopButton={this.resetMediaPermissionsByStopButton}
                        />
                      }
                    }
                  })
                }
              </>
            }
          </div>
        </div>
        <div className="multipleStream__right" id="userVideos">


        </div>
      </>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps,
    whiteBoardReduxState: state.whiteBoardProps,
    audioVideoReduxState: state.audioVideoProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    newActiveWhiteBoardOwnerState: data => dispatch(newActiveWhiteBoardOwner(data)),
    updatePresenterVideoMuteState: data => dispatch(updatePresenterVideoMute(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupCallVideo)