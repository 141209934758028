import React, { Component } from 'react'

export default class Home extends Component {
  render() {
    return (
      <>
        <div id="notfound">
          <div className="notfound">
            <div className="notfound-404">
              <h1 className="blue-color mainHeading">Virtual ClassRoom</h1>
            </div>
          </div>
        </div>
      </>
    )
  }
}
