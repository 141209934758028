import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addWhiteBoard, removeWhiteBoard, newActiveWhiteBoard } from '../../redux/actions/whiteBoardAction'
import { footerToggle, feedBoardTitle, deleteBoardConfirm, pageLoader } from '../../utils/commonJsFunctions';
import { nowTimestamp } from '../../utils/customTimeFunctions';
import { addWhiteboardSocketEmit, deleteWhiteboardSocketEmit } from '../../socket';
import SessionService from '../../services/sessionService';
import config from '../../config';

class Footer extends Component {

  state = {
    isAllowDelete: true
  }

  addNewBoard = async (title) => {
    pageLoader('show');
    const { userReduxState: { sessionInfo: { id } } } = this.props
    let boardId = nowTimestamp();
    let resAddNewBoard = await SessionService.addNewBoard(id, { boardId: boardId, title: title, boardType: 'none' })
    if (resAddNewBoard.data.success) {
      this.props.addWhiteBoardInRedux({ boardId: boardId, title: title, boardType: 'none' })
      addWhiteboardSocketEmit({ boardId: boardId, title: title });
      pageLoader('hide');
    }
  }

  deleteBoard = (boardId) => {
    if (this.state.isAllowDelete) {
      this.setState({
        isAllowDelete: false
      }, () => {
        deleteBoardConfirm(this.confirmedDeleteBoard, boardId)
      })
    }
  }

  confirmedDeleteBoard = (result, boardId) => {
    this.setState({
      isAllowDelete: true
    }, async () => {
      if (result) {
        pageLoader('show');
        const { userReduxState: { sessionInfo: { id } } } = this.props
        let resdeleteBoard = await SessionService.deleteBoard(id, { boardId: boardId })
        if (resdeleteBoard.data.success) {
          pageLoader('hide');
          this.props.removeWhiteBoardInRedux(boardId)
          deleteWhiteboardSocketEmit({ boardId: boardId });
        }
      }
    })
  }

  getBoardTypeIcon(type, title) {
    if (type === 'document') {
      if (title.indexOf(".pdf") != -1) {
        type = 'pdf'
      } else if (title.indexOf(".ppt") != -1) {
        type = 'ppt'
      } else if (title.indexOf(".doc") != -1) {
        type = 'doc'
      } else if (title.indexOf(".xls") != -1) {
        type = 'xls'
      }
    }
    switch (type) {
      case 'none':
        return <img src="/assets/images/whiteboard-icon.svg" className="img-fluid m-0" alt="youtube icon" />
      case 'youtube':
        return <img src="/assets/images/video.svg" className="img-fluid" alt="youtube icon" />
      case 'facebook':
        return <img src="/assets/images/facebook.svg" className="img-fluid facebook-img" alt="facebook icon" />
      case 'twitter':
        return <img src="/assets/images/twitter.svg" className="img-fluid facebook-img" alt="facebook icon" />
      case 'website':
        return <img src="/assets/images/website-icon.svg" className="img-fluid" alt="facebook icon" />
      case 'pdf':
        return <img src="/assets/images/pdf.svg" className="img-fluid m-0" alt="facebook icon" />
      case 'ppt':
        return <img src="/assets/images/powerpoint.svg" className="img-fluid" alt="facebook icon" />
      case 'doc':
        return <img src="/assets/images/word.svg" className="img-fluid" alt="facebook icon" />
      case 'xls':
        return <img src="/assets/images/exel.svg" className="img-fluid" alt="facebook icon" />
      default:
        return '';
    }
  }

  render() {
    const { whiteBoardReduxState, userReduxState: { userType } } = this.props
    return (
      <footer className="footerSection" id="whiteBoardFooter">
        <Link
          to=""
          className="footerSection__arrow"
          onClick={(e) => {
            e.preventDefault()
            footerToggle()
          }}
        >
          <i className="icon-drop_dwon_up"></i>
        </Link>
        <div className="footerSection__inner align-items-center justify-content-between" id="footerInner">
          <Link
            to=""
            onClick={(e) => {
              e.preventDefault()
            }}
            data-target="#addModal"
            data-toggle="modal"
            className="footerSection__inner__icon"
          >
            <i className="icon-add-bg"></i>
          </Link>
          <ul className="list-inline footerSection__inner__right mb-0">
            {
              ((whiteBoardReduxState.whiteBoards.length < 10) && (userType === 'presenter')) && (
                <li
                  className="list-inline-item"
                  onClick={(e) => {
                    e.preventDefault()
                    feedBoardTitle(this.addNewBoard)
                  }}
                >
                  <label className="mb-0 d-flex align-items-center justify-content-center" htmlFor="addFolder">
                    {/* <input type="file" id="addFolder" className="d-none" name="" /> */}
                    <i className="icon-add"></i>
                    <span className="count text-center rounded-circle text-white font-neomd">{whiteBoardReduxState.whiteBoards.length}</span>
                  </label>
                  <span className="d-block">{config.langLabels.newBoard}</span>
                </li>
              )
            }
            {
              whiteBoardReduxState.whiteBoards.map((board, index) => {

                return <li
                  key={board.boardId}
                  className={`list-inline-item ${(Number(whiteBoardReduxState.activeWhiteBoardId) === Number(board.boardId)) ? 'active' : ''}`}
                >
                  <div
                    className={`box`}
                    onClick={() => {
                      if (Number(whiteBoardReduxState.activeWhiteBoardId) !== Number(board.boardId) && (userType === 'presenter')) {
                        this.props.newActiveWhiteBoardInRedux(board.boardId)
                        pageLoader('show', config.langLabels.changingBoardMsg);
                      }
                    }}
                  >
                    {this.getBoardTypeIcon(board.boardType, board.title)}
                  </div>
                  <span className="d-block">{board.title}</span>
                  {
                    (whiteBoardReduxState.whiteBoards.length > 1) && <Link
                      className="delete"
                      to=""
                      onClick={(e) => {
                        e.preventDefault();
                        this.deleteBoard(board.boardId)
                      }}
                    >{config.langLabels.deleteBoard}</Link>
                  }
                </li>

              })
            }
          </ul>
        </div>
      </footer>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    whiteBoardReduxState: state.whiteBoardProps,
    userReduxState: state.userProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    addWhiteBoardInRedux: (data) => dispatch(addWhiteBoard(data)),
    removeWhiteBoardInRedux: (data) => dispatch(removeWhiteBoard(data)),
    newActiveWhiteBoardInRedux: (data) => dispatch(newActiveWhiteBoard(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)