let toolTypeState = {
  tool: '',
  options: {
    rows: 0,
    columns: 0,
    color: '#000000',
    strokeWidth: 1,
    fill: '',
    angle: 0,
    edge: 0,
    cornerradius: 'no',
    url_video: '',
    textType: '',
    textStyle: '',
    doc_images: []
  }
}

export default toolTypeState;