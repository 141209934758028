import $ from 'jquery';
import bootbox from 'bootbox';
import { canvasScrollSocketEmit } from '../socket';
import config from '../config';

// fullScreen toggle function
let toggleFullscreen = (elem) => {
  elem = elem || document.documentElement;
  if (!document.fullscreenElement && !document.mozFullScreenElement &&
    !document.webkitFullscreenElement && !document.msFullscreenElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
}

export let openFullscreen = (event) => {
  let iconName = event.target.classList.value;
  if (iconName === 'icon-fullscreen') {
    event.target.classList.value = iconName + '-exit';
  } else if (iconName === 'icon-fullscreen-exit') {
    event.target.classList.value = 'icon-fullscreen';
  }
  toggleFullscreen();
}

// footer hide show function
export let footerToggle = () => {
  let element = document.getElementsByTagName("body")[0];

  if (element.classList) {
    element.classList.toggle("footerClose");
  } else {
    let classes = element.className.split(" ");
    let i = classes.indexOf("footerClose");

    if (i >= 0)
      classes.splice(i, 1);
    else
      classes.push("footerClose");
    element.className = classes.join(" ");
  }
}

export let voiceonOff = (event) => {
  let iconName = event.target.classList.value;
  if (iconName == 'icon-voice') {
    event.target.classList.value = iconName + '-off';
  } else if (iconName == 'icon-voice-off') {
    event.target.classList.value = 'icon-voice';
  }
}

export let hexToRgb = hex =>
  hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
    , (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16)).join(',')

export let windowResizeJquery = (func, canvas) => {
  $(window).bind('resize', () => {
    func(canvas)
  })
}

export let addGridClassName = () => {
  $("#libraryModal .folderList").addClass('gridView')
}

export let removeGridClassName = () => {
  $("#libraryModal .folderList").removeClass('gridView')
}

export let getChatMessage = () => {
  return $('.emoji-wysiwyg-editor').html()
}

export let resetChatMessage = () => {
  $('.emoji-wysiwyg-editor').empty()
}

export let onEnterKeyPressInChat = (func) => {
  $(`.emoji-wysiwyg-editor`).keypress((event) => {
    if ((event.charCode || event.keyCode || event.which) === 13) {
      event.preventDefault()
      func()
    }
  });
}

export let showAdminChat = () => {
  $("#adminLoader").addClass("d-block")
  $(".adminChat").toggleClass("d-block");
  if ($(".adminChat").hasClass("d-block")) {
    scrollToBottomInAdminChat('chatList')
    if ($("#adminLoader").hasClass("d-block")) {
      setTimeout(() => {
        $("#adminLoader").removeClass("d-block");
        $("#adminLoader").addClass("d-none");
      }, 500);
    }
  }
}

export let menuInResp = () => {
  $(".main-content").toggleClass("chatbarOpen");
  if ($(".main-content").hasClass("chatbarOpen")) {
    $("#menuForResponsive i").removeClass("icon-chat");
    $("#menuForResponsive i").addClass("icon-close");
  } else {
    $("#menuForResponsive i").removeClass("icon-close");
    $("#menuForResponsive i").addClass("icon-chat");
  }
}

export let videoFullScreen = () => {
  $(".main-content").toggleClass("videoFullView");
  $(".main-content").removeClass("overlayScreen");
  if ($(".main-content").hasClass("videoFullView")) {
    $("#videoFullForResponsive i").removeClass("icon-fullscreen");
    $("#videoFullForResponsive i").addClass("icon-fullscreen-exit");

  } else {
    $("#videoFullForResponsive i").removeClass("icon-fullscreen-exit");
    $("#videoFullForResponsive i").addClass("icon-fullscreen");
    $(".main-content").addClass("overlayScreen");
  }
}

export let tggleSidebar = () => {
  $("#responsiveForMessage").toggleClass("active");
  $(".main-content").toggleClass("hideSidebar");
}

export let tggleVideo = () => {
  $("#responsiveForMessage").toggleClass("d-flex");
  $("#responsiveForMessage").addClass("active");
  $("#responsiveForVideo").toggleClass("active");
  $(".chatSidebar .videoView").toggleClass("d-none");
  $(".chatSidebar").toggleClass("sidebarFull");
  if ($(".main-content").hasClass("hideSidebar")) {
    $(".main-content").removeClass("hideSidebar")
  }
  if ($(".main-content").hasClass("videoFullView")) {
    $(".main-content").removeClass("videoFullView");
    // $(".chatSidebar").removeClass("sidebarFull");
  }
}

export let hideOverlay = () => {
  $(".main-content").addClass("overlayScreen")
}

export let showOverlay = () => {
  $(".main-content").removeClass("overlayScreen")
}

export let hideVideoOverlay = () => {
  $(".main-content").addClass("overlayScreen")
}

export let getAdminChatMessage = () => {
  return $('.chatSupervisorPresenter').val()
}


export let toggleVideoStreem = () => {
  $("#responsiveForMultiVideo").toggleClass("active");
  $(".multipleStream__left, .multipleStream__right").toggleClass("d-block");
}

export let resetAdminChatMessage = () => {
  $('.chatSupervisorPresenter').val('')
}

export let onEnterKeyPressInAdminChat = (func) => {
  $('.chatSupervisorPresenter').on('keypress', (event) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      func()
    }
  });
}

export let pageLoader = (type = '', loadingText = '') => {
  if (type === 'show') {
    $(`#pageLoader`).show();
  } else if (type === 'hide') {
    $(`#pageLoader`).hide();
  }
  $(`#pageLoader .loading-text`).text(loadingText);
}

export let scrollToBottomInList = (className = '') => {
  if (className) {
    var objDiv = document.getElementById("chatListWrap");
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
      $(`.${className}`).animate({ scrollTop: objDiv.scrollTop }, 100, "swing");
    }
  }
}

export let scrollToBottomInAdminChat = (className = '') => {
  if (className) {
    $(`.${className}`).animate({ scrollTop: $(`.${className}`)[0].scrollHeight }, 100, "swing");
  }
}

export let chatListWrap = () => {
  var objDiv = document.getElementById("chatListWrap");
  var scrollBtmPos = objDiv.scrollHeight - objDiv.scrollTop
  if (scrollBtmPos == objDiv.clientHeight) {
    $('#responsiveForDownChat').hide()
  } else {
    $('#responsiveForDownChat').show()
  }

}

export let hideBootstrapModal = (modalId) => {
  $(`#${modalId}`).modal('hide');
  $('body').removeClass('modal-open');
}

export let showBootstrapModal = (modalId) => {
  $(`#${modalId}`).modal('show');
  setTimeout(function () {
    $('body').addClass('modal-open');
  }, 500);
}

export let resetReduxModalForm = (modalId, formName, dispatch, reset) => {
  $(`#${modalId}`).on('hidden.bs.modal', () => {
    dispatch(reset(formName));
  });
}

export let resetReactModalForm = (modalId, resetState) => {
  $(`#${modalId}`).on('hidden.bs.modal', () => {
    resetState()
  });
}

export let onOpenReactModalForm = (modalId, func) => {
  $(`#${modalId}`).on('show.bs.modal', () => {
    func()
  });
}

export let showTextToolOptions = (origY, origX, canvas, event, canvasScrollId, boardType) => {

  let canvasZoom = canvas.getZoom()
  $('#font-size-tool').val(10);

  origX *= canvasZoom
  origY *= canvasZoom

  origX += parseInt($(`#${canvasScrollId} .canvas-container`).css("marginLeft"))
  origY += parseInt($(`#${canvasScrollId} .canvas-container`).css("marginTop"))

  origX -= 170
  origY -= 70

  $("#textDropdown").attr("data-top-pos", origY);
  $("#textDropdown").attr("data-left-pos", origX);

  let scrollPos = {
    Y: $(`#${canvasScrollId}`).scrollTop(),
    X: $(`#${canvasScrollId}`).scrollLeft()
  }

  origY -= scrollPos.Y
  origX -= scrollPos.X

  document.getElementById("textDropdown").setAttribute('style', `display: block; top: ${origY}px; left: ${origX}px;`);

  $("#textDropdown").addClass("visible-textDropdown");
}

export let detectScrollInCanvas = (elementId, boardId) => {
  $(`#${elementId}`).scroll(() => {
    let scrollPos = {
      Y: $(`#${elementId}`).scrollTop(),
      X: $(`#${elementId}`).scrollLeft()
    }

    if ($("#textDropdown").hasClass("visible-textDropdown")) {
      let top = $("#textDropdown").attr("data-top-pos");
      top = top - scrollPos.Y
      let left = $("#textDropdown").attr("data-left-pos");
      left = left - scrollPos.X

      document.getElementById("textDropdown").setAttribute('style', `display: block; top: ${top}px; left: ${left}px;`);
    }

    canvasScrollSocketEmit({ action: 'scrollchange', boardId: boardId, scrollPosition: { Y: scrollPos.Y, X: scrollPos.X } });
  });
}

export let updateScrollInCanvas = (elementId, data) => {
  $(`#${elementId}`).scrollTop(data.scrollPosition.Y)
  $(`#${elementId}`).scrollLeft(data.scrollPosition.X)
}

export let hideTextToolOptions = () => {
  if ($('#textDropdown').length > 0) {
    $("#textDropdown").removeClass("visible-textDropdown");
    document.getElementById("textDropdown").setAttribute('style', `display: none;`);
  }
}

export let clearCanvasConfirm = (clearCanvasFunc, canvas) => {
  bootbox.confirm({
    message: config.langLabels.alertClearAllBoard,
    centerVertical: true,
    locale: 'ar',
    buttons: {
      confirm: {
        label: 'Yes',
        className: 'btn-primary ripple-effect'
      },
      cancel: {
        label: 'No',
        className: 'btn-dark ripple-effect'
      }
    },
    callback: (result) => {
      if (result) {
        clearCanvasFunc(result, canvas)
      }
    }
  });
}

export let deleteBoardConfirm = (deleteBoardFunc, boardId) => {
  bootbox.confirm({
    message: config.langLabels.alertDeleteBoard,
    centerVertical: true,
    buttons: {
      confirm: {
        label: 'Yes',
        className: 'btn-primary ripple-effect'
      },
      cancel: {
        label: 'No',
        className: 'btn-dark ripple-effect'
      }
    },
    callback: (result) => {
      deleteBoardFunc(result, boardId)
    }
  });
}

export let extendTimeConfirm = (extendTimeFunc, data) => {
  bootbox.confirm({
    message: config.langLabels.extDurationReq(data.duration),
    centerVertical: true,
    buttons: {
      confirm: {
        label: 'Yes',
        className: 'btn-primary ripple-effect'
      },
      cancel: {
        label: 'No',
        className: 'btn-dark ripple-effect'
      }
    },
    callback: (result) => {
      if (result) {
        extendTimeFunc(result, data)
      }
    }
  });
}

export let feedBoardTitle = (addNewBoard) => {
  bootbox.prompt({
    closeButton: false,
    title: config.langLabels.alertEnterBoardName,
    placeholder: config.langLabels.alertEnterBoardName,
    inputType: 'text',
    required: true,
    centerVertical: true,
    buttons: {
      confirm: {
        label: 'Yes',
        className: 'btn-primary ripple-effect'
      },
      cancel: {
        label: 'No',
        className: 'btn-dark ripple-effect'
      }
    },
    callback: (result) => {
      if (result) {
        addNewBoard(result)
      }
    }
  });
}

export let feedCreateNewFolderTitle = (addNewFolder) => {
  bootbox.prompt({
    title: config.langLabels.alertFolderName,
    placeholder: config.langLabels.alertFolderName,
    inputType: 'text',
    required: true,
    centerVertical: true,
    closeButton: false,
    buttons: {
      confirm: {
        label: 'Yes',
        className: 'btn-primary ripple-effect'
      },
      cancel: {
        label: 'No',
        className: 'btn-dark ripple-effect'
      }
    },
    callback: (result) => {
      if (result) {
        addNewFolder(result)
      }
    }
  });
}

export let renameFolderTitle = (renameFolder, data, index) => {
  bootbox.prompt({
    title: config.langLabels.alertRenameFolder,
    placeholder: config.langLabels.alertRenameFolder,
    inputType: 'text',
    required: true,
    centerVertical: true,
    closeButton: false,
    buttons: {
      confirm: {
        label: 'Yes',
        className: 'btn-primary ripple-effect'
      },
      cancel: {
        label: 'No',
        className: 'btn-dark ripple-effect'
      }
    },
    callback: (result) => {
      if (result) {
        renameFolder(result, data, index)
      }
    }
  });
}

export let renameFileTitle = (renameFile, data, index) => {
  bootbox.prompt({
    title: config.langLabels.alertRenameFile,
    placeholder: config.langLabels.alertRenameFile,
    inputType: 'text',
    required: true,
    centerVertical: true,
    closeButton: false,
    buttons: {
      confirm: {
        label: 'Yes',
        className: 'btn-primary ripple-effect'
      },
      cancel: {
        label: 'No',
        className: 'btn-dark ripple-effect'
      }
    },
    callback: (result) => {
      if (result) {
        renameFile(result, data, index)
      }
    }
  });
}

export let deleteFolderFromList = (func, data, index) => {
  bootbox.confirm({
    // title: "Are you sure you want to delete the selected folder",
    message: config.langLabels.alertDeleteFolder,
    centerVertical: true,
    buttons: {
      confirm: {
        label: 'Yes',
        className: 'btn-primary ripple-effect'
      },
      cancel: {
        label: 'Cancel',
        className: 'btn-dark ripple-effect'
      }
    },
    callback: (result) => {
      if (result) {
        func(result, data, index)
      }
    }
  });
}

export let deleteFileFromList = (func, data, index) => {
  bootbox.confirm({
    // title: "Are you sure you want to delete the selected folder",
    message: config.langLabels.alertDeleteFile,
    centerVertical: true,
    buttons: {
      confirm: {
        label: 'Yes',
        className: 'btn-primary ripple-effect'
      },
      cancel: {
        label: 'Cancel',
        className: 'btn-dark ripple-effect'
      }
    },
    callback: (result) => {
      if (result) {
        func(result, data, index)
      }
    }
  });
}

export let attendeeStopAudioConfirmation = (func) => {
  bootbox.confirm({
    title: "You want to close ?",
    message: "you will close the vocal comment",
    centerVertical: true,
    buttons: {
      confirm: {
        label: 'Yes',
        className: 'btn-primary ripple-effect'
      },
      cancel: {
        label: 'Cancel',
        className: 'btn-dark ripple-effect'
      }
    },
    callback: (result) => {
      if (result) {
        func(result)
      }
    }
  });
}

export let getBrowserDetails = () => { // https://stackoverflow.com/a/11219680
  let nVer = navigator.appVersion;
  let nAgt = navigator.userAgent;
  let browserName = navigator.appName;
  let fullVersion = '' + parseFloat(nVer);
  let majorVersion = parseInt(nVer, 10);
  let nameOffset, verOffset, ix;

  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = nAgt.indexOf("Opera")) != -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf("Version")) != -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Chrome, the true version is after "Chrome" 
  else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version" 
  else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) != -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In Firefox, the true version is after "Firefox" 
  else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers, "name/version" is at the end of userAgent 
  else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(";")) != -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(" ")) != -1)
    fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt('' + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = '' + parseFloat(nVer);
    majorVersion = parseInt(nVer, 10);
  }

  return {
    browserName: browserName,
    fullVersion: fullVersion,
    majorVersion: majorVersion,
    appName: navigator.appName,
    userAgent: navigator.userAgent,
  }
}

export let getWidthHD = (width) => {
  let whHD = {
    width: 128,
    height: 72
  }
  if (width < 256) {
    whHD = {
      width: 128,
      height: 72
    }
  } else if (width < 384) {
    whHD = {
      width: 256,
      height: 144
    }
  } else if (width < 512) {
    whHD = {
      width: 384,
      height: 216
    }
  } else if (width < 640) {
    whHD = {
      width: 512,
      height: 288
    }
  } else if (width < 768) {
    whHD = {
      width: 640,
      height: 360
    }
  } else if (width < 896) {
    whHD = {
      width: 768,
      height: 432
    }
  } else if (width < 1024) {
    whHD = {
      width: 896,
      height: 504
    }
  } else if (width < 1152) {
    whHD = {
      width: 1024,
      height: 576
    }
  } else if (width < 1280) {
    whHD = {
      width: 1152,
      height: 648
    }
  } else if (width < 1408) {
    whHD = {
      width: 1280,
      height: 720
    }
  } else if (width < 1536) {
    whHD = {
      width: 1408,
      height: 792
    }
  } else if (width < 1664) {
    whHD = {
      width: 1536,
      height: 864
    }
  } else if (width < 1792) {
    whHD = {
      width: 1664,
      height: 936
    }
  } else if (width < 1920) {
    whHD = {
      width: 1792,
      height: 1008
    }
  } else if (width >= 1920) {
    whHD = {
      width: 1920,
      height: 1080
    }
  }
  return whHD
}

//open close search form on library modal
export let openSearch = (e) => {
  e.target.offsetParent.offsetParent.classList.add('show');
}

export let closeSearch = (e) => {
  e.target.offsetParent.offsetParent.classList.remove('show');
}

export let folderbox = () => {
  $('#folderList').hide();
  $('.folderBackbtn').show();
}

export let folderBackbtn = (e) => {
  $('#folderList').show();
  e.target.offsetParent.style.display = 'none';
}

export let hideShowAllVideoJs = (val) => {
  $("#userVideoPresenterVideo").css("display", val);
  $("#userVideos").css("display", val);
}

export let hideShowAllAudioJs = (val) => {
  $("#audioList").css("display", val);
}

export let micTest = () => {
  "use strict";
  let paths = document.getElementsByTagName('path');
  let visualizer = document.getElementById('visualizer');
  let mask = visualizer.getElementById('mask');
  // let h = document.getElementsByTagName('h1')[0];
  let path;
  let report = 0;

  let soundAllowed = function (stream) {
    //Audio stops listening in FF without // window.persistAudioStream = stream;
    //https://bugzilla.mozilla.org/show_bug.cgi?id=965483
    //https://support.mozilla.org/en-US/questions/984179
    window.persistAudioStream = stream;
    // h.innerHTML = "Thanks";
    // h.setAttribute('style', 'opacity: 0;');
    let audioContent = new AudioContext();
    let audioStream = audioContent.createMediaStreamSource(stream);
    let analyser = audioContent.createAnalyser();
    audioStream.connect(analyser);
    analyser.fftSize = 1024;

    let frequencyArray = new Uint8Array(analyser.frequencyBinCount);
    visualizer.setAttribute('viewBox', '0 0 255 255');

    //Through the frequencyArray has a length longer than 255, there seems to be no
    //significant data after this point. Not worth visualizing.
    for (let i = 0; i < 255; i++) {
      path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      path.setAttribute('stroke-dasharray', '4,1');
      mask.appendChild(path);
    }
    let doDraw = function () {
      requestAnimationFrame(doDraw);
      analyser.getByteFrequencyData(frequencyArray);
      let adjustedLength;
      for (let i = 0; i < 255; i++) {
        adjustedLength = Math.floor(frequencyArray[i]) - (Math.floor(frequencyArray[i]) % 5);
        paths[i].setAttribute('d', 'M ' + (i) + ',255 l 0,-' + adjustedLength);
      }

    }
    doDraw();
  }

  let soundNotAllowed = function (error) {
    // h.innerHTML = "You must allow your microphone.";
    console.log("You must allow your microphone.", error);
  }

  /*window.navigator = window.navigator || {};
  /*navigator.getUserMedia =  navigator.getUserMedia       ||
                            navigator.webkitGetUserMedia ||
                            navigator.mozGetUserMedia    ||
                            null;*/
  navigator.getUserMedia({ audio: true }, soundAllowed, soundNotAllowed);
};

export let convertBlobToBase64 = blob => new Promise((resolve, reject) => {
  const reader = new FileReader;
  reader.onerror = reject;
  reader.onload = () => {
    resolve(reader.result);
  };
  reader.readAsDataURL(blob);
});