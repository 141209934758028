import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ChatList from '../chatList';
import { updatePresenterVideoMute } from '../../redux/actions/audioVideoAction';
import { videoFullScreen, hideVideoOverlay, scrollToBottomInList } from '../../utils/commonJsFunctions';

class AttendeeSideBar extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isMuted: true
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.audioVideoReduxState.presenterVideoMute !== this.props.audioVideoReduxState.presenterVideoMute) {
      this.setState({
        isMuted: this.props.audioVideoReduxState.presenterVideoMute
      })
    }
  }


  toggleIsMuted = () => {
    this.setState(prevState => {
      return {
        isMuted: !prevState.isMuted
      }
    });
  }

  render() {

    const { isMuted } = this.state

    return (
      <section className="chatSidebar">
        <div className="videoView presenterVideoDiv" id="">
          <video id="presenterVideoMain" poster="/assets/images/default-attende-img.svg" muted={isMuted} width="100%" autoPlay></video>
          <Link to="" className={`videoView__videoIcon d-none d-lg-block rounded-circle text-center ${isMuted ? "" : "active"}`} onClick={(e) => {
            e.preventDefault()
            this.toggleIsMuted()
          }}>
            <i className={`${isMuted ? "icon-volume-off" : "icon-volume"}`}></i>
          </Link>
          <div
            className="videoOverlay"
            onClick={(e) => {
              e.preventDefault()
              hideVideoOverlay()
            }}
          ></div>
          <Link
            to=""
            onClick={(e) => {
              e.preventDefault()
              videoFullScreen()
            }}
            id="videoFullForResponsive"
            className="videoFullBtn d-flex d-lg-none"
          ><i className="icon-fullscreen"></i></Link>
        </div>
        <div className="chatBox">
          <ChatList />
          <Link to=""
            onClick={(e) => {
              e.preventDefault()
              scrollToBottomInList('listingWrapper')
            }}
            id="responsiveForDownChat" className="chatDownbtn d-lg-none">
            <i className="icon-arrow-thin-left"></i>
          </Link>
        </div>
      </section>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps,
    audioVideoReduxState: state.audioVideoProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updatePresenterVideoMuteState: data => dispatch(updatePresenterVideoMute(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendeeSideBar)