import React, { Component } from 'react'
import { Field, reduxForm, FieldArray, reset } from 'redux-form';
import { Link } from 'react-router-dom';
import { resetReduxModalForm, hideBootstrapModal } from '../../utils/commonJsFunctions'
import PollService from '../../services/pollService';
import { getLSLocalStorage } from '../../utils/localStorage';
import { successToastr, errorToastr } from '../../utils/handelError';
import config from '../../config';
import QuestionModal from './questionModal';
import { publishPollSocketEmit } from '../../socket';
import ButtonLoader from '../loaders/buttonLoader';
import { connect } from 'react-redux';

let initialVal = {
  question: '',
  question_options: [{}],
  isAnswer: ''
}

const validate = values => {
  let errors = {};
  if (!values.question) {
    errors.question = config.langLabels.errMsgQField;
  } else if (!values.question.trim()) {
    errors.question = config.langLabels.errMsgQField;
  }
  let question_optionsErrors = [];

  if (!values.question_options) {

  } else if (values.question_options) {
    values.question_options.map((data, index) => {
      if (!data.option) {
        question_optionsErrors.push({ option: config.langLabels.errMsgOptionField });
      } else if (!data.option.trim()) {
        question_optionsErrors.push({ option: config.langLabels.errMsgOptionField });
      } else {
        question_optionsErrors.push({});
      }
      return true;
    });
  } else {
    question_optionsErrors.push({ option: config.langLabels.errMsgOptionField })
  }

  errors.question_options = question_optionsErrors
  return errors;
};

const renderInputField = ({ input, label, placeholder = false, meta: { touched, error } }) => (
  <div className="form-group">
    {
      <input
        {...input}
        className="form-control h_60"
        placeholder={placeholder ? placeholder : label}
        type="text"
      />
    }
    {touched && error && <span className="error-msg">{error}</span>}
  </div>
);


const renderOptions = ({ fields = [] }) => {
  return <>
    <div id="addOption" className="addOption">
      {
        fields.map((option, index) => {
          return <div className="form-group" key={index}>
            <Field
              component={renderInputField}
              name={`${option}option`}
              label={config.langLabels.option}
              className="form-control"
            />


            <Link
              to=""
              className="delete"
              onClick={e => {
                e.preventDefault();
                fields.remove(index)
              }}>
              <i className="icon-delete"></i>
            </Link>
          </div>
        })
      }
    </div>
    <div className="text-right form-group">
      <button
        onClick={e => {
          e.preventDefault();
          fields.push({});
        }}
        className="btn btn-white ripple-effect font-hrg"
        type="button">{config.langLabels.addAns}</button>
    </div>
  </>
}

class AddPollModal extends Component {

  constructor(props) {
    super(props)

    this.state = {
      pollDataAPiRes: {}
    }
  }


  componentDidMount() {
    resetReduxModalForm('addPollModal', 'questionnaireForm', this.props.dispatch, reset);
  }

  handleSubmit = async (values) => {
    let userInfo = this.props.userReduxState;
    let postData = {
      question: values.question,
      answers: values.question_options,
      sessionId: userInfo.sessionInfo.id,
      presenterId: userInfo.userId
    }
    let result = await PollService.createPoll(postData);
    if (result.data.success) {
      successToastr(config.langLabels.pollPosted);
      hideBootstrapModal('addPollModal');
      publishPollSocketEmit(result.data.data);
      this.setState({
        pollDataAPiRes: result.data.data
      }, () => {
        return result.data.data
      })
    } else {
      errorToastr(config.langLabels.pollFailed)
    }
  }

  render() {
    const {
      handleSubmit,
      submitting
    } = this.props;

    const { pollDataAPiRes } = this.state

    return (
      <>
        <div className="modal fade" id="addPollModal" tabIndex="-1" role="dialog" aria-labelledby="addPollModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog modal-dialog-centered w-570 add-video-modal add-poll-modal" role="document">
            <div className="modal-content bg-grey">
              <div className="modal-header border-bottom-0 pb-0">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="icon-close"></i>
                </button>
                <h5 className="modal-title font-neomd">{config.langLabels.addQ}</h5>
              </div>
              <div className="modal-body" dir="rtl">
                <form onSubmit={handleSubmit(this.handleSubmit)}>
                  <div className="form-group">
                    <Field
                      name="question"
                      type="text"
                      component={renderInputField}
                      label="Write the question"
                    />
                  </div>
                  <p className="mb-1 title text-right">{config.langLabels.choices}</p>
                  <FieldArray
                    name="question_options"
                    component={renderOptions}
                  />
                  <div className="text-center btn-row mt-4">
                    <button className="btn btn-dark ripple-effect w_170 ml-2"
                      type="button"
                      onClick={() => hideBootstrapModal('addPollModal')}>{config.langLabels.closeQ}</button>
                    <button
                      disabled={submitting}
                      type="submit"
                      className="btn btn-primary ripple-effect w_170"
                      onClick={(e) => { }}
                    >
                      {config.langLabels.sendQ}
                      {submitting && <ButtonLoader />}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {
          Object.keys(pollDataAPiRes).length > 0 && <QuestionModal pollData={pollDataAPiRes} displayType={'publishResult'} />
        }
      </>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
  }
}

AddPollModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPollModal);

AddPollModal = reduxForm({
  form: 'questionnaireForm',
  initialValues: initialVal,
  validate,
})(AddPollModal);

export default AddPollModal;