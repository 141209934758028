import commonConfig from "./common";


let developmentConfig = {
  API_URL: 'http://localhost:8051/api',
  LIBRARY_URL: 'http://localhost:8051',
  SOCKET_URL: 'http://localhost:8051',
  DOC_UPLOAD: 'https://prortc.com:3030/upload-document',
  ICE_SERVER_CONFIG: {
    iceServers: [{
      "urls": ["turn:3.95.2.177:3478"],
      "username": "navalp",
      "credential": "codiant2020"
    }],
    iceTransportPolicy: 'relay'
  },
  ...commonConfig
}

export default developmentConfig;