import React, { Component } from 'react'
import { showBootstrapModal, hideBootstrapModal } from '../../utils/commonJsFunctions';
import { submitPollAnswerSocketEmit, submitPollAnswerSocketOn, closePollModalsSocketEmit } from '../../socket';
import PollService from '../../services/pollService';
import { connect } from 'react-redux';
import $ from 'jquery';
import config from '../../config';
/**
 * Send the questionnaire modal
 */
class QuestionModal extends Component {

  state = {
    userType: '',
    actionType: false,
    pollData: this.props.pollData
  }

  componentDidMount() {
    this.initLoad()
    showBootstrapModal('questionnaireModal');
    submitPollAnswerSocketOn(this.updateResultOnSocket);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userReduxState !== this.props.userReduxState) {
      this.initLoad()
    }

    if (this.props.displayType === 'publishResult' && prevProps.pollData.PollAnswerOptions !== this.props.pollData.PollAnswerOptions && !$('#questionnaireModal').hasClass('in')) {
      this.setState({
        actionType: false,
        pollData: this.props.pollData
      }, () => {
        $('#customCheck1').prop("checked", false)
        showBootstrapModal('questionnaireModal');
      });
    }
  }

  componentWillUnmount() {
    hideBootstrapModal('questionnaireModal')
  }

  initLoad = () => {
    let sessionInfo = this.props.userReduxState;
    if (sessionInfo) {
      this.setState({
        userType: sessionInfo.userType
      })
    }
  }

  displayResult = async (pollId) => {
    if (this.state.userType === 'presenter') {
      let result = await PollService.displayResult(pollId);
      if (result.data.success) {
        if (this.state.actionType) {
          submitPollAnswerSocketEmit({ type: 'presenter', pollData: result.data.data });
        }
      }
    }
  }

  updateResultOnSocket = (data) => {
    this.setState({
      pollData: data.pollData
    });
  }

  render() {

    const { pollData, userType, actionType } = this.state;

    return (
      <div className="modal fade" id="questionnaireModal" tabIndex="-1" role="dialog" aria-labelledby="addPollModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered w-570 add-video-modal add-poll-modal" role="document">
          <div className="modal-content bg-grey">
            <div className="modal-header border-bottom-0 pb-0">
              <h5 className="modal-title font-neomd">{config.langLabels.aQuestion}</h5>
            </div>
            <div className="modal-body text-right" dir="rtl">
              <h3 className="font-neomd question">{pollData.question}</h3>
              <p className="mb-1 title text-right">{config.langLabels.choices}</p>
              <div className="optionList">
                {
                  pollData.PollAnswerOptions.map((item, index) => {
                    let answerPer = (100 * item.answerCount) / pollData.totalAnswer;
                    return <div className="option" key={index}>
                      <h5>{index + 1}. {item.option}</h5>
                      {
                        (answerPer > 0) && <div className="progress">
                          <div id={`progressbar${item.id}`} className="progress-bar text-right" role="progressbar" style={{ width: `${answerPer}%` }} aria-valuenow={answerPer} aria-valuemin="0" aria-valuemax="100"><span className="mr-3 mt-1">{item.answerCount}</span></div>
                        </div>
                      }
                    </div>
                  })
                }
              </div>
              {
                (userType === 'presenter' && pollData.status === 'Publish') ? <div className="custom-control custom-checkbox">
                  <input type="radio" onClick={(e) => {
                    this.setState({
                      actionType: (actionType) ? false : true
                    }, () => {
                      this.displayResult(pollData.id)
                    })
                  }} className="custom-control-input" id="customCheck1" />
                  <label htmlFor="customCheck1"><span>{config.langLabels.viewResStudents}</span></label>
                </div> : (userType === 'presenter') ? <span>{config.langLabels.resultPublished}</span> : ''
              }
              <div className="text-center btn-row mt-4">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    closePollModalsSocketEmit();
                  }}
                  className="btn btn-dark ripple-effect w_170 ml-2"
                  data-dismiss="modal"
                  aria-label="Close"
                  type="button">{config.langLabels.closeQ}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionModal)