import React from 'react';
import { Switch, Router } from 'react-router-dom';
import RouteWithLayout from './routeWithLayout';
import PresenterLayout from '../layouts/presenter';
import AppLayout from '../layouts/app';
import PageNotFound from '../notFound';
import browserHistory from '../../utils/history';
import WhiteBoard from '../../containers/whiteBoard';
import RequireAuth from '../requireAuth';
import Home from '../home';
import AttendeeLayout from '../layouts/attendee';

export default class Routes extends React.Component {
  render() {
    return (
      <Router history={browserHistory}>
        <Switch>
          <RouteWithLayout
            component={Home}
            exact
            layout={AppLayout}
            path="/"
          />

          <RouteWithLayout
            component={WhiteBoard}
            exact
            layout={RequireAuth(PresenterLayout)}
            path="/presenter/:sessionId/:presenterId"
          />

          <RouteWithLayout
            component={WhiteBoard}
            exact
            layout={RequireAuth(AttendeeLayout)}
            path="/attendee/:sessionId/:attendeeId"
          />

          <RouteWithLayout
            component={WhiteBoard}
            exact
            layout={RequireAuth(PresenterLayout)}
            path="/supervisor/:sessionId/:supervisorId"
          />

          <RouteWithLayout
            component={PageNotFound}
            layout={AppLayout}
            path="*"
          />
        </Switch>
      </Router>
    );
  }
}
