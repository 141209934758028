import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  addGridClassName,
  removeGridClassName,
  resetReactModalForm,
  feedCreateNewFolderTitle,
  hideBootstrapModal,
  pageLoader,
  closeSearch,
  openSearch,
  folderBackbtn,
  folderbox,
  deleteFolderFromList,
  deleteFileFromList,
  renameFolderTitle,
  renameFileTitle
} from '../../utils/commonJsFunctions';
import { ACCEPT_ALL_TYPE } from '../../utils/constants';
import { validateExistsInAllFile, validateFileSize, getFileIconByType } from '../../utils/customValidator';
import FolderService from '../../services/folderService';
import SessionService from '../../services/sessionService';
import { successToastr, errorToastr } from '../../utils/handelError';
import config from '../../config';
import Axios from 'axios';
import moment from 'moment';
import { nowTimestamp } from '../../utils/customTimeFunctions'
import { toolTypeData } from '../../utils/toolData';
import { addWhiteboardSocketEmit } from '../../socket';
import { updateToolType } from '../../redux/actions/toolTypeAction';
import { addWhiteBoard } from '../../redux/actions/whiteBoardAction'
import ButtonLoader from '../loaders/buttonLoader';
import ListLoader from '../loaders/listLoader';

const IMAGE_BASE_URL = config.LIBRARY_URL

/**
 * add Library modal
 */
class LibraryModal extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      fileName: '',
      file: {},
      isInFolder: false,
      folderAndFiles: [],
      filesInFolder: [],
      deleteMultiFiles: [],
      idOfFolderFiles: 0,
      selectedFileId: 0,
      selectedFileURL: '',
      selectedFolderPath: '',
      selectedFolderName: '',
      fileSearchKey: '',
      fileProgress: {
        fileName: '',
        fileUploadProgress: 0
      }
    }
  }

  componentDidMount() {
    this.initData()
    resetReactModalForm('libraryModal', this.resetModalData)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userReduxState !== this.props.userReduxState) {
      this.resetModalData()
    }
  }

  resetModalData = () => {
    this.setState({
      isLoading: true
    }, () => {
      this.initData()
    })
  }

  initData = async () => {
    let setStateObj = {
      fileName: '',
      file: {},
      isLoading: false,
      isInFolder: false,
      folderAndFiles: [],
      filesInFolder: [],
      deleteMultiFiles: [],
      idOfFolderFiles: 0,
      selectedFileId: 0,
      selectedFileURL: '',
      selectedFolderPath: '',
      selectedFolderName: '',
      selectedImages: '',
      fileSearchKey: ''
    }
    const { userReduxState: { userId, userType, sessionInfo } } = this.props
    if (sessionInfo && userType !== 'attendee' && sessionInfo.presenterId) {
      try {
        let rawData = {
          presenterId: sessionInfo.presenterId
        }

        let getFolderServiceData = await FolderService.getFolderList(rawData)
        const { rows } = getFolderServiceData.data.data

        setStateObj = {
          ...setStateObj,
          folderAndFiles: rows
        }
      } catch (error) {

      }
    }
    this.setState(setStateObj)
  }

  filesOfFolder = (idOfFolderFiles, data) => {
    this.setState(prevState => {
      return {
        idOfFolderFiles,
        selectedFolderPath: data.folderPath,
        selectedFolderName: data.folderName,
        isInFolder: true,
        selectedFileId: 0,
        selectedFileURL: '',
        selectedImages: '',
        filesInFolder: prevState.folderAndFiles.filter(obj => Number(obj.id) === Number(idOfFolderFiles))[0].PresenterLibraryFiles,
        deleteMultiFiles: [],
        fileSearchKey: ''
      }
    })
  }

  selectedFile = (selectedFileId, data) => {
    if (data.images) {
      this.setState({
        selectedFileId,
        selectedFileURL: data.fileName,
        selectedImages: data.images
      }, () => {
        this.addFile()
      })
    } else {
      errorToastr(config.langLabels.errMsgSelectFile)
    }
  }

  addFile = () => {
    if (this.props.type === 'chat') {
      let rawData = {
        selectedFileId: this.state.selectedFileId,
        selectedFileURL: `${IMAGE_BASE_URL}/library/${this.state.selectedFolderPath}/${this.state.selectedFileURL}`
      }
      this.props.getSelectedFile(rawData)
    } else {
      let images = this.state.selectedImages;
      this.updateToolTypeInRedux('document', { doc_images: images.split(','), board_name: (this.state.selectedFileURL) ? this.state.selectedFileURL : 'Document' });
    }
    hideBootstrapModal('libraryModal')
  }

  updateToolTypeInRedux = async (tool = '', toolOptions = {}) => {
    pageLoader('show');
    let result = await this.addNewBoard(toolOptions.board_name, toolOptions);
    if (result) {
      pageLoader('hide');
      successToastr(config.langLabels.documentUploaded)
      let data = toolTypeData(tool, toolOptions, this.props.toolTypeReduxState)
      this.props.updateToolTypeState(data)
    }
  }

  addNewBoard = async (title, toolOptions) => {
    const { userReduxState: { sessionInfo: { id } } } = this.props
    let boardId = nowTimestamp();
    let boardType = 'document'
    let payload = {
      boardId: boardId,
      title: title,
      boardType: boardType,
      frameData: toolOptions.doc_images.join(',')
    }
    let resAddNewBoard = await SessionService.addNewBoard(id, payload)
    if (resAddNewBoard.data.success) {
      this.props.addWhiteBoardInRedux(payload)
      addWhiteboardSocketEmit(payload);
      return true;
    }
    return false;
  }

  goToRoot = () => {
    this.setState({
      idOfFolderFiles: 0,
      selectedFileId: 0,
      selectedFileURL: '',
      selectedFolderPath: '',
      selectedFolderName: '',
      isInFolder: false,
      filesInFolder: [],
      deleteMultiFiles: [],
      fileSearchKey: '',
      fileProgress: {
        fileName: '',
        fileUploadProgress: 0
      }
    })
  }

  addNewFolder = async (folderName) => {
    const { userReduxState: { userId, userType } } = this.props
    if (userType !== 'attendee' && userId) {
      try {
        let rawData = {
          presenterId: userId,
          folderName: folderName
        }

        let postFolderServiceData = await FolderService.postFolderList(rawData)
        if (postFolderServiceData.data.success) {
          successToastr(config.langLabels.folderCreated)
          this.initData()
        } else {
          errorToastr(postFolderServiceData.data.message)
        }

      } catch (error) {

      }
    }
  }

  uploadFile = async () => {
    // return false
    const { file, idOfFolderFiles, folderAndFiles, filesInFolder } = this.state
    const { userReduxState: { userId, userType } } = this.props

    if (userType !== 'attendee' && userId) {
      try {
        let rawData = new FormData();
        rawData.append('file', file);
        pageLoader('show');

        // https://gist.github.com/virolea/e1af9359fe071f24de3da3500ff0f429
        let response = await Axios.post(
          config.DOC_UPLOAD,
          rawData,
          {
            onUploadProgress: (progressEvent) => {
              let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              this.setState(prevState => {
                return {
                  fileProgress: {
                    fileName: prevState.fileProgress.fileName,
                    fileUploadProgress: percentCompleted
                  }
                }
              })
            }
          }
        );
        if (response.data.success) {
          rawData.append('images', response.data.data.images);
          let postFileUploadData = await FolderService.uploadFileInFolder(idOfFolderFiles, rawData)
          if (postFileUploadData.data.success) {
            pageLoader('hide');
            let updatedFolderAndFiles = Object.assign([], folderAndFiles || [])
            let updateFilesInFolder = Object.assign([], filesInFolder || [])
            let uploadedFileRes = postFileUploadData.data.data
            let indexOfFolderAndFiles = updatedFolderAndFiles.findIndex(obj => Number(obj.id) === Number(idOfFolderFiles))
            updatedFolderAndFiles[indexOfFolderAndFiles].PresenterLibraryFiles.push(uploadedFileRes)
            updateFilesInFolder.push(uploadedFileRes)
            successToastr(config.langLabels.fileUploaded)
            this.setState({
              filesInFolder: updateFilesInFolder,
              folderAndFiles: updatedFolderAndFiles,
              fileProgress: {
                fileName: '',
                fileUploadProgress: 0
              }
            })
          }
        } else {
          this.setState({
            fileProgress: {
              fileName: '',
              fileUploadProgress: 0
            }
          }, () => {
            pageLoader('hide');
            errorToastr(response.data.message)
          })
        }
      } catch (error) {

      }
    }
  }

  fileChange = (e) => {
    const { files: FILELIST } = e.target
    let fileName = '';
    let file = {};

    if (
      FILELIST.length &&
      FILELIST.length === 1 &&
      validateExistsInAllFile(FILELIST[0].name) &&
      validateFileSize(FILELIST[0].size)
    ) {
      fileName = FILELIST[0].name
      file = FILELIST[0]
    }

    this.setState({
      fileName,
      file,
      fileProgress: {
        fileName: fileName,
        fileUploadProgress: 0
      }
    }, () => {
      this.uploadFile()
    });
  }

  deleteFolder = (data, index) => {
    deleteFolderFromList(this.deleteFolderAfterConfirm, data, index)
  }

  deleteFolderAfterConfirm = async (result, data, index) => {
    pageLoader('show');
    let deleteFolderServiceData = await FolderService.deleteSingleFolder(data.id)
    if (deleteFolderServiceData.data.success) {
      let folderAndFiles = this.state.folderAndFiles
      folderAndFiles.splice(index, 1)
      this.setState({
        folderAndFiles
      })
      pageLoader('hide');
      successToastr(config.langLabels.folderdeleted)
    } else {
      errorToastr(deleteFolderServiceData.data.message)
    }
  }

  deleteSingleFile = (data, index) => {
    data = [data.id]
    index = [index]
    deleteFileFromList(this.deleteFileAfterConfirm, data, index)
  }

  deleteMultiFile = () => {
    let deleteMultiFiles = this.state.deleteMultiFiles;
    let data = []
    let index = []
    deleteMultiFiles.map((d, i) => {
      data.push(d.id)
      index.push(d.index)
    })

    deleteFileFromList(this.deleteFileAfterConfirm, data, index)
  }

  deleteFileAfterConfirm = async (result, data, index) => {
    pageLoader('show');
    let payloadData = data.join(',');
    let deleteFolderServiceData = await FolderService.deleteSingleMultiFiles({ ids: payloadData })
    if (deleteFolderServiceData.data.success) {
      let filesInFolder = this.state.filesInFolder
      filesInFolder = filesInFolder.filter(obj => !data.includes(obj.id))
      this.setState({
        filesInFolder,
        deleteMultiFiles: []
      })
      pageLoader('hide');
      successToastr(config.langLabels.filedeleted)
    } else {
      errorToastr(deleteFolderServiceData.data.message)
    }
  }

  addRemoveFileFromSelected = (e, data, index) => {
    let deleteMultiFiles = this.state.deleteMultiFiles

    if (e.target.checked) {
      deleteMultiFiles.push({
        id: data.id,
        data,
        index
      })
    } else {
      let spliceId = deleteMultiFiles.findIndex(obj => obj.id === data.id)
      if (spliceId > -1) {
        deleteMultiFiles.splice(spliceId, 1)
      }
    }

    this.setState({
      deleteMultiFiles
    })
  }

  renameFolder = async (folderName, data, index) => {
    pageLoader('show');
    let paylaod = {
      folderName
    }
    let updateFolderServiceData = await FolderService.updateFolderName(data.id, paylaod)
    if (updateFolderServiceData.data.success) {
      let folderAndFiles = this.state.folderAndFiles
      folderAndFiles[index].folderName = folderName;
      this.setState({
        folderAndFiles
      })
      pageLoader('hide');
      successToastr(config.langLabels.folderRename)
    } else {
      errorToastr(updateFolderServiceData.data.message)
    }
  }

  renameFile = async (fileName, data, index) => {
    pageLoader('show');
    fileName = fileName.replace(/ /g, "-")
    let paylaod = {
      fileName
    }
    let updateFileServiceData = await FolderService.updateFileName(data.id, paylaod)
    if (updateFileServiceData.data.success) {
      let filesInFolder = this.state.filesInFolder
      let fileExtension = filesInFolder[index].fileName.split(".").pop()
      filesInFolder[index].fileName = `${fileName}.${fileExtension}`;
      this.setState({
        filesInFolder
      })
      pageLoader('hide');
      successToastr(config.langLabels.fileRename)
    } else {
      errorToastr(updateFileServiceData.data.message)
    }
  }

  render() {
    const { isInFolder, folderAndFiles, filesInFolder, selectedFileId, selectedFolderName, deleteMultiFiles, fileSearchKey, fileProgress, isLoading } = this.state
    let fileInputId = this.props.type ? `newFile_${this.props.type}` : `newFile_upload`
    return (
      <div className="modal fade" id="libraryModal" tabIndex="-1" role="dialog" aria-labelledby="libraryModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered w-570 add-library" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="icon-cancel"></i>
              </button>
              <h5 className="modal-title font-neomd m-0">{config.langLabels.library}</h5>
            </div>
            {
              isLoading ? <div className="p-5"><ListLoader /></div> : <div className="modal-body pt-0 px-0 bg-grey text-right" dir="rtl">
                <form>
                  <div className="searchSection">
                    <div className="form-group">
                      <input type="text" value={fileSearchKey} onChange={(e) => this.setState({ fileSearchKey: e.target.value })} name="" className="form-control rounded-0 border-top-0 border-left-0 border-right-0" placeholder={config.langLabels.searchFilePlaceholder} />
                      {
                        isInFolder && <>
                          <button value="closeSearch" onClick={(e) => { e.preventDefault(); closeSearch(e); }} type="button" className="closeSearch p-0 btn btn-light bg-transparent border-0">
                            <i className="icon-cancel"></i>
                          </button>
                          <button value="openSearch" onClick={(e) => { e.preventDefault(); openSearch(e); }} type="button" className="openSearch p-0 btn btn-light bg-transparent border-0">
                            <i className="icon-search"></i>
                          </button>
                        </>
                      }
                    </div>
                    {

                      !isInFolder ? <Link
                        to=""
                        onClick={(e) => {
                          e.preventDefault()
                          feedCreateNewFolderTitle(this.addNewFolder)
                        }}
                      >
                        <span className="addFolder">
                          <span className="icon-create_new_folder"></span>
                        </span>
                      </Link> : <Link
                        to=""
                        className="folderBackbtn"
                        style={{ display: 'inline' }}
                        onClick={(e) => {
                          e.preventDefault()
                          // folderBackbtn(e);
                          this.goToRoot()
                        }}
                      >
                          <i className="icon-back d-inline-block align-middle"></i> <span className="d-inline-block align-middle">{selectedFolderName}</span>
                        </Link>
                    }
                  </div>
                  <div id="folderList">
                    <ul className="list-unstyled folderList d-flex flex-wrap mb-0">
                      {
                        !isInFolder && folderAndFiles.map((data, index) => {
                          return <li className="" key={data.id}>
                            <Link
                              to=""
                              className="folderBox d-flex align-items-center"
                              onClick={(e) => {
                                e.preventDefault()
                                this.filesOfFolder(data.id, data)
                                // folderbox()
                              }}
                            >
                              <div className="folderIcon">
                                <span className="icon-folder"></span>
                              </div>
                              <div className="text">
                                <span className="name d-block">{data.folderName}</span>
                                <span className="number d-block">{data.PresenterLibraryFiles.length} {config.langLabels.files}</span>
                              </div>
                            </Link>
                            <div className="dropdown">
                              <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="icon-ellipsis-v"></span>
                              </a>
                              <div className="dropdown-menu text-right" aria-labelledby="dropdownMenuLink">
                                <Link
                                  to=""
                                  className="dropdown-item"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    this.deleteFolder(data, index)
                                  }}
                                >{config.langLabels.deleteFolder}</Link>
                                <Link
                                  to=""
                                  className="dropdown-item"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    renameFolderTitle(this.renameFolder, data, index)
                                  }}
                                >{config.langLabels.rename}</Link>
                              </div>
                            </div>
                          </li>
                        })
                      }
                    </ul>
                  </div>
                  <ul className="list-unstyled fileList">
                    {
                      isInFolder && filesInFolder.filter((obj) => obj.fileName.includes(fileSearchKey)).map((data, index) => {
                        return <li key={`file_${data.id}`}>
                          <div className="fileBox d-flex align-items-center">
                            <div className="custom-control custom-checkbox">
                              <input type="checkbox" name="file" className="custom-control-input" id={`file_2_${data.id}`}
                                onChange={(e) => {
                                  this.addRemoveFileFromSelected(e, data, index)
                                }}
                                checked={deleteMultiFiles.filter(obj => obj.id === data.id).length > 0 ? true : false}
                              />
                              <label htmlFor={`file_2_${data.id}`}><span></span></label>
                            </div>
                            <div className="fileIcon d-flex">
                              <img src={`/assets/images/${getFileIconByType(data.fileName)}`} alt="word icon" className="img-fluid" />
                            </div>
                            <div className="text">
                              <span className="name d-block">{data.fileName}</span>
                              <span className="number d-block">{moment(data.createdAt).format('MMMM DD, YYYY')}</span>
                            </div>
                            <div className="dropdown mr-auto">
                              <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="icon-ellipsis-v"></span>
                              </a>
                              <div className="dropdown-menu text-right" aria-labelledby="dropdownMenuLink">
                                <Link
                                  to=""
                                  className="dropdown-item"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    this.deleteSingleFile(data, index)
                                  }}
                                >{config.langLabels.deleteFile}</Link>
                                <Link
                                  to=""
                                  className="dropdown-item"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    renameFileTitle(this.renameFile, data, index)
                                  }}
                                >{config.langLabels.rename}</Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      })
                    }
                    {
                      (isInFolder && fileProgress.fileName) && <li className="fileInProcess">
                        <div className="fileBox d-flex align-items-center">
                          {/* <div className="custom-control custom-checkbox">
                          <input type="checkbox" name="file" className="custom-control-input" id="file1" onchange="selectFile(this)" />
                          <label htmlFor="file1"><span></span></label>
                        </div> */}
                          <div className="fileIcon d-flex">
                            <img src={`/assets/images/${getFileIconByType(fileProgress.fileName)}`} alt="word icon" className="img-fluid" />
                          </div>
                          <div className="text">
                            <span className="name d-block">{fileProgress.fileName}</span>
                            {/* <span className="number d-block">June 15, 2019 4 mb</span> */}
                          </div>
                        </div>
                        <div className="fileProgress uploading mr-auto">
                          <div className="fileUploading d-flex align-items-center">
                            <div className="progressSec ml-3">
                              <label className="mb-1">{config.langLabels.percentageCompleted(fileProgress.fileName, fileProgress.fileUploadProgress)}</label>
                              <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: `${fileProgress.fileUploadProgress}%` }} aria-valuenow={fileProgress.fileUploadProgress} aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                            </div>
                            {/* <span className="actionButton closeButton" onclick="fileFailed(event)">
                              <i className="icon-cancel"></i>
                            </span> */}
                          </div>
                          {/* <div className="fileUploadingFailed d-flex align-items-center">
                            <label className="mb-0 ml-2">File upload failed</label>
                            <span className="actionButton refreshButton" onclick="fileUploding(event)">
                              <i className="icon-refresh"></i>
                            </span>
                          </div> */}
                        </div>

                      </li>
                    }
                  </ul>
                  {
                    (!isInFolder && folderAndFiles.length === 0) && <div className="alert alert-danger" role="alert">
                      {config.langLabels.errMsgNoFolder}
                    </div>
                  }
                  {
                    (isInFolder && filesInFolder.length === 0) && <div className="alert alert-danger" role="alert">
                      {config.langLabels.errMsgNoFile}
                    </div>
                  }
                </form>
                {
                  (isInFolder && deleteMultiFiles.length < 1) && <div className="btn-row mt-4 upload">
                    <div className="d-flex">
                      <label htmlFor={fileInputId} className="mb-0 btn btn-dark ripple-effect w_170 ml-2 d-flex align-items-center">
                        <input
                          type="file"
                          id={fileInputId}
                          className="d-none"
                          name=""
                          onChange={(e) => {
                            e.preventDefault()
                            this.fileChange(e)
                          }}
                          accept={ACCEPT_ALL_TYPE}
                        />
                        <i className="icon-cloud_upload ml-2"></i> {config.langLabels.uploadFile}
                      </label>
                      <button className="btn btn-dark ripple-effect w_170 mr-auto" disabled="disabled" type="button">
                        {config.langLabels.addFileOnBoard}
                      </button>
                    </div>
                  </div>
                }
                {
                  (isInFolder && deleteMultiFiles.length > 0) && <div className="btn-row mt-4 delete">
                    <div className="d-flex">
                      <button
                        className="btn btn-dark ripple-effect w_170  d-flex align-items-center"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault()
                          this.deleteMultiFile()
                        }}
                      >
                        <span className="icon-delete ml-2"></span>
                        {config.langLabels.delete}
                      </button>
                      {
                        (deleteMultiFiles.length === 1) && <button
                          className="btn btn-primary ripple-effect w_170 mr-auto"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault()
                            this.selectedFile(deleteMultiFiles[0].id, deleteMultiFiles[0].data)
                          }}
                        >
                          {config.langLabels.addFileOnBoard}
                        </button>
                      }
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    toolTypeReduxState: state.toolTypeProps,
    userReduxState: state.userProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updateToolTypeState: data => dispatch(updateToolType(data)),
    addWhiteBoardInRedux: (data) => dispatch(addWhiteBoard(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LibraryModal)