import audioVideoState from "../reduxStates/audioVideoState";
import { UPDATE_PRESENTER_VIDEO_MUTE } from "../reduxConst/audioVideoConst";

let updatePresenterVideoMute = (state, action) => {
  return {
    ...state,
    presenterVideoMute: action.data
  }
}

let audioVideoReducer = (state = audioVideoState, action) => {

  switch (action.type) {

    case UPDATE_PRESENTER_VIDEO_MUTE:

      return updatePresenterVideoMute(state, action)

    default:
      return state;
  }

}

export default audioVideoReducer;