import { ADD_WHITE_BOARD, REMOVE_WHITE_BOARD, NEW_ACTIVE_WHITE_BOARD, NEW_ACTIVE_WHITE_BOARD_OWNER, RESET_WHITE_BOARD, UPDATE_IS_RECORDING_ON, UPDATE_START_SCREEN_SHARE } from "../reduxConst/whiteBoardConst";
import config from '../../config';
import whiteBoardState from "../reduxStates/whiteBoardState";

let addWhiteBoardRedux = (state, action) => {
  let newBoardId = action.data.boardId;
  let newBoardTitle = action.data.title;
  let boardType = action.data.boardType;
  let addWhiteBoardActiveWhiteBoardId = state.activeWhiteBoardId;
  let addWhiteBoardWhiteBoards = state.whiteBoards;
  if (state.whiteBoards.length < config.maxBoards) {
    addWhiteBoardActiveWhiteBoardId = newBoardId
    addWhiteBoardWhiteBoards.push({
      ...action.data,
      boardId: newBoardId,
      title: newBoardTitle,
      boardType: boardType
    })
  }

  return {
    ...state,
    whiteBoards: addWhiteBoardWhiteBoards,
    activeWhiteBoardId: addWhiteBoardActiveWhiteBoardId
  }
}

let removeWhiteBoardRedux = (state, action) => {
  let removeWhiteBoard = state;
  if (removeWhiteBoard.whiteBoards.length > 1) {
    let removeBoardId = Number(action.data);
    let removeIndex = removeWhiteBoard.whiteBoards.findIndex(data => Number(data.boardId) === removeBoardId);
    let newActiveWhiteBoardId = ''
    if (removeIndex === 0) {
      newActiveWhiteBoardId = removeWhiteBoard.whiteBoards[1].boardId
    } else {
      newActiveWhiteBoardId = removeWhiteBoard.whiteBoards[removeIndex - 1].boardId
    }
    removeWhiteBoard.activeWhiteBoardId = Number(newActiveWhiteBoardId)
    removeWhiteBoard.whiteBoards.splice(removeIndex, 1);
  }

  return {
    ...state,
    ...removeWhiteBoard
  }
}

let newActiveWhiteBoard = (state, action) => {
  return {
    ...state,
    activeWhiteBoardId: Number(action.data)
  }
}

let newActiveWhiteBoardOwner = (state, action) => {
  return {
    ...state,
    activeWhiteBoardOwner: {
      ...action.data,
      mediaPermissions: action.data.mediaPermissions,
      actionTaken: action.data.actionTaken
    }
  }
}

let resetWhiteBoard = (state, action) => {
  return {
    ...state,
    whiteBoards: action.data.whiteBoards,
    activeWhiteBoardId: action.data.activeWhiteBoardId,
    activeWhiteBoardOwner: action.data.activeWhiteBoardOwner
  }
}

let updateRecordingOn = (state, action) => {
  return {
    ...state,
    isRecordingOn: action.data
  }
}

let updateStartScreenShare = (state, action) => {
  return {
    ...state,
    startScreenShareModal: action.data
  }
}

let whiteBoardCollectionReducer = (state = whiteBoardState, action) => {
  switch (action.type) {
    case ADD_WHITE_BOARD:

      return addWhiteBoardRedux(state, action);

    case REMOVE_WHITE_BOARD:

      return removeWhiteBoardRedux(state, action);

    case NEW_ACTIVE_WHITE_BOARD:

      return newActiveWhiteBoard(state, action);

    case NEW_ACTIVE_WHITE_BOARD_OWNER:

      return newActiveWhiteBoardOwner(state, action);

    case RESET_WHITE_BOARD:

      return resetWhiteBoard(state, action);

    case UPDATE_IS_RECORDING_ON:

      return updateRecordingOn(state, action);

    case UPDATE_START_SCREEN_SHARE:

      return updateStartScreenShare(state, action);

    default:
      return state;
  }

}

export default whiteBoardCollectionReducer;