// import all settings for production env
import productionConfig from "./production";
import socketIOClient from 'socket.io-client'

// import all settings for development env
import developmentConfig from "./development";
import engLang from './english.js';
import arbLang from './arabic.js';
import { ENV, LANG } from "../../env";

let config = ENV === 'development' ? developmentConfig : productionConfig;

config = {
  ...config,
  langLabels: LANG === 'english' ? engLang : arbLang,
  socketClient: socketIOClient(config.SOCKET_URL)
}

export default config;