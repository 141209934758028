import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../header';
import Modals from '../../modals';
import AttendeeSideBar from '../../sidebar/attendeeSideBar';
import PageLoader from '../../loaders/pageLoader';
import { Link } from 'react-scroll';
import { tggleSidebar, tggleVideo, showOverlay, hideOverlay, toggleVideoStreem } from '../../../utils/commonJsFunctions';
import RequestBoxButton from '../../chatList/requestBoxButton';
import TimerClock from '../../header/timerClock';

const AttendeeLayout = (props) => {
  const { children, userReduxState, whiteBoardReduxState } = props;

  return (
    <>
      <div className="pageWrapper_message align-items-center justify-content-center d-md-none">
        <h3 className="text-center">This Application is only viewable <br /> in landscape mode</h3>
      </div>
      <div className="pageWrapper">
        <Header />
        <main className="main-content homePage studentPage">
          <div className="mainWrapper">
            <div className="mainWrapper__left">
              <AttendeeSideBar />
            </div>
            <div className="mainWrapper__right bg-white">
              {children}
              <Link to=""
                onClick={(e) => {
                  e.preventDefault()
                  showOverlay()
                }}
                className="overlayHideShowBtn d-lg-none"><img src="/assets/images/layer-icon.svg" alt="layer icon" /></Link>
              <div
                onClick={(e) => {
                  e.preventDefault()
                  hideOverlay()
                }}
                className="mobileView d-lg-none">
                <div className="mobileView__top d-flex align-items-center justify-content-between">
                  <label className="font-neomd mb-0"><span>
                    <TimerClock userReduxState={userReduxState} />
                  </span></label>
                  <span className="font-hrg">{("sessionInfo" in userReduxState) ? userReduxState.sessionInfo.title : ''}</span>
                </div>
              </div>
              <div className="mobileViewBottom align-items-center justify-content-between d-lg-none">
                <div className="mobileViewBottom__left">
                  {
                    (userReduxState && (Number(userReduxState.userId) !== Number(userReduxState.sessionId))) && <>
                      <RequestBoxButton />
                    </>
                  }
                </div>
                <div className="mobileViewBottom__right d-inline-flex">
                  <Link to=""
                    id="responsiveForMessage"
                    onClick={(e) => {
                      e.preventDefault()
                      tggleSidebar()
                    }}
                    className="d-none align-items-center">
                    <i className="icon-chat"></i>
                  </Link>
                  <Link to=""
                    id="responsiveForVideo"
                    onClick={(e) => {
                      e.preventDefault()
                      tggleVideo()
                    }}
                    className="d-flex align-items-center active">
                    <i className="icon-user"></i>
                  </Link>
                  {
                    (
                      "activeWhiteBoardOwner" in whiteBoardReduxState &&
                      "mediaPermissions" in whiteBoardReduxState.activeWhiteBoardOwner &&
                      whiteBoardReduxState.activeWhiteBoardOwner.mediaPermissions.length > 1
                    ) && <Link to=""
                      onClick={(e) => {
                        e.preventDefault()
                        toggleVideoStreem()
                      }}
                      id="responsiveForMultiVideo" className="d-flex align-items-center">
                      <i className="icon-video-library"></i>
                    </Link>
                  }
                </div>
              </div>
              <Modals />
              <PageLoader />
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

AttendeeLayout.propTypes = {
  children: PropTypes.node
};


let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps,
    chatReduxState: state.chatProps,
    whiteBoardReduxState: state.whiteBoardProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendeeLayout)
