import React, { Component } from 'react'
import { connect } from 'react-redux';
import kurentoUtils from "kurento-utils";
import { receiveScreenOfferSocketOn, sendPresenterScreen, screenShareStopedSocketOn, iceCandidateScreenSocketOn, restartScreenShareSocketOn } from '../../socket';
import { updateRecordingOn, updateStartScreenShare } from '../../redux/actions/whiteBoardAction';
import config from '../../config';

let webRtcPeer;
let videoOutput

function onError(error) {
  console.error(error);
}

function sendMessage(message, userInfo) {
  message.userId = userInfo.userId;
  message.userType = userInfo.userType;
  var jsonMessage = JSON.stringify(message);
  sendPresenterScreen(jsonMessage);
}

function dispose() {
  if (webRtcPeer) {
    webRtcPeer.dispose();
    webRtcPeer = null;
    hideSpinner(videoOutput);
  }
}

function showSpinner() {
  for (var i = 0; i < arguments.length; i++) {
    arguments[i].poster = '/assets/images/transparent-1px.png';
    arguments[i].style.background = 'center transparent url("/assets/images/spinner.gif") no-repeat';
  }
}

function hideSpinner() {
  for (var i = 0; i < arguments.length; i++) {
    arguments[i].src = '';
    arguments[i].poster = '/assets/images/webrtc.png';
    arguments[i].style.background = '';
  }
}

class ScreenShareViewer extends Component {

  state = {
    playVideo: false
  }

  componentDidMount() {
    this.resendAttendeeOfferOnSocket();
    screenShareStopedSocketOn(this.screenShareStopedOnSocket)
    restartScreenShareSocketOn(this.resendAttendeeOfferOnSocket);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userReduxState !== this.props.userReduxState) {
      //this.resendAttendeeOfferOnSocket()
    }
  }

  componentWillUnmount() {
    dispose();
  }

  resendAttendeeOfferOnSocket = () => {
    let that = this
    videoOutput = document.getElementById('videoScreenView');
    showSpinner(videoOutput);
    let userInfo = this.props.userReduxState

    if (webRtcPeer) {
      webRtcPeer = null;
    }

    if (!webRtcPeer && Object.keys(userInfo).length) {
      var options = {
        remoteVideo: videoOutput,
        onicecandidate: this.onIceCandidate,
        configuration: config.ICE_SERVER_CONFIG
      }
      webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, function (error) {
        if (error) return onError(error);
        this.generateOffer(that.onOfferViewer);
      });
      receiveScreenOfferSocketOn(this.onMessageReceived, webRtcPeer);

    }
  }

  onIceCandidate = (candidate) => {
    var message = {
      id: 'onIceCandidate',
      candidate: candidate
    }
    let userInfo = this.props.userReduxState
    sendMessage(message, userInfo);
  }


  onMessageReceived = (message, webRtcPeer) => {
    var parsedMessage = JSON.parse(message);
    switch (parsedMessage.id) {
      case 'viewerResponse':
        this.viewerResponse(parsedMessage, webRtcPeer);
        break;
      case 'iceCandidate':
        webRtcPeer.addIceCandidate(parsedMessage.candidate);
        break;
      case 'stopCommunication':
        dispose(webRtcPeer);
        break;
      default:
        console.error('Unrecognized message', parsedMessage);
    }
  }

  viewerResponse = (message, webRtcPeer) => {
    if (message.response !== 'accepted') {
      var errorMsg = message.message ? message.message : 'Unknow error';
      console.warn('Call not accepted for the following reason: ' + errorMsg);
      dispose();
    } else {
      webRtcPeer.processAnswer(message.sdpAnswer);

    }
  }

  onOfferViewer = (error, offerSdp) => {
    if (error) return onError(error)
    var message = {
      id: 'viewer',
      sdpOffer: offerSdp,
    }
    let userInfo = this.props.userReduxState
    sendMessage(message, userInfo);
  }

  screenShareStopedOnSocket = (data) => {
    dispose()
    this.props.updateRecordingOnState(false)
    this.props.updateStartScreenShareState(false)
  }

  render() {
    const { userReduxState, whiteBoardReduxState } = this.props;
    return (
      <>
        {
          (
            Object.keys(userReduxState).length > 0 &&
            userReduxState.userType !== 'presenter' &&
            whiteBoardReduxState.startScreenShareModal &&
            whiteBoardReduxState.isRecordingOn
          ) && <div className={`userVideo videoScreen`}>
            <div className="image text-center">
              <video id="videoScreenView" autoPlay muted width="100%"></video>
            </div>
          </div>
        }

      </>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    whiteBoardReduxState: state.whiteBoardProps,
    userReduxState: state.userProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updateRecordingOnState: data => dispatch(updateRecordingOn(data)),
    updateStartScreenShareState: data => dispatch(updateStartScreenShare(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenShareViewer)