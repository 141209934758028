import React from 'react';
import YoutubeIframe from "./youtubeIframe"
import FacebookIframe from "./facebookIframe"
import { getSiteTypeURL } from "../../utils/customValidator"
import WebsiteIframe from './websiteIframe';
import TwitterIframe from './twitterIframe';

let VideoPlayer = (url, activeWhiteBoardId, cWidth, cHeight) => {
  let key = getSiteTypeURL(url);
  switch (key) {
    case 'youtube':
      return <YoutubeIframe videoUrl={url} boardId={activeWhiteBoardId} />
    case 'facebook':
      return <FacebookIframe videoUrl={url} boardId={activeWhiteBoardId} />
    case 'twitter':
      return <TwitterIframe videoUrl={url} boardId={activeWhiteBoardId} />
    case 'website':
      return <WebsiteIframe webUrl={url} boardId={activeWhiteBoardId} cWidth={cWidth} cHeight={cHeight} />
    default:
      return '';
  }
}

export default VideoPlayer



