import { FACEBOOK_REGEXP, TWITTER_REGEXP, YOUTUBE_REGEXP, URL_REGEXP, ALLOW_ALL, ALLOW_WORD, ALLOW_PDF, ALLOW_EXCEL, ALLOW_PRESENTATION, ALLOW_TEXT, ALLOW_IMAGES, MAX_FILE_SIZE } from "./constants";
import { errorToastr } from "./handelError";

export let generateFacebookIFrameURL = (url) => {
  let match = url.match(FACEBOOK_REGEXP);

  if (match && match[0].length) {
    return `https://www.facebook.com/plugins/video.php?href=${encodeURI(match[0])}`;
  } else {
    return url;
  }
}

export let generateTwitterIFrameURL = (url) => {
  let match = url.match(TWITTER_REGEXP);

  if (match && match[0].length) {
    return `https://twitframe.com/show?url=${match[0]}`;
  } else {
    return url;
  }
}

export let generateYoutubeIFrameURL = (url) => {
  let match = url.match(YOUTUBE_REGEXP);

  if (match && match[1].length === 11) {
    return `https://www.youtube.com/embed/${match[1]}`;
  } else {
    return url;
  }
}

export let addHttpsURL = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    url = 'https://' + url;
  }
  return url
}

export let regexValidURL = (url) => {
  return URL_REGEXP.test(url)
}

export let regexFbVideoURL = (url) => {
  return FACEBOOK_REGEXP.test(url)
}

export let regexYoutubeURL = (url) => {
  return YOUTUBE_REGEXP.test(url)
}

export let regexTwitterURL = (url) => {
  return TWITTER_REGEXP.test(url)
}

export let validateSocialMediaURL = (url) => {
  let isValidUrl = false
  if (regexFbVideoURL(url) || regexYoutubeURL(url) || regexTwitterURL(url)) {
    isValidUrl = true
  }

  return isValidUrl
}

export let generateSocialMediaIFrameURL = (url) => {
  let iframeURL = ''
  if (regexFbVideoURL(url)) {
    iframeURL = generateFacebookIFrameURL(url)
  } else if (regexYoutubeURL(url)) {
    iframeURL = generateYoutubeIFrameURL(url)
  } else if (regexTwitterURL(url)) {
    iframeURL = generateTwitterIFrameURL(url)
  }

  return iframeURL
}

export let getSiteTypeURL = (url) => {
  let siteType = ''
  if (regexFbVideoURL(url)) {
    siteType = 'facebook'
  } else if (regexYoutubeURL(url)) {
    siteType = 'youtube'
  } else if (regexTwitterURL(url)) {
    siteType = 'twitter'
  } else if (url) {
    siteType = 'website'
  }

  return siteType
}

export let validateFileSize = (fileSize) => {
  let boolFileSize = fileSize <= MAX_FILE_SIZE

  if (!boolFileSize) {
    errorToastr(`Only upto ${Math.round(MAX_FILE_SIZE / 1000000)}MB size file is allowed`)
  }

  return boolFileSize
}

export let validateExistsInAllFile = (fileName) => {
  let ext = fileName.split('.').pop().toLowerCase();
  let boolFileAllowed = ALLOW_ALL.includes(ext);
  if (!boolFileAllowed) {
    errorToastr(`Allowed extensions are ${ALLOW_ALL.join(', ')} selected ${ext} extension is not allowed`)
  }
  return boolFileAllowed
}

export let validateExistsInTypeOfFile = (fileName, formType) => {
  let ext = fileName.split('.').pop().toLowerCase();
  let boolFileAllowed = false;
  let AllowedDocExt = [];

  if (formType === 'presentation') {
    AllowedDocExt = ALLOW_PRESENTATION
    boolFileAllowed = AllowedDocExt.includes(ext);
  } else {
    AllowedDocExt = [...ALLOW_IMAGES, ...ALLOW_WORD, ...ALLOW_PDF, ...ALLOW_EXCEL, ...ALLOW_TEXT]
    boolFileAllowed = AllowedDocExt.includes(ext);
  }

  if (!boolFileAllowed) {
    errorToastr(`Allowed extensions are ${AllowedDocExt.join(', ')} selected ${ext} extension is not allowed`)
  }

  return boolFileAllowed
}

export let getFileIconByType = (fileURL) => {
  let ext = fileURL.split('.').pop().toLowerCase();
  let icon = 'word-rounded-icon.svg'
  if (ALLOW_WORD.includes(ext)) {
    icon = 'word-rounded-icon.svg'
  } else if (ALLOW_PDF.includes(ext)) {
    icon = 'pdf-rounded-icon.svg'
  } else if (ALLOW_EXCEL.includes(ext)) {
    icon = 'exel-rounded-icon.svg'
  } else if (ALLOW_PRESENTATION.includes(ext)) {
    icon = 'powerpoint-rounded-icon.svg'
  } else if (ALLOW_TEXT.includes(ext)) {
    icon = 'word-rounded-icon.svg'
  }

  return icon
}