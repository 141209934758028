import toolTypeState from "../redux/reduxStates/toolTypeState";

export let toolTypeData = (tool, toolOptions, reduxToolData) => {
  let data = {
    ...toolTypeState,
    options: {
      ...toolTypeState.options,
      color: reduxToolData.options.color,
      url_video: reduxToolData.options.url_video,
      doc_images: reduxToolData.options.doc_images,
      board_name: reduxToolData.options.board_name,
    }
  }

  if (toolOptions && Object.keys(toolOptions).length && 'strokeWidth' in toolOptions && reduxToolData.tool === 'pencil') {
    data.tool = reduxToolData.tool;
  }

  if (toolOptions && Object.keys(toolOptions).length && 'color' in toolOptions && reduxToolData.options.fill !== '') {
    data = reduxToolData
    data.options.fill = toolOptions.color;
  }

  switch (tool) {
    case 'browse':
      data = {
        ...data,
        tool: tool
      }
      break;
    case 'clear':
      data = {
        ...data,
        tool: tool
      }
      break;
    case 'undo':
      data = {
        ...data,
        tool: tool
      }
      break;
    case 'redo':
      data = {
        ...data,
        tool: tool
      }
      break;
    case 'color':
      data = {
        tool: ['undo', 'redo'].includes(reduxToolData.tool) ? 'color' : reduxToolData.tool,
        options: {
          ...reduxToolData.options,
          color: toolOptions.color
        }
      }
      break;
    case 'table':
      data = {
        ...data,
        tool: tool,
        options: {
          ...data.options,
          rows: Number(toolOptions.rowNumber + 1),
          columns: Number(toolOptions.columnNumber + 1)
        }
      }
      break;
    case 'single-head-arrow':
      data = {
        ...data,
        tool: tool
      }
      break;
    case 'double-head-arrow':
      data = {
        ...data,
        tool: tool
      }
      break;
    case 'curved-arrow':
      data = {
        ...data,
        tool: tool
      }
      break;
    case 'line':
      data = {
        ...data,
        tool: tool
      }
      break;
    case 'heartFill':
      data = {
        tool: 'heart',
        options: {
          ...data.options,
          fill: data.options.color
        }
      }
      break;
    case 'pentagonFill':
      data = {
        tool: 'polygon',
        options: {
          ...data.options,
          edge: 5,
          fill: data.options.color
        }
      }
      break;
    case 'triangleFill':
      data = {
        tool: 'triangle',
        options: {
          ...data.options,
          fill: data.options.color
        }
      }
      break;
    case 'squareFill':
      data = {
        tool: 'rectangle',
        options: {
          ...data.options,
          fill: data.options.color,
          angle: 0,
          cornerradius: 'no'
        }
      }
      break;
    case 'polygonFill':
      data = {
        tool: 'polygon',
        options: {
          ...data.options,
          edge: 6,
          fill: data.options.color
        }
      }
      break;
    case 'fontagonFill':
      data = {
        tool: 'rectangle',
        options: {
          ...data.options,
          fill: data.options.color,
          angle: 45,
          cornerradius: 'no'
        }
      }
      break;
    case 'circleFill':
      data = {
        tool: 'circle',
        options: {
          ...data.options,
          fill: data.options.color
        }
      }
      break;
    case 'rectangleFill':
      data = {
        tool: 'rectangle',
        options: {
          ...data.options,
          fill: data.options.color,
          angle: 0,
          cornerradius: 'yes'
        }
      }
      break;
    case 'heartOutline':
      data = {
        ...data,
        tool: 'heart'
      }
      break;
    case 'pentagonOutline':
      data = {
        tool: 'polygon',
        options: {
          ...data.options,
          edge: 5,
        }
      }
      break;
    case 'triangleOutline':
      data = {
        ...data,
        tool: 'triangle'
      }
      break;
    case 'squareOutline':
      data = {
        tool: 'rectangle',
        options: {
          ...data.options,
          angle: 0,
          cornerradius: 'no'
        }
      }
      break;
    case 'polygonOutline':
      data = {
        tool: 'polygon',
        options: {
          ...data.options,
          edge: 6
        }
      }
      break;
    case 'fontagonOutline':
      data = {
        tool: 'rectangle',
        options: {
          ...data.options,
          angle: 45,
          cornerradius: 'no'
        }
      }
      break;
    case 'circleOutline':
      data = {
        tool: 'circle',
        options: {
          ...data.options
        }
      }
      break;
    case 'rectangleOutline':
      data = {
        tool: 'rectangle',
        options: {
          ...data.options,
          angle: 0,
          cornerradius: 'yes'
        }
      }
      break;
    case 'eraser':
      data = {
        tool: 'eraser',
        options: {
          ...data.options
        }
      }
      break;
    case 'highliter':
      data = {
        ...data,
        tool: 'highliter',
        options: {
          ...reduxToolData.options,
          strokeWidth: Number(toolOptions.strokeWidth) < 10 ? 10 : Number(toolOptions.strokeWidth)
        }
      }
      break;
    case 'text':
      data = {
        ...data,
        tool: tool
      }
      break;
    case 'updatetext':
      data = {
        tool: tool,
        options: {
          ...reduxToolData.options,
          ...toolOptions
        }
      }
      break;
    case 'pencil':
      data = {
        tool: tool,
        options: {
          ...reduxToolData.options,
          strokeWidth: 1
        }
      }
      break;
    case 'video':
      data = {
        tool: tool,
        options: {
          ...data.options,
          url_video: toolOptions.url_video
        }
      }
      break;
    case 'document':
      data = {
        tool: tool,
        options: {
          ...data.options,
          doc_images: toolOptions.doc_images
        }
      }
      break;
    case 'select':
      data = {
        ...data,
        tool: 'pane'
      }
      break;

    default:

      break;
  }
  return data
}