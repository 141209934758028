import commonConfig from "./common";

let productionConfig = {
  API_URL: 'https://v-meeting-api.codiantdev.com/api',
  LIBRARY_URL: 'https://v-meeting-api.codiantdev.com',
  SOCKET_URL: 'https://v-meeting-api.codiantdev.com',
  DOC_UPLOAD: 'https://prortc.com:3030/upload-document',
  ICE_SERVER_CONFIG: {
    iceServers: [{
      "urls": ["turn:3.95.2.177:3478"],
      "username": "navalp",
      "credential": "codiant2020"
    }],
    iceTransportPolicy: 'relay'
  },
  ...commonConfig
}

export default productionConfig;