import axios from 'axios';
import { handelApiError, handelApiRes } from './handelError';
import config from '../config';

// api headers config
let axiosConfig = {
  'language': 'en',
  'Content-Type': 'application/json',
  "Access-Control-Allow-Origin": "*",
  "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
};

let API_URL = config.API_URL;

// post api 
const postApi = async (url = '', data, config = { headers: axiosConfig }, APIURL = API_URL) => {
  try {
    url = APIURL + url;
    let response = await axios.post(url, data, config);
    return handelApiRes(response);
  } catch (error) {
    return handelApiError(error);
  }
}

// get list api 
const getApi = async (url, data = {}, config = axiosConfig, APIURL = API_URL) => {
  try {
    url = APIURL + url;
    let response = await axios.get(url, { headers: config, params: data });
    return handelApiRes(response);
  } catch (error) {
    return handelApiError(error);
  }
}

// update data api 
const putApi = async (url, data = {}, config = axiosConfig, APIURL = API_URL) => {
  try {
    url = APIURL + url;
    let response = await axios.put(url, data, { headers: config });
    return handelApiRes(response);
  } catch (error) {
    return handelApiError(error);
  }
}

// delete data api
const deleteApi = async (url, data = {}, config = axiosConfig, APIURL = API_URL) => {
  try {
    url = APIURL + url;
    let response = await axios.delete(url, { headers: config, params: data, data: data });
    return handelApiRes(response);
  } catch (error) {
    return handelApiError(error);
  }
}

const HttpClient = {
  postApi,
  getApi,
  putApi,
  deleteApi
};

export { HttpClient };
