import React, { Component } from 'react'
import { showBootstrapModal, hideBootstrapModal } from '../../utils/commonJsFunctions';
import $ from 'jquery';
import PollService from '../../services/pollService';
import { successToastr } from '../../utils/handelError';
import config from '../../config';
import { submitPollAnswerSocketEmit } from '../../socket';
import { connect } from 'react-redux';

/**
 * Send the questionnaire modal
 */
class PostAnswerModal extends Component {

  state = {
    answerId: '',
    btnStatus: false
  }

  componentDidMount() {
    showBootstrapModal('addPollAnswerModal');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.pollData.PollAnswerOptions !== this.props.pollData.PollAnswerOptions && this.props.displayType === 'publishPoll') {
      this.setState({
        answerId: '',
        btnStatus: false
      }, () => {
        $('.custom-control-input').prop("checked", false);
        showBootstrapModal('addPollAnswerModal');
      })
    }
  }

  componentWillUnmount() {
    hideBootstrapModal('addPollAnswerModal')
  }

  submitPoll = () => {
    if (this.state.answerId) {
      this.setState({
        btnStatus: true
      }, async () => {
        let sessionInfo = this.props.userReduxState;
        let postData = {
          pollId: this.props.pollData.id,
          pollAnswerOptionId: this.state.answerId,
          attendeeId: sessionInfo.userId
        }
        let result = await PollService.submitPollAnswer(postData);
        if (result.data.success) {
          successToastr(config.langLabels.pollAnswerPosted);
          hideBootstrapModal('addPollAnswerModal');
          submitPollAnswerSocketEmit({ type: 'attendee', pollData: result.data.data });
        }
      });
    }
  }


  render() {
    const { pollData } = this.props;
    return (
      <div className="modal fade" id="addPollAnswerModal" tabIndex="-1" role="dialog" aria-labelledby="addPollModal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered w-570 add-video-modal add-poll-modal" role="document">
          <div className="modal-content bg-grey">
            <div className="modal-header border-bottom-0 pb-0">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="icon-close"></i>
              </button>
              <h5 className="modal-title font-neomd">{config.langLabels.poll}</h5>
            </div>
            <div className="modal-body text-right" dir="rtl">
              <h3 className="font-neomd question">{pollData.question}</h3>
              <p className="mb-1 title text-right mb-4">{config.langLabels.selectOneOpt}</p>
              <div className="row">
                {
                  pollData.PollAnswerOptions.map((item, index) => {
                    return <div className="col-6" key={index}>
                      <div className="custom-control custom-radio mb-4">
                        <input name="answer" type="radio" onClick={(e) => {
                          this.setState({
                            answerId: item.id
                          });
                        }} id={`customRadioInline${index}`} className="custom-control-input" />
                        <label htmlFor={`customRadioInline${index}`}>
                          <span>{item.option}</span>
                        </label>
                      </div>
                    </div>
                  })
                }
              </div>
              <div className="text-center btn-row mt-4">
                <button className="btn btn-primary ripple-effect w_170"
                  disabled={this.state.btnStatus}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.submitPoll();
                  }}>{(this.state.btnStatus) ? config.langLabels.pleaseWait : config.langLabels.submit}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostAnswerModal)