
import React from 'react';
import { fabric } from 'fabric';
import 'fabric-history';
import { connect } from 'react-redux';
import { updateToolType } from '../../redux/actions/toolTypeAction';
import toolTypeState from '../../redux/reduxStates/toolTypeState';
import { customFabric } from '../../utils/customFabric';
import { hexToRgb, clearCanvasConfirm, showTextToolOptions, hideTextToolOptions, windowResizeJquery, detectScrollInCanvas, updateScrollInCanvas, getWidthHD, convertBlobToBase64 } from '../../utils/commonJsFunctions';
import VideoPlayer from '../players';
import { objectAddedSocketEmit, objectRemovedSocketEmit, objectAddedSocketOn, objectRemovedSocketOn, objectModifiedSocketOn, objectModifiedSocketEmit, clearBoardSocketOn, clearBoardSocketEmit, redoActionSocketOn, undoActionSocketOn, undoActionSocketEmit, redoActionSocketEmit, canvasScrollSocketOn } from '../../socket';
import { nowTimestamp } from '../../utils/customTimeFunctions';
import DocImages from '../docImages';
import SessionService from '../../services/sessionService';

fabric = customFabric(fabric);

// canvas dimensions
let canvasSize = {
  width: 1920,
  height: 1080
};

class Canvas extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      canvas: null,
      isDown: false,
      drawingTool: props.toolTypeReduxState.tool,
      origX: '',
      originY: '',
      TextTop: '',
      TextLeft: '',
      currentObject: '',
      ...props.toolTypeReduxState.options,
      canvasState: [],
      currentStateIndex: -1,
      undoStatus: false,
      redoStatus: false,
      undoFinishedStatus: 1,
      redoFinishedStatus: 1,
      undoButton: false,
      redoButton: false,
      isRedoing: false,
      h: [],
      cWidth: 0,
      cHeight: 0,
      scaleRatio: 1,
      docImagesArray: [],
      activeImage: '',
      activeMove: '',
      activeCheck: '',
      prevTool: '',
      imgW: 0,
      imgH: 0,
      needSave: false
    }
  }

  componentDidMount() {

    // initialize canvas daynamically
    const canvas = new fabric.Canvas(`canvas_${this.props.currentWhiteBoardId}`, {
      selection: false,
      isDrawingMode: true,
      preserveObjectStacking: true,
      allowTouchScrolling: true // Ref: Fixed the issue of scrolling on the touch screen https://github.com/fabricjs/fabric.js/issues/5903#issuecomment-604917561
    });
    if (this.props.canvasData) {
      this.setState({
        canvasState: JSON.parse(this.props.canvasData),
        currentStateIndex: this.props.currentStateIndex || -1
      }, () => {

        // if (this.props.boardType === "document") {
        //   canvas.loadFromJSON(this.state.canvasState);
        // } else {
        //   this.state.canvasState.map(item => {
        //     canvas.loadFromJSON(item);
        //   });
        // }

        this.state.canvasState.map((item, index) => {
          if (Number(index) === Number(this.state.currentStateIndex)) {
            canvas.loadFromJSON(item);
          }
        });
      })
    }

    this.canvasSizeSet(canvas)
    this.setState({
      canvas
    }, () => {
      this.renderShapes();
      this.canvasResize(canvas);
      this.socketOnEvents(canvas);
      canvasScrollSocketOn(this.canvasScrollOnSocket)
    });


  }

  componentDidUpdate(prevProps, prevState) {

    if ((prevProps.toolTypeReduxState !== this.props.toolTypeReduxState) && (this.props.whiteBoardReduxState.activeWhiteBoardId === this.props.currentWhiteBoardId)) {
      const {
        tool,
        options
      } = this.props.toolTypeReduxState
      this.setState({
        drawingTool: tool,
        ...options
      }, () => {
        if (tool === 'eraser') {
          this.state.canvas.discardActiveObject().renderAll();
        }

        if (!['updatetext', 'text'].includes(tool)) {
          this.setState({
            TextTop: '',
            TextLeft: '',
          })
          // this.state.canvas.off('object:modified');
          this.state.canvas.discardActiveObject().renderAll();
        } else {
          if (tool != 'updatetext') {
            this.state.canvas.off('object:modified');
          }
        }

        this.setState({
          prevTool: tool
        });



        setInterval(() => {
          // undo redo issue resolved

          this.saveData();

        }, 15 * 1000);

        if (!['color'].includes(this.state.drawingTool)) {
          this.renderShapes();
        }
      });
    }

    if (prevProps.whiteBoardReduxState.activeWhiteBoardOwner !== this.props.whiteBoardReduxState.activeWhiteBoardOwner && this.props.currentWhiteBoardId === prevProps.whiteBoardReduxState.activeWhiteBoardId) {
      this.enableSelection(this.state.canvas, false);
      this.state.canvas.discardActiveObject().renderAll();
    }
  }

  saveData = () => {
    const { userReduxState: { sessionInfo: { id } }, whiteBoardReduxState: { activeWhiteBoardId } } = this.props
    if (this.state.needSave) {
      if (
        ['document'].includes(this.props.boardType) &&
        this.props.whiteBoardReduxState.activeWhiteBoardId === this.props.currentWhiteBoardId
      ) {
        let doc_images = this.props.frameData.split(',') || []

        if (doc_images && doc_images.length > 0) {
          this.prevImag(this.state.activeImage, doc_images)
        }
      }

      if (
        this.props.whiteBoardReduxState.activeWhiteBoardOwner.userType === this.props.userReduxState.userType &&
        this.props.whiteBoardReduxState.activeWhiteBoardOwner.userId === this.props.userReduxState.userId &&
        activeWhiteBoardId === this.props.currentWhiteBoardId
      ) {
        this.setState({
          needSave: false
        }, async () => {
          this.state.canvasState.backgroundImage = null;
          let latestCanvas = JSON.stringify(this.state.canvasState);
          await SessionService.updateBoardSheetById({ boardId: activeWhiteBoardId, canvas: latestCanvas, currentIndex: this.state.currentStateIndex });
        })
      } else {
        this.setState({
          needSave: false
        })
      }
    }
  }

  canvasResize = (canvas) => {
    windowResizeJquery(this.canvasSizeSet, canvas)
    window.onresize = (event) => {
      this.canvasSizeSet(canvas, true)
    };
  }

  canvasSizeSet = (canvas, isResize = false) => {
    let footerHeight = 0; //document.getElementById("whiteBoardFooter") ? document.getElementById("whiteBoardFooter").offsetHeight : 0
    let whiteBoardWidth = document.getElementById("whiteBoard").offsetWidth
    let whiteBoardHeight = document.getElementById("whiteBoard").offsetHeight + footerHeight

    // whiteBoardWidth = whiteBoardWidth - 40
    // whiteBoardHeight = whiteBoardHeight - 30

    // let wScale = (canvasSize.width * whiteBoardHeight) / canvasSize.height
    // let hScale = (canvasSize.height * whiteBoardWidth) / canvasSize.width
    // if (wScale > whiteBoardWidth) {
    //   wScale = whiteBoardWidth
    // }
    // if (hScale > whiteBoardHeight) {
    //   hScale = whiteBoardHeight
    // }  

    // wScale = wScale - 30
    // hScale = hScale - 40

    let whiteBoardWidthHeight = getWidthHD(whiteBoardWidth)

    let wScale = whiteBoardWidthHeight.width
    let hScale = whiteBoardWidthHeight.height

    //let scaleRatio = Math.min(wScale / canvasSize.width, hScale / canvasSize.height)

    let scaleRatio = Math.min(wScale / canvasSize.width, hScale / canvasSize.height)

    this.setState({
      cWidth: wScale,
      cHeight: hScale,
      scaleRatio
    });

    if (this.props.boardType !== "document") {
      canvas.setWidth(wScale);
      canvas.setHeight(hScale);
      canvas.setZoom(scaleRatio);
    } else {
      canvas.setZoom(1);
    }

    // if (this.props.boardType === "document") {
    //   if (isResize) {
    //     // canvas.setZoom(scaleRatio)
    //   }
    this.detectScroll();
    // } else {
    //   canvas.setZoom(scaleRatio)
    // }
    canvas.renderAll();
  }

  setZoom = (zoom) => {
    const { canvas, scaleRatio, imgW, imgH } = this.state
    const { currentWhiteBoardId } = this.props
    let SCALE_FACTOR = zoom

    // SCALE_FACTOR = scaleRatio * zoom

    // let newWidth = (SCALE_FACTOR * imgW) + (SCALE_FACTOR * imgH)
    let newWidth = (SCALE_FACTOR * imgW) + (SCALE_FACTOR * imgH)
    let newHeight = SCALE_FACTOR * imgH

    // https://stackoverflow.com/a/30750227
    // if (SCALE_FACTOR > 1) {
    //   canvas.setHeight(cHeight * SCALE_FACTOR);
    //   canvas.setWidth(cWidth * SCALE_FACTOR);

    // } else if (SCALE_FACTOR < 1) {
    //   canvas.setHeight(cHeight * (scaleRatio / SCALE_FACTOR));
    //   canvas.setWidth(cWidth * (scaleRatio / SCALE_FACTOR));

    // } else {
    //   // this.canvasSizeSet(canvas)
    //   zoom = scaleRatio
    // }


    // let scaleRatioNew = Math.min(cWidth * SCALE_FACTOR / canvasSize.width, cHeight * SCALE_FACTOR / canvasSize.height)

    // let footerHeight = document.getElementById("whiteBoardFooter") ? document.getElementById("whiteBoardFooter").offsetHeight : 0
    let whiteBoardWidth = document.getElementById("whiteBoard").offsetWidth
    let whiteBoardHeight = document.getElementById("whiteBoard").offsetHeight

    // canvas.setWidth(newWidth);
    // canvas.setHeight(newHeight);

    // $(`#canvas_scroll_${currentWhiteBoardId}`).find('.canvas-container').css('width', newWidth);
    // $(`#canvas_scroll_${currentWhiteBoardId}`).find('.canvas-container').css('height', newHeight);

    // canvas.setHeight(cHeight * SCALE_FACTOR);
    // canvas.setWidth(cWidth * SCALE_FACTOR);

    canvas.setZoom(SCALE_FACTOR);

    canvas.setDimensions({
      width: newWidth,
      height: newHeight
    })

    let prevHeight = canvas.getHeight();
    let prevWidth = canvas.getWidth();
    let t = prevHeight / 2 - whiteBoardHeight / 2;
    let l = prevWidth / 2 - whiteBoardWidth / 2;
    let data = {
      scrollPosition: {
        X: l,
        Y: t
      }
    }
    // updateScrollInCanvas(`canvas_scroll_${currentWhiteBoardId}`, data);

    canvas.renderAll();

    if (
      (this.state.drawingTool === 'pane' || this.state.drawingTool === 'text') &&
      !['', null, undefined, false, 'false'].includes(this.state.TextTop) &&
      !['', null, undefined, false, 'false'].includes(this.state.TextLeft)
    ) {
      hideTextToolOptions()
      showTextToolOptions(this.state.TextTop, this.state.TextLeft, canvas, '', `canvas_scroll_${this.props.currentWhiteBoardId}`);
    }
  }

  reSizeCanvasObject = (data) => {

    var userWidth = data.getWidth;
    var myWidth = this.state.canvas.getWidth();
    // top calculation
    if (data.target.top) {
      var top = data.target.top;
      var newTop = (top * myWidth) / userWidth;
      data.target.top = newTop;
    }

    // left calculation
    if (data.target.left) {
      var left = data.target.left;
      var newLeft = (left * myWidth) / userWidth;
      data.target.left = newLeft;
    }
    // width calculation
    if (data.target.width) {
      var width = data.target.width;
      var newWidth = (width * myWidth) / userWidth;
      data.target.width = newWidth;
    }
    // height calculation
    if (data.target.height) {
      var height = data.target.height;
      var newHeight = (height * myWidth) / userWidth;
      data.target.height = newHeight;
    }
    if (data.target.strokeWidth) {
      var strokeWidth = data.target.strokeWidth;
      var newstrokeWidth = (strokeWidth * myWidth) / userWidth;
      data.target.strokeWidth = newstrokeWidth;
    }

    if (data.target.type == 'path') {
      // scaleX calculation
      var newscaleX = (data.target.scaleX * myWidth) / userWidth;
      data.target.scaleX = newscaleX;

      // scaleY calculation
      var newscaleY = (data.target.scaleY * myWidth) / userWidth;
      data.target.scaleY = newscaleY;
    }

    if (data.target.type == 'circle') {
      var radius = data.target.radius;
      var newRadius = (radius * myWidth) / userWidth;
      data.target.radius = newRadius;
    } else if (data.target.type == 'line') {
      data.target.x1 = (data.target.x1 * myWidth) / userWidth;
      data.target.y1 = (data.target.y1 * myWidth) / userWidth;
      data.target.x2 = (data.target.x2 * myWidth) / userWidth;
      data.target.y2 = (data.target.y2 * myWidth) / userWidth;
    }

    else if (data.target.type == 'i-text') {
      var newfontSize = (data.target.fontSize * myWidth) / userWidth;
      data.target.fontSize = newfontSize;
    }

    return data;
  }

  renderShapes = () => {
    let currentObject = '';
    let origX = '';
    let origY = '';
    let canvas = this.state.canvas;
    canvas.isDrawingMode = false;

    // clear board
    if (this.state.drawingTool === 'clear') {
      this.clearCanvas(canvas);
      return true;
    }

    if (!['text', 'updatetext'].includes(this.state.drawingTool)) {
      this.setState({
        TextTop: '',
        TextLeft: ''
      }, () => {
        hideTextToolOptions()
      })
    }

    // enable objevt selection
    if (this.state.drawingTool === 'pane' || this.state.drawingTool === 'eraser') {

      this.enableSelection(canvas, true);
      return true;
    } else {
      this.enableSelection(canvas, false);
    }

    // discardActiveObject when select eraser tool
    if (this.state.drawingTool === 'eraser') {
      canvas.discardActiveObject().renderAll();
    }

    if (this.state.drawingTool === 'updatetext') {
      this.updateTextStyle(canvas);
    }

    // undo canvas objects
    if (this.state.drawingTool === 'undo') {
      this.undo();
      undoActionSocketEmit({ boardId: this.props.currentWhiteBoardId });
      return true;
    }

    // redo canvas objects
    if (this.state.drawingTool === 'redo') {
      this.redo()
      redoActionSocketEmit({ boardId: this.props.currentWhiteBoardId });
      return true;
    }

    // draw table when selecting row columns
    if (this.state.drawingTool === 'table') {
      this.drawTable(canvas)
      return true;
    }

    canvas.isDrawingMode = false;
    if (this.state.drawingTool === 'pencil' || this.state.drawingTool === 'highliter') {
      canvas.isDrawingMode = true;
      canvas.freeDrawingBrush.width = this.state.strokeWidth || 1;
      canvas.freeDrawingBrush.color = this.state.drawingTool === 'highliter' ? `rgb(${hexToRgb(this.state.color)},0.5)` : this.state.color;
    }

    canvas.selection = false;
    // below event start working when mouse click on canvas
    canvas.on('mouse:down', (o) => {
      this.setState({
        activeMove: true
      });
      // hideTextToolOptions()
      if (o.target && o.target.type === 'i-text' && this.state.drawingTool === 'pane') {
        this.setState({
          TextTop: o.target.top,
          TextLeft: o.target.left
        })
        showTextToolOptions(o.target.top, o.target.left, canvas, o, `canvas_scroll_${this.props.currentWhiteBoardId}`);
      } else if (this.state.drawingTool === 'pane') {
        this.setState({
          TextTop: '',
          TextLeft: ''
        }, () => {
          hideTextToolOptions()
        })
      }

      // condition  if eraser tool selected
      if (this.state.drawingTool === "eraser") {
        if (canvas.getActiveObject()) {
          canvas.isDrawingMode = false;
          let object = canvas.getActiveObject();
          let robj = canvas.getActiveObject().toJSON(['id']);
          canvas.remove(object);
          this.socketEmitEvents(robj, this.state.drawingTool, 'removed');
        }
      } else {
        this.setState({
          isDown: true
        }, () => {
          let { drawingTool } = this.state;
          // get mouse current position
          let pointer = canvas.getPointer(o.e);
          origX = pointer.x;
          origY = pointer.y;
          // call shapes render function accoording to drawingTool
          currentObject = this.renderShapesByType(canvas, drawingTool, o.e, origX, origY);
          if (currentObject) {
            this.setState({
              currentObject: currentObject
            })
          }
        });
      }
    });

    // below event start working when we move mouse click on board
    canvas.on('mouse:move', (objEvent) => {

      if (!this.state.isDown)
        return;
      if (this.state.currentObject) {
        // set all object strock width
        if (this.state.currentObject.strokeWidth !== this.state.strokeWidth) {
          this.state.currentObject.set({ strokeWidth: this.state.strokeWidth });
        }
        let { drawingTool } = this.state;
        this.renderShapesByType(canvas, drawingTool, objEvent.e, origX, origY);
      }
    });

    // below event start working when mouse click leave
    canvas.on('mouse:up', (o) => {

      this.setState({
        activeMove: false
      });
      if (this.state.currentObject) {
        let object = this.state.currentObject;
        if (!['text', 'updatetext'].includes(this.state.drawingTool)) {
          canvas.remove(object);
          canvas.add(object);
          object.set({ selectable: false });
          canvas.selection = false;
          canvas.renderAll();
        }
        this.socketEmitEvents(object, this.state.drawingTool, 'added');
      }

      this.setState({
        isDown: false,
        currentObject: ''
      }, () => {
        // off all mouse event after object drawn
        if (this.state.drawingTool !== "eraser" && this.state.drawingTool !== "pane" && this.state.drawingTool !== "table") {
          this.setState({
            drawingTool: this.state.drawingTool
          }, () => {
            canvas.selection = false;
            canvas.off('mouse:down')
            canvas.off('mouse:move')
            canvas.off('mouse:up')
            canvas.off('object:moving');
            canvas.off('object:modified');
            //canvas.off('object:added');
            //canvas.off('object:removed');
            canvas.off('path:created');
            this.renderShapes();
          })
        }
      });
    });

    if (this.state.drawingTool === "pencil" || this.state.drawingTool === "highliter") {
      canvas.on('path:created', (event) => {
        if (this.state.activeCheck != event.path) {
          if (event.path) {
            event.path.id = nowTimestamp();
            this.socketEmitEvents(event.path, this.state.drawingTool, 'added');
          }
          this.setState({
            activeCheck: event.path
          })
        }
      });
    }

    canvas.on('object:modified', (e) => {
      this.socketEmitEvents(e.target, 'modified', 'modified');
    });

    // below code for maintaing object strokeWidth on object scaling
    canvas.on('object:scaling', (e) => {
      var o = e.target;
      if (!o.strokeWidthUnscaled && o.strokeWidth) {
        o.strokeWidthUnscaled = o.strokeWidth;
      }
      if (o.strokeWidthUnscaled) {
        o.strokeWidth = o.strokeWidthUnscaled / o.scaleX;
      }
    });
  }

  calcArrowAngle(x1, y1, x2, y2) {
    var angle = 0,
      x, y;

    x = (x2 - x1);
    y = (y2 - y1);

    if (x === 0) {
      angle = (y === 0) ? 0 : (y > 0) ? Math.PI / 2 : Math.PI * 3 / 2;
    } else if (y === 0) {
      angle = (x > 0) ? 0 : Math.PI;
    } else {
      angle = (x < 0) ? Math.atan(y / x) + Math.PI : (y < 0) ? Math.atan(y / x) + (2 * Math.PI) : Math.atan(y / x);
    }

    return (angle * 180 / Math.PI);
  }

  socketEmitEvents = (object, tool, action) => {
    let userInfo = this.props.userReduxState;
    let boardId = this.props.currentWhiteBoardId;
    let sessionId = userInfo.sessionInfo.id;
    let fromUserId = userInfo.userId;
    let userType = userInfo.userType;
    switch (action) {
      case 'added':
        objectAddedSocketEmit({
          'tool': tool,
          'options': (tool !== 'table') ? object.toJSON(['id']) : object,
          'fromUserId': fromUserId,
          'sessionId': sessionId,
          'boardId': boardId,
          'userType': userType,
          'getWidth': this.state.canvas.getWidth(),
          'getHeight': this.state.canvas.getHeight(),
        });
        this.updateCanvasState();
        break
      case 'modified':
        objectModifiedSocketEmit({
          'target': object.toJSON(['id']),
          'fromUserId': fromUserId,
          'sessionId': sessionId,
          'boardId': boardId,
          'userType': userType,
          'getWidth': this.state.canvas.getWidth(),
          'getHeight': this.state.canvas.getHeight(),
        });
        this.updateCanvasState();
        break
      case 'removed':
        objectRemovedSocketEmit({
          'target': object,
          'fromUserId': fromUserId,
          'sessionId': sessionId,
          'boardId': boardId,
          'userType': userType
        });
        this.updateCanvasState();
        break
      case 'clear':
        clearBoardSocketEmit({
          'fromUserId': fromUserId,
          'sessionId': sessionId,
          'boardId': boardId,
          'userType': userType
        });
        this.updateCanvasState();
        break
      default:
        return '';
    }
  }

  objectAddedOnSocket = async (canvas, data) => {
    if (this.props.currentWhiteBoardId === data.boardId) {
      let drawingTool = data.tool;
      let newObject = '';
      let object = this.getObjectById(canvas, data.options.id);
      data.target = data.options;
      data.options.selectable = false;
      if (object) {
        this.objectModifiedSocketOn(canvas, data);
      } else {
        switch (drawingTool) {
          case 'line':
            newObject = new fabric.Line([data.options.x1, data.options.y1, data.options.x2, data.options.y2], data.options);
            break;
          case 'circle':
            newObject = new fabric.Circle(data.options);
            break;
          case 'triangle':
            newObject = new fabric.Triangle(data.options);
            break;
          case 'rectangle':
            newObject = new fabric.Rect(data.options);
            break;
          case 'polygon':
            newObject = new fabric.Path(data.options.path, data.options)
            break;
          case 'pencil':
            newObject = new fabric.Path(data.options.path.join(' '), data.options);
            break;
          case 'highliter':
            newObject = new fabric.Path(data.options.path.join(' '), data.options);
            break;
          case 'text':
            newObject = new fabric.IText(data.options.text, data.options);
            break;
          case 'heart':
            newObject = new fabric.Path(data.options.path, data.options)
            break;
          case 'table':
            newObject = await this.addTableInCanvas(data)
            break;
          case 'single-head-arrow':
            newObject = new fabric.Arrow([data.options.x1, data.options.y1, data.options.x2, data.options.y2], data.options)
            break;
          case 'double-head-arrow':
            newObject = new fabric.LineArrow([data.options.x1, data.options.y1, data.options.x2, data.options.y2], data.options)
            break;
          case 'curved-arrow':
            newObject = new fabric.LineWithArrow([data.options.x1, data.options.y1, data.options.x2, data.options.y2], data.options)
            break;
          default:
            return '';
        }
        if (newObject) {
          canvas.add(newObject);
          setTimeout(() => {
            canvas.renderAll();
          }, 1000);
          this.updateCanvasState(canvas, newObject, 'added');
        }
      }
    }
  }

  addTableInCanvas = (data) => {
    return new Promise((resolve, reject) => {
      const { rows, columns, id, color } = data.options
      this.setState({
        rows,
        columns,
        id,
        color
      }, async () => {
        let svgData = this.tableSVG();
        // creating image from svg
        let DOMURL = window.URL || window.webkitURL || window;
        let img = new Image();
        let svg = new Blob([svgData], { type: 'image/svg+xml' });
        // let url = DOMURL.createObjectURL(svg);
        let url = await convertBlobToBase64(svg);
        img.src = url;
        let whiteBoardWidth = document.getElementById("whiteBoard").offsetWidth
        let whiteBoardHeight = document.getElementById("whiteBoard").offsetHeight
        var center = this.state.canvas.getCenter();

        let newObject = new fabric.Image(img, {
          left: center.left / 2,
          top: center.top / 2,
          width: 25 * columns,
          height: 25 * rows,
          originX: 'left',
          originY: 'top',
          selectable: false,
          id: id
        });
        resolve(newObject);
      })
    });
  }

  getObjectById(canvas, id) {
    var objects = canvas._objects;
    var length = canvas._objects.length;
    for (var i = 0; i < length; i++) {
      if ((objects[i].id) === (id))
        return objects[i];
    }
  }

  objectRemovedOnSocket = (canvas, data) => {
    if (this.props.currentWhiteBoardId === data.boardId) {
      let obj = this.getObjectById(canvas, data.target.id)
      canvas.remove(obj);
      canvas.renderAll();
      this.updateCanvasState();
    }
  }

  objectModifiedSocketOn = (canvas, data) => {
    canvas.discardActiveObject();
    canvas.isDrawingMode = false;
    if (this.props.currentWhiteBoardId === data.boardId) {
      let target = data.target;
      let object = this.getObjectById(canvas, target.id);
      if (object) {
        object.animate({
          left: target.left,
          top: target.top,
          scaleX: target.scaleX,
          scaleY: target.scaleY,
          angle: target.angle,
          flipX: target.flipX,
          flipY: target.flipY,
        }, {
          duration: 1000,
          onChange: () => {
            if (target.type === 'i-text') {
              object.set('text', target.text);
              object.set("fontWeight", target.fontWeight);
              object.set("fontStyle", target.fontStyle);
              object.set('underline', target.underline);
              object.set("fontSize", target.fontSize);
              object.set('flipX', target.flipX);
              object.set("flipY", target.flipY);
              object.set("textAlign", target.textAlign);
            }
          },
          onComplete: () => {
            canvas.renderAll();
            this.updateCanvasState();
          }
        });
      }
    }
  }

  clearBoard = (canvas, data) => {
    if (this.props.currentWhiteBoardId === data.boardId) {
      this.setState({
        canvasState: [],
        currentStateIndex: -1,
        undoStatus: false,
        redoStatus: false,
        undoFinishedStatus: 1,
        redoFinishedStatus: 1,
        undoButton: false,
        redoButton: false
      }, () => {
        this.state.canvas.clear();
        this.setState({
          needSave: true
        }, () => {
          this.saveData();
        });
        localStorage.removeItem(this.state.docImagesArray[this.state.activeImage]);
        this.setCanvasBackgroundImage(this.state.docImagesArray, this.state.activeImage);
      });
    }
  }

  socketOnEvents = (canvas) => {
    objectAddedSocketOn(this.objectAddedOnSocket, canvas)
    objectRemovedSocketOn(this.objectRemovedOnSocket, canvas)
    objectModifiedSocketOn(this.objectModifiedSocketOn, canvas)
    clearBoardSocketOn(this.clearBoard, canvas);
    redoActionSocketOn(this.redoAction, canvas);
    undoActionSocketOn(this.undoAction, canvas);
  }

  redoAction = (data) => {
    if (this.props.currentWhiteBoardId === data.boardId) {
      this.redo();
    }
  }

  undoAction = (data) => {
    if (this.props.currentWhiteBoardId === data.boardId) {
      this.undo();
    }
  }

  updateCanvasState = async (canvas = this.state.canvas) => {

    const { userReduxState: { sessionInfo: { id } }, whiteBoardReduxState: { activeWhiteBoardId } } = this.props
    var jsonData = canvas.toJSON(['id']);
    var canvasAsJson = JSON.stringify(jsonData);
    let canvasState = this.state.canvasState;
    if (this.state.currentStateIndex < this.state.canvasState.length - 1) {
      var indexToBeInserted = this.state.currentStateIndex + 1;
      canvasState[indexToBeInserted] = canvasAsJson;
      var numberOfElementsToRetain = indexToBeInserted + 1;
      canvasState.splice(0, numberOfElementsToRetain);
      this.setState({
        canvasState
      })
    } else {
      canvasState.push(canvasAsJson);
      this.setState({
        canvasState: canvasState
      })
    }
    this.setState({
      currentStateIndex: this.state.canvasState.length - 1,
      needSave: true
    })
    if ((this.state.currentStateIndex == this.state.canvasState.length - 1) && this.state.currentStateIndex != -1) {
      this.setState({
        redoButton: false
      })
    }
  }

  undo = (canvas = this.state.canvas) => {
    if (this.state.undoFinishedStatus) {
      if (this.state.currentStateIndex === -1) {

      } else {
        if (this.state.canvasState.length >= 1) {
          this.state.undoFinishedStatus = 0;
          if (this.state.currentStateIndex !== 0) {
            canvas.loadFromJSON(this.state.canvasState[this.state.currentStateIndex - 1], () => {
              canvas.renderAll();
              this.enableSelection(canvas, false);
              this.setState({
                currentStateIndex: this.state.currentStateIndex - 1,
                undoFinishedStatus: 1,
              })
            });
          }
          else if (this.state.currentStateIndex === 0) {
            this.setCanvasBackgroundImage(this.state.docImagesArray, this.state.activeImage);
            canvas.clear();
            this.state.undoFinishedStatus = 1;
            this.setState({
              undoFinishedStatus: 1,
              currentStateIndex: this.state.currentStateIndex - 1,
            })
          }
        }
      }
    }
  }

  redo = (canvas = this.state.canvas) => {
    let stateCurrentIndex = this.state.currentStateIndex;

    // if ((this.state.currentStateIndex === (this.state.canvasState.length - 1)) && this.state.currentStateIndex !== -1) {
    if ((this.state.currentStateIndex === (this.state.canvasState.length - 1)) || (this.state.canvasState.length < this.state.currentStateIndex)) {

    } else {
      // if (this.state.canvasState.length > this.state.currentStateIndex && this.state.canvasState.length !== 0) {
      if (this.state.canvasState.length > this.state.currentStateIndex) {
        stateCurrentIndex = stateCurrentIndex + 1
        canvas.loadFromJSON(this.state.canvasState[stateCurrentIndex], () => {
          canvas.renderAll();
          this.enableSelection(canvas, false);
          this.state.currentStateIndex += 1;
          if (this.state.currentStateIndex !== -1) {
            this.setState({
              undoButton: true,
              currentStateIndex: stateCurrentIndex,
            })
          }

          if ((this.state.currentStateIndex === this.state.canvasState.length - 1) && this.state.currentStateIndex !== -1) {
            this.setState({
              redoButton: false
            })
          }
        });
      }
    }

  }

  renderShapesByType = (canvas, drawingTool, event, origX, origY) => {
    switch (drawingTool) {
      case 'line':
        return this.drawLine(canvas, event);
      case 'circle':
        return this.drawCircle(canvas, event, origX, origY);
      case 'triangle':
        return this.drawTriangle(canvas, event, origX, origY);
      case 'rectangle':
        return this.drawRectangle(canvas, event, origX, origY);
      case 'polygon':
        return this.drawPolygon(canvas, event, origX, origY);
      case 'pencil':
        return this.freeDrawing(canvas, event);
      case 'highliter':
        return this.freeDrawing(canvas, event);
      case 'text':
        return this.writeText(canvas, event, origX, origY);
      case 'heart':
        return this.drawHeart(canvas, event, origX, origY);
      // case 'table':
      //   return this.drawTable(canvas, event, origX, origY);
      case 'single-head-arrow':
        return this.drawSingleHeadArrow(canvas, event, origX, origY);
      case 'double-head-arrow':
        return this.drawDoubleHeadArrow(canvas, event, origX, origY);
      case 'curved-arrow':
        return this.drawCurvedArrow(canvas, event, origX, origY);
      // case 'pane':
      //   return this.enableSelection(canvas);
      default:
        return '';
    }
  }

  // function for draw single head arrow
  drawSingleHeadArrow = (canvas, event, origX, origY) => {
    let currentObject = ''
    let pointer = canvas.getPointer(event);
    if (this.state.currentObject) {
      this.state.currentObject.set({ x2: pointer.x, y2: pointer.y });
    } else {
      let points = [pointer.x, pointer.y, pointer.x, pointer.y];
      currentObject = new fabric.Arrow(points, {
        strokeWidth: 1,
        fill: this.state.color,
        stroke: this.state.color,
        originX: 'center',
        originY: 'center',
        id: nowTimestamp(),
        perPixelTargetFind: true
      });
      canvas.add(currentObject);
    }
    canvas.renderAll();
    return currentObject;
  }
  // function for draw curved arrow
  drawCurvedArrow = (canvas, event, origX, origY) => {
    let currentObject = ''
    let pointer = canvas.getPointer(event);
    if (this.state.currentObject) {
      this.state.currentObject.set({ x2: pointer.x, y2: pointer.y });
    } else {
      let points = [pointer.x, pointer.y, pointer.x, pointer.y];
      currentObject = new fabric.LineWithArrow(points, {
        strokeWidth: 1,
        fill: this.state.color,
        stroke: this.state.color,
        originX: 'center',
        originY: 'center',
        id: nowTimestamp()
      });
      canvas.add(currentObject);
    }
    canvas.renderAll();
    return currentObject;
  }

  // function for draw double head arrow
  drawDoubleHeadArrow = (canvas, event, origX, origY) => {
    let currentObject = ''
    let pointer = canvas.getPointer(event);
    if (this.state.currentObject) {
      this.state.currentObject.set({ x2: pointer.x, y2: pointer.y });
    } else {
      let points = [pointer.x, pointer.y, pointer.x, pointer.y];
      currentObject = new fabric.LineArrow(points, {
        strokeWidth: 1,
        fill: this.state.color,
        stroke: this.state.color,
        originX: 'center',
        originY: 'center',
        id: nowTimestamp()
      });
      canvas.add(currentObject);
    }
    canvas.renderAll();
    return currentObject;
  }

  // function for active board object selection
  enableSelection = (canvas, selectionStatus) => {
    canvas._objects.map((item, index) => {
      item.set({ selectable: selectionStatus })
    });
    //canvas.isDrawingMode = selectionStatus;
    canvas.selection = selectionStatus;
  }

  // function for clear board
  clearCanvas = (canvas) => {
    clearCanvasConfirm(this.confirmedClearCanvas, canvas)
  }

  confirmedClearCanvas = (result, canvas) => {
    if (result) {
      this.setState({
        canvasState: [],
        currentStateIndex: -1,
        undoStatus: false,
        redoStatus: false,
        undoFinishedStatus: 1,
        redoFinishedStatus: 1,
        undoButton: false,
        redoButton: false
      }, () => {
        canvas.clear();
        if (['document'].includes(this.props.boardType)) {
          this.docZoom.resetZoomLevel()
        }
        this.setState({
          needSave: true
        }, () => {
          this.saveData();
        });
        localStorage.removeItem(this.state.docImagesArray[this.state.activeImage]);
        this.socketEmitEvents({}, 'clear', 'clear');
        this.updateCanvasState(canvas, 'clear')
        this.setCanvasBackgroundImage(this.state.docImagesArray, this.state.activeImage);
      })
    }
    this.props.updateToolTypeState(toolTypeState)
  }

  trtd = () => {
    const { rows, columns, color } = this.state
    let trtdArr = []
    for (let indexRow = 0; indexRow < rows; indexRow++) {
      trtdArr.push(`<tr style="border: 1px solid ${color}">`)
      for (let indexColumn = 0; indexColumn < columns; indexColumn++) {
        trtdArr.push(`<td style="border: 1px solid ${color}"></td>`)
      }
      trtdArr.push('</tr>')
    }
    return trtdArr.join(' ')
  }

  tableSVG = () => {
    const { rows, columns, color } = this.state
    let tableSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="${25 * columns}" height="${25 * rows}">
      <foreignObject width="${25 * columns}" height="${25 * rows}">
      <div xmlns="http://www.w3.org/1999/xhtml">
      <table border="1px" style="border: 1px solid ${color};border-collapse: collapse;" width="${25 * columns}" height="${25 * rows}">${this.trtd()}</table>
      </div>
      </foreignObject>
      </svg>`;
    return tableSVG
  }

  // function for draw table
  drawTable = async (canvas) => {
    const { rows, columns } = this.state
    let svgData = this.tableSVG();
    // creating image from svg
    let DOMURL = window.URL || window.webkitURL || window;
    let img = new Image();
    let svg = new Blob([svgData], { type: 'image/svg+xml' });
    // let url = DOMURL.createObjectURL(svg);
    let url = await convertBlobToBase64(svg);
    img.src = url;
    let id = nowTimestamp();
    // let whiteBoardWidth = document.getElementById("whiteBoard").offsetWidth
    // let whiteBoardHeight = document.getElementById("whiteBoard").offsetHeight
    var center = this.state.canvas.getCenter();
    let currentObject = new fabric.Image(img, {
      left: center.left / 2,
      top: center.top / 2,
      width: 25 * columns,
      height: 25 * rows,
      originX: 'left',
      originY: 'top',
      selectable: false,
      id: id
    });
    canvas.add(currentObject);
    setTimeout(() => {
      this.socketEmitEvents({ rows: rows, columns: columns, id: id, color: this.state.color }, 'table', 'added');
      canvas.renderAll();
    }, 100);

  }

  // function for draw heart
  drawHeart = (canvas, event, origX, origY) => {
    let currentObject = ''
    let pointer = canvas.getPointer(event);
    if (this.state.currentObject) {
      if (origX > pointer.x) {
        this.state.currentObject.set({
          left: Math.abs(pointer.x)
        });
      }
      if (origY > pointer.y) {
        this.state.currentObject.set({
          top: Math.abs(pointer.y)
        });
      }
      let width = Math.abs(origX - pointer.x) / 4;
      let scale = width / 100;
      this.state.currentObject.set({ scaleX: scale, scaleY: scale });
    } else {
      currentObject = new fabric.Path('M 272.70141,238.71731 \
      C 206.46141,238.71731 152.70146,292.4773 152.70146,358.71731  \
      C 152.70146,493.47282 288.63461,528.80461 381.26391,662.02535 \
      C 468.83815,529.62199 609.82641,489.17075 609.82641,358.71731 \
      C 609.82641,292.47731 556.06651,238.7173 489.82641,238.71731  \
      C 441.77851,238.71731 400.42481,267.08774 381.26391,307.90481 \
      C 362.10311,267.08773 320.74941,238.7173 272.70141,238.71731  \
      z ', {
        left: origX,
        top: origY,
        width: 0,
        height: 0,
        fill: this.state.fill,
        stroke: this.state.color,
        strokeWidth: 1,
        id: nowTimestamp()
      });
      currentObject.set({ scaleX: 0, scaleY: 0 });
      canvas.add(currentObject);
    }
    canvas.renderAll();
    return currentObject;
  }



  // function for draw circle
  drawCircle = (canvas, event, origX, origY) => {
    let currentObject = ''
    let pointer = canvas.getPointer(event);
    if (this.state.currentObject) {
      // code for update selected circle
      var radius = Math.max(Math.abs(origY - pointer.y), Math.abs(origX - pointer.x)) / 2;
      if (radius > this.state.currentObject.strokeWidth) {
        radius -= this.state.currentObject.strokeWidth / 2;
      }
      this.state.currentObject.set({ radius: radius });
      if (origX > pointer.x) {
        this.state.currentObject.set({ originX: 'right' });
      } else {
        this.state.currentObject.set({ originX: 'left' });
      }
      if (origY > pointer.y) {
        this.state.currentObject.set({ originY: 'bottom' });
      } else {
        this.state.currentObject.set({ originY: 'top' });
      }
      // code for update selected circle end
    } else {
      origX = pointer.x;
      origY = pointer.y;
      currentObject = new fabric.Circle({
        left: origX,
        top: origY,
        originX: 'left',
        originY: 'top',
        radius: pointer.x - origX,
        angle: 0,
        fill: this.state.fill,
        stroke: this.state.color,
        strokeWidth: 1,
        id: nowTimestamp(),
      });
      canvas.add(currentObject);
    }
    canvas.renderAll();
    return currentObject;
  }

  // function for draw triangle
  drawTriangle = (canvas, event, origX, origY) => {
    let currentObject = ''
    let pointer = canvas.getPointer(event);
    if (this.state.currentObject) {
      // code for update selected traingle
      if (origX > pointer.x) {
        this.state.currentObject.set({
          left: Math.abs(pointer.x)
        });
      }
      if (origY > pointer.y) {
        this.state.currentObject.set({
          top: Math.abs(pointer.y)
        });
      }
      this.state.currentObject.set({
        width: Math.abs(origX - pointer.x)
      });
      this.state.currentObject.set({
        height: Math.abs(origY - pointer.y)
      });
      // code for update selected traingle
    } else {
      currentObject = new fabric.Triangle({
        left: origX,
        top: origY,
        width: pointer.x - origX,
        height: pointer.y - origY,
        fill: this.state.fill,
        stroke: this.state.color,
        strokeWidth: 1,
        id: nowTimestamp()
      });
      canvas.add(currentObject);
    }
    canvas.renderAll();
    return currentObject;
  }
  // function for draw line
  drawLine = (canvas, event) => {
    let currentObject = ''
    let pointer = canvas.getPointer(event);
    if (this.state.currentObject) {
      this.state.currentObject.set({ x2: pointer.x, y2: pointer.y });
    } else {
      let points = [pointer.x, pointer.y, pointer.x, pointer.y];
      currentObject = new fabric.Line(points, {
        strokeWidth: 1,
        stroke: this.state.color,
        originX: 'center',
        originY: 'center',
        id: nowTimestamp()
      });
      canvas.add(currentObject);
    }
    canvas.renderAll();
    return currentObject;
  }
  // function for draw hexagone and pentagone
  drawPolygon = (canvas, event, origX, origY) => {
    let currentObject = ''
    let pointer = canvas.getPointer(event);
    if (this.state.currentObject) {
      if (origX > pointer.x) {
        this.state.currentObject.set({
          left: Math.abs(pointer.x)
        });
      }
      if (origY > pointer.y) {
        this.state.currentObject.set({
          top: Math.abs(pointer.y)
        });
      }
      let width = Math.abs(origX - pointer.x) / 2;
      let scale = width / 100;
      this.state.currentObject.set({ scaleX: scale, scaleY: scale, strokeWidth: 1 });
    } else {
      let pathCord = '';
      if (this.state.edge === 6) {
        pathCord = 'M850 75 958 137.5 958 262.5 850 325 742 262.6 742 137.5Z';
      } else {
        pathCord = 'M150 20 200 60 180 110 120 110 100 60Z';
      }
      currentObject = new fabric.Path(pathCord, {
        left: origX,
        top: origY,
        fill: this.state.fill,
        stroke: this.state.color,
        strokeWidth: 1,
        id: nowTimestamp()
      });
      currentObject.set({ scaleX: 0, scaleY: 0 });
      canvas.add(currentObject);
    }
    canvas.renderAll();
    return currentObject;
  }
  // function for draw rectangle
  drawRectangle = (canvas, event, origX, origY) => {
    let currentObject = ''
    let pointer = canvas.getPointer(event);
    if (this.state.currentObject) {
      // code for update selected Rectangle
      if (origX > pointer.x) {
        this.state.currentObject.set({
          left: Math.abs(pointer.x)
        });
      }
      if (origY > pointer.y) {
        this.state.currentObject.set({
          top: Math.abs(pointer.y)
        });
      }
      this.state.currentObject.set({
        width: Math.abs(origX - pointer.x)
      });
      this.state.currentObject.set({
        height: Math.abs(origY - pointer.y)
      });

      if (this.state.cornerradius === 'yes') {
        let radius = (Math.abs(origX - pointer.x) / 2) * 0.20;
        this.state.currentObject.set({ rx: radius, ry: radius });
      }
      // code for update selected Rectangle
    } else {
      currentObject = new fabric.Rect({
        left: origX,
        top: origY,
        width: pointer.x - origX,
        height: pointer.y - origY,
        fill: this.state.fill,
        stroke: this.state.color,
        strokeWidth: 1,
        angle: this.state.angle,
        id: nowTimestamp()
      });
      canvas.add(currentObject);
    }
    canvas.renderAll();
    return currentObject;
  }


  // function for write text
  writeText = (canvas, event, origX, origY) => {
    let currentObject = ''
    if (this.state.currentObject) {
      // code for update selected text
      this.state.currentObject.setCoords();
      // code for update selected text
    } else {
      currentObject = new fabric.IText('', {
        left: origX,
        top: origY,
        padding: 10,
        fontFamily: 'Times New Roman',
        fill: this.state.color,
        fontSize: 18,
        cornerSize: 5,
        perPixelTargetFind: true,
        id: nowTimestamp()
      });
      this.setState({
        TextTop: origY,
        TextLeft: origX
      })
      showTextToolOptions(origY, origX, canvas, event, `canvas_scroll_${this.props.currentWhiteBoardId}`);
      canvas.add(currentObject);
      canvas.setActiveObject(currentObject)
      currentObject.enterEditing();
      currentObject.hiddenTextarea.focus();
    }
    canvas.renderAll();
    return currentObject;
  }

  updateTextStyle = (canvas) => {
    let obj = canvas.getActiveObject();
    let textType = this.state.textType;
    let textStyle = this.state.textStyle;

    if (obj) {
      if (textType === 'align') {
        obj.set("textAlign", textStyle);
      } else if (textType === 'bold' && obj.fontWeight === "normal") {
        obj.set("fontWeight", "bold");
      } else if (textType === 'italic' && obj.fontStyle === "normal") {
        obj.set("fontStyle", "italic");
      } else if (textType === 'underline' && obj.underline === false) {
        obj.set('underline', true);
      } else if (textType === 'bold' && obj.fontWeight === "bold") {
        obj.set("fontWeight", "normal");
      } else if (textType === 'italic' && obj.fontStyle === "italic") {
        obj.set("fontStyle", "normal");
      } else if (textType === 'underline' && obj.underline === true) {
        obj.set('underline', false);
      } else if (textType === 'size') {
        obj.set("fontSize", textStyle);
      }
      canvas.renderAll();
      this.setState({
        drawingTool: 'text'
      }, () => {
        if (obj.text) {
          this.socketEmitEvents(obj, 'text', 'modified');
        }
      })
    }
  }

  // function for pencil tool
  freeDrawing = (canvas, event) => {
    canvas.isDrawingMode = true;
    canvas.selection = false;
    if (this.state.drawingTool === 'highliter') {
      canvas.freeDrawingBrush.width = this.state.strokeWidth;
      canvas.freeDrawingBrush.color = `rgb(${hexToRgb(this.state.color)},0.5)`;
    } else {
      canvas.freeDrawingBrush.width = 1;
      canvas.freeDrawingBrush.color = this.state.color;
    }
    canvas.renderAll()
    return '';
  }

  setCanvasBackgroundImage = (docImages, activeImage) => {
    if (docImages.length > 0) {
      this.setState({
        docImagesArray: docImages,
        activeImage: activeImage
      })

      // https://stackoverflow.com/a/23448986
      fabric.Image.fromURL(docImages[activeImage], (img) => {

        this.setState({
          imgW: img.width,
          imgH: img.height
        })

        const canvasDimensions = { width: img.width + img.height, height: img.height }

        this.state.canvas.setDimensions(canvasDimensions);
        this.state.canvas.setZoom(1);

        let center = this.state.canvas.getCenter();

        let t = canvasDimensions.height / 3;
        let l = canvasDimensions.width / 3;
        let data = {
          scrollPosition: {
            X: l,
            Y: t
          }
        }

        // img.set({
        //   top: center.top,
        //   left: center.left,
        //   originX: 'left',
        //   originY: 'top'
        // });

        this.state.canvas.setBackgroundImage(img, this.state.canvas.renderAll.bind(this.state.canvas)
          , {
            scaleX: this.state.canvas.getZoom(),
            scaleY: this.state.canvas.getZoom(),
            top: center.top,
            left: center.left,
            originX: 'center',
            originY: 'center'
          })

        // setTimeout(() => {
        updateScrollInCanvas(`canvas_scroll_${this.props.currentWhiteBoardId}`, data);
        // }, 500);
      });
    }
  }

  changeImg = (activeImage, docImages) => {
    if (this.state.canvas) {
      this.state.canvas.clear();
    }
    if (
      (this.state.drawingTool === 'pane' || this.state.drawingTool === 'text') &&
      !['', null, undefined, false, 'false'].includes(this.state.TextTop) &&
      !['', null, undefined, false, 'false'].includes(this.state.TextLeft)
    ) {
      this.setState({
        TextTop: '',
        TextLeft: ''
      }, () => {
        hideTextToolOptions()
      })
    }
    this.state.canvas.setZoom(1);
    let imgData = localStorage.getItem(`${docImages[activeImage]}_${this.props.currentWhiteBoardId}`);
    this.setCanvasBackgroundImage(docImages, activeImage);
    if (imgData) {
      imgData = JSON.parse(imgData);
      if (imgData.objects.length > 0) {
        this.state.canvas.loadFromJSON(imgData);
        this.state.canvas.renderAll();
      }
    }
    this.canvasSizeSet(this.state.canvas)
  }

  prevImag = (activeImage, docImages) => {
    var jsonData = this.state.canvas.toJSON();
    var canvasAsJson = JSON.stringify(jsonData);
    localStorage.setItem(`${docImages[activeImage]}_${this.props.currentWhiteBoardId}`, canvasAsJson);
    localStorage.setItem(this.props.currentWhiteBoardId, activeImage);
  }

  attendeeDoc = (data, docImages) => {
    let imgData = JSON.parse(data.cavasData);
    if (this.state.canvas) {
      this.state.canvas.clear();
    }
    this.setCanvasBackgroundImage(docImages, data.newActiveImage);
    if (imgData) {
      if (imgData.objects.length > 0) {
        this.state.canvas.loadFromJSON(imgData);
        this.state.canvas.renderAll();
      }
    }
    this.canvasSizeSet(this.state.canvas)
  }

  detectScroll = () => {
    const { currentWhiteBoardId, whiteBoardReduxState, userReduxState } = this.props
    if (
      Number(currentWhiteBoardId) === Number(whiteBoardReduxState.activeWhiteBoardId) && (
        Number(userReduxState.userId) === Number(whiteBoardReduxState.activeWhiteBoardOwner.userId) &&
        userReduxState.userType === whiteBoardReduxState.activeWhiteBoardOwner.userType
      )
    ) {
      detectScrollInCanvas(`canvas_scroll_${currentWhiteBoardId}`, currentWhiteBoardId)
    }
  }

  canvasScrollOnSocket = (data) => {
    const { currentWhiteBoardId, whiteBoardReduxState, userReduxState } = this.props
    if (
      currentWhiteBoardId === data.boardId && (
        userReduxState.userId !== whiteBoardReduxState.activeWhiteBoardOwner.userId &&
        userReduxState.userType !== whiteBoardReduxState.activeWhiteBoardOwner.userType
      )
    ) {
      updateScrollInCanvas(`canvas_scroll_${currentWhiteBoardId}`, data)
    }
  }

  render() {
    let userInfo = this.props.userReduxState;
    const { currentWhiteBoardId, frameData, boardType } = this.props
    const { cWidth, cHeight } = this.state

    let url_video = '';
    let doc_images = [];

    if (frameData) {
      if (boardType === 'facebook' || boardType === 'youtube' || boardType === 'website' || boardType === 'twitter') {
        url_video = frameData
      } else if (boardType === 'document') {
        doc_images = frameData.split(',');
      }
    }

    if (Object.keys(userInfo).length < 1) {
      return <></>
    }

    return (
      <>
        {
          !['youtube', 'facebook', 'twitter', 'website'].includes(boardType) && <div
            id={`canvas_scroll_${currentWhiteBoardId}`}

            style={(userInfo.userType === 'presenter') ? ((boardType === 'none' || boardType === 'document') ? {
              display: `${boardType === 'document' ? 'flex' : 'block'}`,
              overflow: "auto", height: "100%"
            } : { display: 'none' }) : { display: 'block', overflow: "auto", height: "100%" }}
          >
            <canvas
              id={`canvas_${currentWhiteBoardId}`}
            // style={{ border: "1px solid red" }}
            />
          </div>
        }
        {

          (['youtube', 'facebook', 'twitter', 'website'].includes(boardType) && this.props.whiteBoardReduxState.activeWhiteBoardId === this.props.currentWhiteBoardId) && VideoPlayer(url_video, currentWhiteBoardId, cWidth, cHeight)

        }
        {
          (
            doc_images &&
            doc_images.length > 0 &&
            ['document'].includes(boardType) &&
            this.props.whiteBoardReduxState.activeWhiteBoardId === this.props.currentWhiteBoardId
          ) && <DocImages
            doc_images={doc_images}
            changeImg={this.changeImg}
            prevImag={this.prevImag}
            attendeeDoc={this.attendeeDoc}
            setZoom={this.setZoom}
            currentWhiteBoardId={currentWhiteBoardId}
            onRef={ref => (this.docZoom = ref)}
          />
        }
      </>
    )
  }

}

let mapStateToProps = (state) => {
  return {
    whiteBoardReduxState: state.whiteBoardProps,
    toolTypeReduxState: state.toolTypeProps,
    userReduxState: state.userProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updateToolTypeState: data => dispatch(updateToolType(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Canvas)