import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SessionService from '../../services/sessionService';
import { updateRequestCount } from '../../redux/actions/chatAction';
import { newActiveWhiteBoardOwner } from '../../redux/actions/whiteBoardAction';
import { errorToastr } from '../../utils/handelError';
import { activeWhiteboardOwnerSocketEmit } from '../../socket';
import config from '../../config';
import ButtonLoader from '../loaders/buttonLoader';

class RequestBox extends Component {

  constructor(props) {
    super(props)

    this.state = {
      request: this.props.request,
      isLoading: false
    }
  }

  updateMediaPermissions = async (id, time, info, mediaType) => {
    const { whiteBoardReduxState: { activeWhiteBoardOwner } } = this.props

    let tempMediaPermissions = activeWhiteBoardOwner.mediaPermissions
    let checkMediaTypeLimitAllow = tempMediaPermissions.filter(obj => obj[mediaType]).length < (mediaType === 'video' ? config.maxVideo : config.maxAudio)

    let getAttendeeIndex = tempMediaPermissions.findIndex(obj => (Number(obj.userId) === (Number(info.attendeeId) || Number(info.userId)) && obj.userType === 'attendee'))

    if (getAttendeeIndex > -1) {
      tempMediaPermissions.splice(getAttendeeIndex, 1)
    }

    if (checkMediaTypeLimitAllow) {
      let userInfo = this.props.userReduxState;

      let data = {
        id: id,
        duration: time,
        sessionId: userInfo.sessionInfo.id
      }
      let requestStatus = await SessionService.approveRequest(userInfo.sessionInfo.id, data);
      if (requestStatus.data.success) {
        this.setState({
          request: requestStatus.data.data
        }, () => {
          const { chatReduxState: { requestCount } } = this.props
          this.props.updateRequestCountInRedux(Number(requestCount) - 1)

          tempMediaPermissions.push({
            audio: mediaType === 'audio' ? true : false,
            video: mediaType === 'video' ? true : false,
            // audio: mediaType === 'audio' || mediaType === 'video' ? true : false,
            // video: mediaType === 'video' ? true : false,
            audioTimeLimit: time === 'open' ? 'open' : Number(time),
            videoTimeLimit: time === 'open' ? 'open' : Number(time),
            userType: 'attendee',
            userId: Number(info.attendeeId) || Number(info.userId),
            name: info.attendeeName || info.fromName,
          })
          info.isVideoPermission = mediaType === 'video' ? true : false;
          info.isAudioPermission = mediaType === 'audio' || mediaType === 'video' ? true : false;

          let rawData = {
            ...activeWhiteBoardOwner,
            mediaPermissions: tempMediaPermissions,
            actionTaken: { userInfo: info, action: 'start' }
          };

          activeWhiteboardOwnerSocketEmit(rawData)
          this.props.newActiveWhiteBoardOwnerState(rawData);
          if (time !== 'open') {
            setTimeout(() => {
              let getTempAttendeeIndex = activeWhiteBoardOwner.mediaPermissions.findIndex(obj => (Number(obj.userId) === (Number(info.attendeeId) || Number(info.userId)) && obj.userType === 'attendee'))

              if (getTempAttendeeIndex > -1) {
                this.resetMediaPermissions({
                  userId: Number(info.attendeeId) || Number(info.userId),
                  userType: 'attendee'
                })
              }
            }, 1000 * 60 * Number(time));
          }
        })
      }
    } else {
      errorToastr(config.langLabels.audioVideoLimitExceed(mediaType))
    }
  }

  resetMediaPermissions = (data) => {
    const { userReduxState: { userId, name, sessionInfo: { presenterId, presenterName } }, whiteBoardReduxState: { activeWhiteBoardOwner } } = this.props

    let tempMediaPermissions = activeWhiteBoardOwner.mediaPermissions
    let getAttendeeIndex = tempMediaPermissions.findIndex(obj => (Number(obj.userId) === Number(data.userId) && obj.userType === data.userType))
    if (getAttendeeIndex > -1) {
      tempMediaPermissions.splice(getAttendeeIndex, 1)
    }

    let newOwner = {
      ...activeWhiteBoardOwner,
      mediaPermissions: tempMediaPermissions,
      actionTaken: { userInfo: data, action: 'stop' }
    };
    activeWhiteboardOwnerSocketEmit(newOwner)
    this.props.newActiveWhiteBoardOwnerState(newOwner);
  }

  render() {
    let { request, isLoading } = this.state
    const { chat } = this.props
    return (
      <>
        {
          (request.status === 'Pending') && <div className="chatBox__listing__msgBoxInfo">
            <div className="top">
              <p className="mb-0">{config.langLabels.reqFrom(request.attendeeName)}<span className="icon-voice align-middle"></span></p>
            </div>
            <ul className="list-unstyled bottom border-top-0 d-flex justify-content-between bg-white align-items-center" id="RequestTime">
              {
                isLoading ? <li className="text-center flex-fill">
                  <ButtonLoader />
                </li> : <>
                    <li className="text-center flex-fill">
                      <Link onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          isLoading: true
                        }, () => {
                          this.updateMediaPermissions(request.id, 'open', chat, 'audio')
                        })
                      }} to="" className="selectTime d-block">
                        {config.langLabels.open}
                      </Link>
                    </li>
                    <li className="text-center flex-fill">
                      <Link to="" onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          isLoading: true
                        }, () => {
                          this.updateMediaPermissions(request.id, 10, chat, 'audio')
                        })
                      }} className="selectTime d-block">
                        {config.langLabels.minutesInTime(10)}
                      </Link>
                    </li>
                    <li className="text-center flex-fill">
                      <Link to="" onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          isLoading: true
                        }, () => {
                          this.updateMediaPermissions(request.id, 5, chat, 'audio')
                        })
                      }} className="selectTime d-block">
                        {config.langLabels.minutesInTime(5)}
                      </Link>
                    </li>
                  </>
              }
            </ul>
          </div>
        }

        {
          (request.status === 'Approved') && <div className="chatBox__listing__msgBoxInfo">
            <div className="top topSend">
              <p className="mb-0">request approved {request.attendeeName} : {request.duration} </p>
              <span className="icon-voice align-middle"></span>
            </div>
          </div>
        }

      </>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    chatReduxState: state.chatProps,
    userReduxState: state.userProps,
    whiteBoardReduxState: state.whiteBoardProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updateRequestCountInRedux: (data) => dispatch(updateRequestCount(data)),
    newActiveWhiteBoardOwnerState: data => dispatch(newActiveWhiteBoardOwner(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestBox)
