import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import parser from 'react-html-parser';
import ChatForm from '../chatForm';
import ChatMessage from './chatMessage';
import { resetChatMessage, getChatMessage, scrollToBottomInList, chatListWrap } from '../../utils/commonJsFunctions';
import { groupMessageSocketEmit, groupMessageSocketOn, newRequestSocketOn } from '../../socket';
import ChatService from '../../services/chatService';
import { updateQuestionCount, updateRequestCount } from '../../redux/actions/chatAction';
import ListLoader from '../loaders/listLoader';
import RequestBoxButton from './requestBoxButton';

function replaceAll(str, term, replacement) {
  return str.replace(new RegExp(escapeRegExp(term), 'g'), replacement);
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

class ChatList extends Component {

  constructor(props) {
    super(props)

    this.state = {
      chatList: [],
      chatReply: {},
      isLoading: true
    }
  }

  componentDidMount() {
    groupMessageSocketOn(this.groupMessageOnSocket)
    this.initLoad()
    if (this.props.userReduxState && this.props.userReduxState.userType === 'presenter') {
      newRequestSocketOn(this.appendRequestList);
    }
  }

  appendRequestList = (data) => {
    const { chatReduxState: { requestCount } } = this.props
    let chatList = this.state.chatList
    chatList.push({ messageType: 'request', requestInfo: data, ...data })
    scrollToBottomInList('listingWrapper')
    this.setState({
      chatList
    }, () => {
      this.props.updateRequestCountInRedux(Number(requestCount) + 1)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.userReduxState.sessionId !== this.props.userReduxState.sessionId ||
      prevProps.type !== this.props.type
    ) {
      this.initLoad()
    }
  }

  initLoad = async () => {
    const {
      userReduxState: {
        sessionInfo
      }
    } = this.props

    if (sessionInfo) {
      try {
        let rawData = {}
        if (this.props.type === 'question') {
          rawData = { isMarked: 'yes' }
        }
        this.setState({
          isLoading: true
        })
        let getInitLoad = await ChatService.getChatQuestionList(sessionInfo.id, rawData)
        if (getInitLoad.data.success) {
          this.setState({
            chatList: getInitLoad.data.data.rows
          }, () => {
            let questionCount = this.state.chatList.filter(obj => obj.isMarked === 'yes').length
            this.props.updateQuestionCountInRedux(questionCount);
            if (this.props.type !== 'question') {
              let requestCount = this.state.chatList.filter(obj => (obj.requestInfo && obj.requestInfo.status === 'Pending')).length
              this.props.updateRequestCountInRedux(requestCount)
            }
            scrollToBottomInList('listingWrapper')
            setTimeout(() => {
              this.setState({
                isLoading: false
              })
            }, 1000 * 2);
          })
        }
      } catch (error) {

      }
    }
  }

  groupMessageOnSocket = (data) => {
    this.setState(prevState => {
      prevState.chatList.push(data.message)
      return {
        chatList: prevState.chatList
      }
    }, () => {
      scrollToBottomInList('listingWrapper')
    })
  }

  updateChatList = async (fileUrl = '') => {

    const {
      userReduxState: {
        userId,
        userType,
        name,
        sessionInfo
      }
    } = this.props

    let { chatList, chatReply } = this.state
    let parent = {}
    let textMessage = fileUrl || getChatMessage()
    let str = textMessage.replace(/(<([^>]+)>)/ig, "").trim();
    str = replaceAll(str, '&nbsp;', '')

    if (str.trim().length === 0) {
      if (textMessage.includes('/assets/libemoji/img/')) {
        str = textMessage
      }
    }

    if (this.props.type === 'question' && Object.keys(chatReply).length < 1) {
      return false
    }

    if (str.trim().length > 0) {
      if (Object.keys(chatReply).length) {
        parent = chatReply
      }

      let rawData = {
        sessionId: sessionInfo.id,
        userId: userId,
        fromName: name,
        userType: userType,
        message: textMessage,
        messageType: fileUrl ? 'file' : 'text',
        mediaUrl: fileUrl,
        parentId: parent.id,
        isMarked: 'no',
        requestId: null,
        parentMessage: Object.keys(parent).length ? parent : null
      }

      chatList.push(rawData);
      this.setState({
        chatList,
        chatReply: {}
      }, () => {
        scrollToBottomInList('listingWrapper')
        resetChatMessage()
      })

      try {
        let postedMsgRes = await ChatService.postChat(rawData)
        if (postedMsgRes.data.success) {
          let resData = postedMsgRes.data.data
          groupMessageSocketEmit(resData);
        }

      } catch (error) {

      }
    }

  }

  addChatReply = (chatReply) => {
    this.setState({
      chatReply
    })
  }

  render() {
    const { chatList, chatReply, isLoading } = this.state
    const { userReduxState } = this.props
    return (
      <>
        {
          isLoading && <ListLoader />
        }
        <div className={`listingWrapper ${isLoading ? '' : ''}`} id="chatListWrap"
          onScroll={() => {
            // e.preventDefault()
            chatListWrap()
          }}
        >
          <>
            <ul className="list-unstyled chatBox__listing text-right mb-0 " id="AllChat">
              {
                chatList.map((chat, index) => {
                  return <li key={index}>
                    <ChatMessage
                      chat={chat}
                      index={index}
                      addChatReply={this.addChatReply}
                      userReduxState={userReduxState}
                    />
                  </li>
                })
              }
              {
                Object.keys(chatReply).length > 0 && <li className="chatReplay" id="chatReplay" key={'chatReplay'}>
                  <div className="chatBox__listing__msgBox chatBox__listing__msgBox--reply d-flex border-right-0">
                    <div className="CloseBtn">
                      <Link
                        to=""
                        onClick={(e) => {
                          e.preventDefault()
                          this.addChatReply({})
                        }}
                        className="d-flex justify-content-center align-items-center"
                      ><span className="icon-cancel"></span></Link>
                    </div>
                    <div>
                      <span>{chatReply.fromName}</span>
                      <p className="mb-0">
                        {parser(chatReply.message)}
                      </p>
                    </div>
                  </div>
                  <div className="chatBox__listing__reply">
                  </div>
                </li>
              }
            </ul>
          </>
        </div >
        {
          (Number(this.props.userReduxState.userId) !== Number(this.props.userReduxState.sessionId)) && <>
            <RequestBoxButton />
            <ChatForm
              updateChatList={this.updateChatList}
              userReduxState={userReduxState}
            />
          </>
        }
      </>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps,
    chatReduxState: state.chatProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updateQuestionCountInRedux: (data) => dispatch(updateQuestionCount(data)),
    updateRequestCountInRedux: (data) => dispatch(updateRequestCount(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatList)