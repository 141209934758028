
import chatState from "../reduxStates/chatState";
import { UPDATE_REQUEST_COUNT, UPDATE_QUESTION_COUNT, UPDATE_ONLINE_ATTENDEE_COUNT } from "../reduxConst/chatConst";

let updateRequestCount = (state, action) => {
  return {
    ...state,
    requestCount: action.data
  }
}

let updateQuestionCount = (state, action) => {
  return {
    ...state,
    questionCount: action.data
  }
}

let updateOnlineAttendeeCount = (state, action) => {
  return {
    ...state,
    onlineAttendeeCount: action.data
  }
}

let chatReducer = (state = chatState, action) => {

  switch (action.type) {

    case UPDATE_REQUEST_COUNT:
      return updateRequestCount(state, action);

    case UPDATE_QUESTION_COUNT:
      return updateQuestionCount(state, action);

    case UPDATE_ONLINE_ATTENDEE_COUNT:
      return updateOnlineAttendeeCount(state, action);

    default:
      return state;
  }

}

export default chatReducer;