import { HttpClient } from "../utils/httpClient";

let CHAT_BASE_PATH = '/chat';

let getChatQuestionList = (sessionId, data = {}) => HttpClient.getApi(`${CHAT_BASE_PATH}/${sessionId}`, data);

let postChat = (data = {}) => HttpClient.postApi(`${CHAT_BASE_PATH}`, data);

let putChat = (chatId, data = {}) => HttpClient.putApi(`${CHAT_BASE_PATH}/${chatId}`, data);

const ChatService = {
  getChatQuestionList,
  postChat,
  putChat
};

export default ChatService;