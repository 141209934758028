import config from "../config";

let socketClient = config.socketClient;

/**
 * Emit functions
 */

// Broadcast delete particular Object
export let sendPresenterStream = (data) => {
  socketClient.emit('send_offer', data);
}


export let sendPresenterScreen = (data) => {
  socketClient.emit('send_screen_offer', data);
}

// Joining Seession
export let joinSessionSocketEmit = (data) => {
  socketClient.emit('join_session', data);
}

export let extendTimeRequestSocketEmit = (data) => {
  socketClient.emit('extend_time_request', data);
}

// Adding New WhiteBoard
export let addWhiteboardSocketEmit = (data) => {
  socketClient.emit('add_whiteboard', data);
}

// Update new active WhiteBoard
export let activeWhiteboardSocketEmit = (data) => {
  socketClient.emit('active_whiteboard', data);
}

// Update new active WhiteBoard Owner
export let activeWhiteboardOwnerSocketEmit = (data) => {
  socketClient.emit('active_whiteboard_owner', data);
}

// Delete particular WhiteBoard
export let deleteWhiteboardSocketEmit = (data) => {
  socketClient.emit('delete_whiteboard', data);
}

// Broadcast Message
export let groupMessageSocketEmit = (data) => {
  socketClient.emit('group_message', data);
}

export let endSessionSocketEmit = (data) => {
  socketClient.emit('stopSession', data);
}

// Send Message to Supervisor <--> Presenter
export let adminMessageSocketEmit = (data) => {
  socketClient.emit('presenter_supervisor_message', data);
}

// Broadcast new Object for add
export let objectAddedSocketEmit = (data) => {
  socketClient.emit('object_added', data);
}

export let objectModifiedSocketEmit = (data) => {
  socketClient.emit('object_modified', data);
}

// Broadcast delete particular Object
export let objectRemovedSocketEmit = (data) => {
  socketClient.emit('object_removed', data);
}

// To Broadcast the poll
export let publishPollSocketEmit = (data) => {
  socketClient.emit('publish_poll', data);
}

// To submit Poll Answer
export let submitPollAnswerSocketEmit = (data) => {
  socketClient.emit('submit_poll_answer', data);
}

export let iframeSocketEmit = (data) => {
  socketClient.emit('iframe_action', data);
}

export let iframeWebsiteSocketEmit = (data) => {
  socketClient.emit('iframe_website', data);
}

export let websiteSocketEmit = (data) => {
  socketClient.emit('website_action', data);
}

export let canvasScrollSocketEmit = (data) => {
  socketClient.emit('canvas_scroll_action', data);
}

// Emit canvas scroll detect
export let scrollDetectSocketEmit = (data) => {
  socketClient.emit('scroll_detect_canvas', data);
}


export let videoEventSocketEmit = (data) => {
  socketClient.emit('video_events', data);
}

export let extendTimeSocketEmit = (data) => {
  socketClient.emit('extend_time', data);
}


// Broadcast the change image index
export let docImageChangeSocketEmit = (data) => {
  socketClient.emit('change_doc_image', data);
}

export let clearBoardSocketEmit = (data) => {
  socketClient.emit('clear_board', data);
}

// Broadcast Message
export let newRequestSocketEmit = (data) => {
  socketClient.emit('new_request', data);
}

// Emit action to the presenter for reset media permission
export let resetMediaPermissionsEmit = (data) => {
  socketClient.emit('reset_media_permission', data);
}

export let undoActionSocketEmit = (data) => {
  socketClient.emit('undo_action', data);
}

export let redoActionSocketEmit = (data) => {
  socketClient.emit('redo_action', data);
}

export let zoomInOutSocketEmit = (data) => {
  socketClient.emit('zoom_in_out', data);
}

export let screenShareStartedSocketEmit = (data) => {
  socketClient.emit('screen_share_started', data);
}

export let screenShareStopedSocketEmit = (data) => {
  socketClient.emit('screen_share_stoped', data);
}

export let resetWhiteBoardStateSocketEmit = (data) => {
  socketClient.emit('reset_whiteboard_state', data);
}

export let hideShowAllAudioSocketEmit = (data) => {
  socketClient.emit('hideShowAllAudio', data);
}

export let hideShowAllVideoSocketEmit = (data) => {
  socketClient.emit('hideShowAllVideo', data);
}

export let onIceCandidateSocketEmit = (data) => {
  socketClient.emit('onIceCandidate', data);
}

export let videoFromUserSocketEmit = (data) => {
  socketClient.emit('videoFromUser', data);
}

export let closePollModalsSocketEmit = () => {
  socketClient.emit('closePollModels', {});
}

/**
 * On functions
 */

// Connection with Socket
export let connectSocketOn = (func, rawData) => {
  socketClient.on('connect', (data) => {
    func(data, rawData)
  });
}

// After auth
export let sessionStartedSocketOn = (func, rawData = {}, postData = {}) => {
  socketClient.on('session_Started', (data) => {
    func(data, rawData, postData)
  });
}

export let hostJoinedSocketOn = (func) => {
  socketClient.on('host_joined', (data) => {
    func(data)
  });
}



// Adding New WhiteBoard on connected members
export let addWhiteboardSocketOn = (func) => {
  socketClient.on('add_whiteboard', (data) => {
    func(data)
  });
}

export let extendTimeRequestSocketOn = (func) => {
  socketClient.on('extend_time_request', (data) => {
    func(data)
  });
}

// Adding New WhiteBoard on connected members
export let linkExistSocketOn = (func) => {
  socketClient.on('link_exist', (data) => {
    func(data)
  });
}

// Update new active WhiteBoard on connected members
export let activeWhiteboardSocketOn = (func) => {
  socketClient.on('active_whiteboard', (data) => {
    func(data)
  });
}

// Update new active WhiteBoard Owner on  connected members
export let activeWhiteboardOwnerSocketOn = (func) => {
  socketClient.on('active_whiteboard_owner', (data) => {
    func(data)
  });
}

// Delete particular WhiteBoard on connected members
export let deleteWhiteboardSocketOn = (func) => {
  socketClient.on('delete_whiteboard', (data) => {
    func(data)
  });
}

// Broadcast Message Receive
export let groupMessageSocketOn = (func) => {
  socketClient.on('group_message', (data) => {
    func(data)
  });
}

// Message Receive from Supervisor <--> Presenter
export let adminMessageSocketOn = (func) => {
  socketClient.on('presenter_supervisor_message', (data) => {
    func(data)
  });
}

// Broadcast new Object for add on connected members
export let clearBoardSocketOn = (func, canvas) => {
  socketClient.on('clear_board', (data) => {
    func(canvas, data)
  });
}

// Broadcast new Object for add on connected members
export let objectAddedSocketOn = (func, canvas) => {
  socketClient.on('object_added', (data) => {
    func(canvas, data)
  });
}

// Broadcast new Object for modified on connected members
export let objectModifiedSocketOn = (func, canvas) => {
  socketClient.on('object_modified', (data) => {
    func(canvas, data)
  });
}

// Broadcast delete particular Object on connected members
export let objectRemovedSocketOn = (func, canvas) => {
  socketClient.on('object_removed', (data) => {
    func(canvas, data)
  });
}

// To show Poll
export let showPublishPollSocketOn = (func) => {
  socketClient.on('publish_poll', (data) => {
    func(data)
  });
}

// To submit Poll
export let submitPollAnswerSocketOn = (func) => {
  socketClient.on('submit_poll_answer', (data) => {
    func(data)
  });
}

// when session get on hold
export let sessionOnHoldSocketOn = (func) => {
  socketClient.on('session_hold', (data) => {
    func(data)
  });
}

// when session resume
export let sessionResumeSocketOn = (func) => {
  socketClient.on('session_resume', (data) => {
    func(data)
  });
}

// call to resend offer by attendee
export let resendAttendeeOfferSocketOn = (func) => {
  socketClient.on('resend_attendee_offer', (data) => {
    setTimeout(() => {
      func(data)
    }, 5 * 1000);
  });
}

export let iframeSocketOn = (func) => {
  socketClient.on('iframe_action', (data) => {
    func(data)
  });
}

export let iframeWebsiteSocketOn = (func) => {
  socketClient.on('iframe_website', (data) => {
    func(data)
  });
}

export let websiteSocketOn = (func) => {
  socketClient.on('website_action', (data) => {
    func(data)
  });
}

export let canvasScrollSocketOn = (func) => {
  socketClient.on('canvas_scroll_action', (data) => {
    func(data)
  });
}

export let videoEventSocketOn = (func) => {
  socketClient.on('video_events', (data) => {
    func(data)
  });
}

export let extendTimeSocketOn = (func) => {
  socketClient.on('extend_time', (data) => {
    func(data)
  });
}

export let endSessionSocketOn = (func) => {
  socketClient.on('stopSession', (data) => {
    func(data)
  });
}

// export let presenterDisconnectedSocketOn = (func) => {
//   socketClient.on('presenter_disconnected', (data) => {
//     func(data)
//   });
// }

// On canvas scroll detect
export let scrollDetectSocketOn = (data) => {
  socketClient.on('scroll_detect_canvas', data);
}

export let newPeerSocketOn = (func) => {
  socketClient.on('new_peer', (data) => {
    func(data)
  });
}

export let removePeerSocketOn = (func) => {
  socketClient.on('remove_peer', (data) => {
    func(data)
  });
}

// Listen when attendee stop manually
export let resetMediaPermissionsSocketOn = (func) => {
  socketClient.on('reset_media_permission', (data) => {
    func(data)
  });
}

// To get the changed doc image index
export let docImageChangeSocketOn = (func) => {
  socketClient.on('change_doc_image', (data) => {
    func(data)
  });
}

export let zoomInOutSocketOn = (func) => {
  socketClient.on('zoom_in_out', (data) => {
    func(data)
  });
}

// To get the changed doc image index
export let newRequestSocketOn = (func) => {
  socketClient.on('new_request', (data) => {
    func(data)
  });
}

// To get the changed doc image index
export let undoActionSocketOn = (func) => {
  socketClient.on('undo_action', (data) => {
    func(data)
  });
}

// To get the changed doc image index
export let redoActionSocketOn = (func) => {
  socketClient.on('redo_action', (data) => {
    func(data)
  });
}

export let receivePresenterOfferSocketOn = (func, webRtcPeer) => {
  socketClient.on('receive_presenter_offer', (data) => {
    func(data, webRtcPeer)
  });
}

export let receiveAttendeeOfferSocketOn = (func, webRtcPeer) => {
  socketClient.on('receive_attendee_offer', (data) => {
    func(data, webRtcPeer)
  });
}

export let iceCandidatePresenterSocketOn = (func, webRtcPeer) => {
  socketClient.on('presenter_ice_candidate', (data) => {
    func(data, webRtcPeer)
  });
}

export let iceCandidateAttendeeSocketOn = (func, webRtcPeer) => {
  socketClient.on('attendee_ice_candidate', (data) => {
    func(data, webRtcPeer)
  });
}

export let receiveScreenOfferSocketOn = (func, webRtcPeer) => {
  socketClient.on('receive_screen_offer', (data) => {
    func(data, webRtcPeer)
  });
}

export let iceCandidateScreenSocketOn = (func, webRtcPeer) => {
  socketClient.on('screen_ice_candidate', (data) => {
    func(data, webRtcPeer)
  });
}

export let screenShareStartedSocketOn = (func) => {
  socketClient.on('screen_share_started', (data) => {
    func(data)
  });
}

export let screenShareStopedSocketOn = (func) => {
  socketClient.on('screen_share_stoped', (data) => {
    func(data)
  });
}

export let restartScreenShareSocketOn = (func) => {
  socketClient.on('restart_screen_share', (data) => {
    func(data)
  });
}

export let resetWhiteBoardStateSocketOn = (func) => {
  socketClient.on('reset_whiteboard_state', (data) => {
    func(data)
  });
}

export let disConnectSocketOn = (func) => {
  socketClient.on('disconnect', (data) => {
    func(data)
  });
}

export let startPresenterCallVideoSocketOn = (func) => {
  socketClient.on("startPresenter", data => {
    func(data)
  });
}

export let iceCandidateSocketOn = (func) => {
  socketClient.on("iceCandidate", data => {
    func(data)
  });
}


export let afterJoinRoomSocketOn = (func, rawData) => {
  socketClient.on("after_join_room", data => {
    func(rawData)
  });
}



export let presenterArrivedSocketOn = (func) => {
  socketClient.on("presenterArrived", data => {
    func(data)
  });
}

export let joinRoomAttendeeSocketEmit = (data) => {
  socketClient.emit("joinRoomAttendee", data);
}

export let newUserArrivedSocketOn = (func) => {
  socketClient.on("newUserArrived", data => {
    func(data)
  });
}

export let existingUsersSocketOn = (func) => {
  socketClient.on("existingUsers", data => {
    func(data)
  });
}


export let receiveVideoAnswerSocketOn = (func) => {
  socketClient.on("receiveVideoAnswer", data => {
    func(data)
  });
}

export let participantLeftSocketOn = (func) => {
  socketClient.on("participantLeft", data => {
    func(data)
  });
}

export let closePollModalsSocketOn = (func) => {
  socketClient.on("closePollModels", data => {
    func(data)
  });
}


export let allowMediaSocketOn = (func) => {
  socketClient.on("allowMedia", data => {
    func(data)
  });
}


export let allowMediaSocketEmit = (data) => {
  socketClient.emit("allowMedia", data);
}


export let allowMediaFromSocketOn = (func) => {
  socketClient.on("allowMediaFrom", data => {
    func(data)
  });
}

export let stopMediaSocketOn = (func) => {
  socketClient.on("stopMedia", data => {
    func(data)
  });
}

export let stopMediaSelfSocketOn = (func) => {
  socketClient.on("stopMediaSelf", data => {
    func(data)
  });
}

export let stopMediaFromSocketOn = (func) => {
  socketClient.on("stopMediaFrom", data => {
    func(data)
  });
}


export let stopMediaSocketEmit = (data) => {
  socketClient.emit("stopMedia", data);
}

export let mediaFromUsersSocketOn = (func) => {
  socketClient.on("mediaFromUsers", data => {
    func(data)
  });
}

export let hideShowAllAudioSocketOn = (func) => {
  socketClient.on("hideShowAllAudio", data => {
    func(data)
  });
}

export let hideShowAllVideoSocketOn = (func) => {
  socketClient.on("hideShowAllVideo", data => {
    func(data)
  });
}