import React, { Component } from 'react'
import MediaSettings from './mediaSetting';
import TestConnection from './testConnection';
import { Link } from 'react-router-dom';
import {
  onOpenReactModalForm,
  resetReactModalForm
} from '../../utils/commonJsFunctions';
import config from '../../config';

class Settings extends Component {

  state = {
    activeTab: ''
  }

  componentDidMount() {
    onOpenReactModalForm('settingModal', this.initRender)
    resetReactModalForm('settingModal', this.resetState)
  }

  resetState = () => {
    this.setState({
      activeTab: ''
    })
  }

  initRender = () => {
    this.setState({
      activeTab: 'testConnection'
    })
  }

  render() {
    const { activeTab } = this.state;
    return (
      <div className="modal fade" id="settingModal" tabIndex="-1" role="dialog" aria-labelledby="settingModal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered w-570 setting-modal" role="document">
          <div className="modal-content bg-grey">
            <div className="modal-header border-bottom-0 pb-0">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="icon-close"></i>
              </button>
              <h5 className="modal-title font-neomd">{config.langLabels.setting}</h5>
            </div>
            <div className="modal-body" dir="rtl">
              <ul className="nav nav-tabs justify-content-center border-0" id="myTab" role="tablist">
                <li className="nav-item">
                  <Link
                    className={`nav-link ${(activeTab === 'mediaSettings') ? 'active' : ''}`}
                    id="video-tab" data-toggle="tab"
                    to="" role="tab"
                    aria-controls="video"
                    aria-selected="false"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        activeTab: 'mediaSettings'
                      })
                    }}
                  >{config.langLabels.videoSound}</Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${(activeTab === 'testConnection') ? 'active' : ''}`}
                    id="connection-tab"
                    data-toggle="tab"
                    to=""
                    role="tab"
                    aria-controls="connection"
                    aria-selected="true"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        activeTab: 'testConnection'
                      })
                    }}
                  >{config.langLabels.testConnection}</Link>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                {
                  activeTab && ((activeTab === 'testConnection') ? <TestConnection /> : <MediaSettings />)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default Settings