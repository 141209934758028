import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { updateUser } from '../../redux/actions/userAction';
import { newActiveWhiteBoardOwner, resetWhiteBoard, addWhiteBoard, updateRecordingOn, updateStartScreenShare } from '../../redux/actions/whiteBoardAction';
import { nowTimestamp } from '../../utils/customTimeFunctions';
import {
  joinSessionSocketEmit,
  addWhiteboardSocketEmit,
  connectSocketOn,
  activeWhiteboardOwnerSocketEmit,
  activeWhiteboardOwnerSocketOn,
  linkExistSocketOn,
  sessionOnHoldSocketOn,
  sessionResumeSocketOn,
  resetWhiteBoardStateSocketEmit,
  resetWhiteBoardStateSocketOn,
  sessionStartedSocketOn,
  hostJoinedSocketOn,
  disConnectSocketOn
} from '../../socket';
import SessionService from '../../services/sessionService';
import { errorToastr } from '../../utils/handelError';
import config from '../../config';
import { feedBoardTitle, pageLoader } from '../../utils/commonJsFunctions';
import bootbox from 'bootbox';
import PageNotFound from '../notFound';

export default function (ComposedComponent) {
  let isAuthenticated = false;

  class RequireAuth extends Component {

    constructor(props) {
      super(props);
      this.state = {
        message: 'Loading'
      }
    }
    componentDidMount() {
      try {
        connectSocketOn(this.connectOnSocket);
        disConnectSocketOn(this.connectOnSocket)
      } catch (e) {
        errorToastr(config.langLabels.sessionAuth);
        this.props.history.push('/not-found');
      }
    }

    checkSessionStorage = () => {
      let getLocalStorage = localStorage.getItem(window.location.href)
      if (getLocalStorage === null) {
        localStorage.clear();
        localStorage.setItem(window.location.href, "session")
      }
    }

    connectOnSocket = async () => {
      this.checkSessionStorage()
      linkExistSocketOn(this.linkExist);
      hostJoinedSocketOn(this.hostJoined)
      pageLoader('show', config.langLabels.loading);
      try {
        // get all users id and session id from session link
        const {
          match: {
            params: {
              sessionId,
              presenterId,
              attendeeId,
              supervisorId
            }
          }
        } = this.props;

        let userId = Number(presenterId || attendeeId || supervisorId);
        let userType = (presenterId ? 'presenter' : (attendeeId ? 'attendee' : 'supervisor'));

        let postData = {
          sessionId: sessionId,
          userType: userType,
          userId: Number(userId)
        };
        // call api for session authentication
        let sessionInfo = await SessionService.sessionAuthentication(postData);
        if (sessionInfo.data.success) {

          joinSessionSocketEmit({
            room: sessionId,
            userType,
            userId: Number(userId)
          });

          let apiData = sessionInfo.data.data;
          sessionStartedSocketOn(this.afterSessionStarted, apiData, postData);

        } else {
          let errorMsg = config.langLabels.sessionAuth;

          switch (sessionInfo.data.message) {
            case 'Session Not Started Yet':
              errorMsg = config.langLabels.sessionNotStarted
              break;

            case 'Session Not Found':
              errorMsg = config.langLabels.sessionNotFound
              break;

            default:
              break;
          }

          this.setState({
            message: errorMsg
          }, () => {
            errorToastr(errorMsg);
          })
          // this.props.history.push('/not-found');
        }
      } catch (error) {
        pageLoader('show');
      }
    }


    hostJoined = () => {
      // setTimeout(() => {
      //   window.location.reload();
      // }, 5 * 1000);
    }

    afterSessionStarted = (data, apiData, postData) => {
      // get all users id and session id from session link
      const {
        match: {
          params: {
            sessionId,
            presenterId,
            attendeeId,
            supervisorId
          }
        }
      } = this.props;

      let userType = (presenterId ? 'presenter' : (attendeeId ? 'attendee' : 'supervisor'));

      isAuthenticated = true;

      this.afterAuthenticate(apiData, { name: apiData.name, ...postData });
      if (userType !== 'presenter' && apiData.status.toLowerCase() !== 'started') {
        this.showLoaderSessionHold()
      } else {
        this.hideLoader()
      }
      if (userType !== 'presenter') {
        sessionOnHoldSocketOn(this.sessionOnHoldOnSocket)
        sessionResumeSocketOn(this.sessionResumeOnSocket)
      }
    }

    afterAuthenticate = (data, rawData) => {
      const {
        userType,
      } = rawData
      let dataToResetWhiteBoard = {}

      let whiteBoardsList = data.whiteBoardList || []
      let newData = Object.assign({}, data)
      delete newData.whiteBoardList

      this.props.updateUserState(Object.assign({}, rawData, newData, { sessionDuration: data.sessionInfo.actualDuration, extendedTime: (data.sessionInfo.extendedTime || 0) }));

      if (whiteBoardsList.length) {
        dataToResetWhiteBoard = {
          whiteBoards: whiteBoardsList,
          activeWhiteBoardId: Number(data.sessionInfo.activeBoardId) || Number(whiteBoardsList[0].boardId),
          activeWhiteBoardOwner: {
            sessionId: data.sessionInfo.sessionUuid,
            userType: 'presenter',
            userId: Number(data.sessionInfo.presenterId),
            name: data.name,
            boardTimeLimit: 'open',
            mediaPermissions: [{
              audio: true,
              video: true,
              audioTimeLimit: 'open',
              videoTimeLimit: 'open',
              userType: 'presenter',
              userId: Number(data.sessionInfo.presenterId),
              name: data.name,
            }],
            actionTaken: {}
          },
          isRecordingOn: false,
          startScreenShareModal: false
        }
        this.resetWhiteBoardInRedux(dataToResetWhiteBoard)
      }

      if (userType === 'presenter') {
        if (whiteBoardsList.length === 0) {
          feedBoardTitle(this.addNewBoard)
          let addMediaPermissionsInRawData = {
            ...rawData,
            boardTimeLimit: 'open',
            mediaPermissions: [{
              audio: true,
              video: true,
              audioTimeLimit: 'open',
              videoTimeLimit: 'open',
              userType: 'presenter',
              userId: rawData.userId,
              name: rawData.name,
            }],
            actionTaken: {}
          }
          activeWhiteboardOwnerSocketEmit(addMediaPermissionsInRawData)
          this.props.newActiveWhiteBoardOwnerState(addMediaPermissionsInRawData);
        } else {
          resetWhiteBoardStateSocketEmit(dataToResetWhiteBoard)
        }
      }
      activeWhiteboardOwnerSocketOn(this.activeWhiteboardOwnerOnSocket)
      resetWhiteBoardStateSocketOn(this.resetWhiteBoardInRedux)

      if (this.props.whiteBoardReduxState.isRecordingOn) {
        bootbox.alert(config.langLabels.errScreenShareStop);
        this.props.updateRecordingOnState(false);
        this.props.updateStartScreenShareState(false);
      }
    }

    resetWhiteBoardInRedux = (data) => {
      this.props.resetWhiteBoardState(data)
    }

    linkExist = (data) => {
      let message = ''
      if (data.type === 'waiting') {
        message = config.langLabels.errMsgOnJoin
      } else if (data.type === 'already_exist') {
        message = config.langLabels.errMsgOnAlreadyJoin
      } else {
        message = config.langLabels.sessionAuth
      }
      this.setState({
        message
      }, () => {
        errorToastr(message);
      })
      //this.props.history.push('/not-found');
    }

    activeWhiteboardOwnerOnSocket = (data) => {
      this.props.newActiveWhiteBoardOwnerState(data);
    }

    addNewBoard = (title) => {
      let boardId = nowTimestamp();
      this.props.addWhiteBoardInRedux({ boardId: boardId, title: title })
      addWhiteboardSocketEmit({ boardId: boardId, title: title });
    }

    addNewBoard = async (title) => {
      pageLoader('show', config.langLabels.addingNewBoard);
      let userInfo = this.props.userReduxState;
      let boardId = nowTimestamp();

      let resAddNewBoard = await SessionService.addNewBoard(userInfo.sessionInfo.id, { boardId: boardId, title: title, boardType: 'none' })

      if (resAddNewBoard.data.success) {
        this.hideLoader()
        this.props.addWhiteBoardInRedux({ boardId: boardId, title: title, boardType: 'none' })
        addWhiteboardSocketEmit({ boardId: boardId, title: title });
      }
    }

    sessionOnHoldOnSocket = (data) => {
      this.showLoaderSessionHold()
    }

    sessionResumeOnSocket = (data) => {
      this.hideLoader()
    }

    hideLoader = () => {
      pageLoader('hide');
    }

    showLoaderSessionHold = () => {
      pageLoader('show', config.langLabels.sessionHoldMsg);
    }

    render() {

      const { message } = this.state

      if (isAuthenticated) {
        return <>
          <ComposedComponent {...this.props} />
        </>
      }
      if (message === 'Loading') {
        return <>
          <main className="main-content loadingPage d-flex align-items-center justify-content-center">
            <div className="text-center">
              <div className="spinner-grow" role="status">
                <span className="sr-only">{config.langLabels.loading}</span>
              </div>
              <h2>{message}</h2>
            </div>
          </main>
        </>
      }
      return <>
        {message && <main className="main-content error_page">
          <PageNotFound message={message} />
        </main>
        }
      </>
    }

  }

  let mapStateToProps = (state) => {
    return {
      whiteBoardReduxState: state.whiteBoardProps,
      userReduxState: state.userProps,
    }
  }

  let mapDispatchToProps = (dispatch) => {
    return {
      addWhiteBoardInRedux: (data) => dispatch(addWhiteBoard(data)),
      updateUserState: data => dispatch(updateUser(data)),
      newActiveWhiteBoardOwnerState: data => dispatch(newActiveWhiteBoardOwner(data)),
      resetWhiteBoardState: data => dispatch(resetWhiteBoard(data)),
      updateRecordingOnState: data => dispatch(updateRecordingOn(data)),
      updateStartScreenShareState: data => dispatch(updateStartScreenShare(data)),
    }
  }

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(RequireAuth))
}