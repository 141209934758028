import React, { Component } from 'react'
import config from '../../config'

export default class PageNotFound extends Component {
  render() {
    const { message } = this.props
    return (
      <>
        <div id="notfound">
          <div className="notfound">
            <div className="notfound-404">
              <h1 className="blue-color">{config.langLabels.oops}</h1>
              <h2>{message ? message : config.langLabels.pnf}</h2>
            </div>
          </div>
        </div>
      </>
    )
  }
}
