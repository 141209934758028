import React, { Component } from 'react'
import { connect } from 'react-redux';
import { ACCEPT_PRESENTATION, ACCEPT_IMAGES_TYPE, ACCEPT_WORD, ACCEPT_PDF, ACCEPT_EXCEL } from '../../utils/constants'
import { hideBootstrapModal, pageLoader } from '../../utils/commonJsFunctions'
import { validateExistsInTypeOfFile, validateFileSize } from '../../utils/customValidator'
import { toolTypeData } from '../../utils/toolData'
import SessionService from '../../services/sessionService'
import { nowTimestamp } from '../../utils/customTimeFunctions'
import { addWhiteboardSocketEmit } from '../../socket'
import { updateToolType } from '../../redux/actions/toolTypeAction'
import { addWhiteBoard } from '../../redux/actions/whiteBoardAction'
import Axios from 'axios';
import { handelApiError, successToastr, errorToastr } from '../../utils/handelError';
import config from '../../config';

class FormDocPresentation extends Component {

  constructor(props) {
    super(props)

    this.state = {
      fileName: '',
      file: {}
    }
  }

  fileChange = (e) => {
    const { formType } = this.props
    const { files: FILELIST } = e.target
    let fileName = '';
    let file = {};
    if (
      FILELIST.length &&
      FILELIST.length === 1 &&
      validateExistsInTypeOfFile(FILELIST[0].name, formType) &&
      validateFileSize(FILELIST[0].size)
    ) {
      fileName = FILELIST[0].name
      file = FILELIST[0]
    }

    this.setState({
      fileName,
      file
    }, () => {
      this.uploadFile()
    });
  }

  uploadFile = async () => {
    try {
      pageLoader('show');
      hideBootstrapModal('addModal');
      const { userReduxState: { sessionInfo: { id } }, whiteBoardReduxState: { whiteBoards } } = this.props
      if (whiteBoards.length < 10) {
        let rawData = new FormData();
        rawData.append('file', this.state.file);
        let response = await Axios.post(config.DOC_UPLOAD, rawData);
        if (response.data.success) {
          this.updateToolTypeInRedux('document', { doc_images: response.data.data.images, board_name: (response.data.data.fileName) ? response.data.data.fileName : 'Document' });
        }
      } else {
        errorToastr(config.langLabels.boardAddLimitExceed)
      }
      pageLoader('hide');
    } catch (error) {
      return handelApiError(error);
    }
  }

  updateToolTypeInRedux = async (tool = '', toolOptions = {}) => {
    pageLoader('show');
    let result = await this.addNewBoard(toolOptions.board_name, toolOptions);
    if (result) {
      successToastr(config.langLabels.documentUploaded)
      let data = toolTypeData(tool, toolOptions, this.props.toolTypeReduxState)
      this.props.updateToolTypeState(data)
    }
    this.setState({
      fileName: '',
      file: {}
    }, () => {
      pageLoader('hide');
    })
  }

  addNewBoard = async (title, toolOptions) => {
    const { userReduxState: { sessionInfo: { id } }, whiteBoardReduxState: { whiteBoards } } = this.props
    if (whiteBoards.length < 10) {
      let boardId = nowTimestamp();
      let boardType = 'document'
      let payload = {
        boardId: boardId,
        title: title,
        boardType: boardType,
        frameData: toolOptions.doc_images.join(',')
      }
      let resAddNewBoard = await SessionService.addNewBoard(id, payload)
      if (resAddNewBoard.data.success) {
        this.props.addWhiteBoardInRedux(payload)
        addWhiteboardSocketEmit(payload);
        return true;
      }
    } else {
      errorToastr(config.langLabels.boardAddLimitExceed)
    }
    return false;
  }

  render() {
    let formType = this.props.formType
    return (
      <input
        type="file"
        id={`uploadFile_${formType}`}
        // className="d-none"
        name={`uploadFile_${formType}`}
        onChange={(e) => {
          e.preventDefault()
          this.fileChange(e)
        }}
        accept={formType === 'presentation' ? ACCEPT_PRESENTATION : `${ACCEPT_IMAGES_TYPE}, ${ACCEPT_WORD}, ${ACCEPT_PDF}, ${ACCEPT_EXCEL}`}
      />
    )
  }
}

let mapStateToProps = (state) => {
  return {
    toolTypeReduxState: state.toolTypeProps,
    whiteBoardReduxState: state.whiteBoardProps,
    userReduxState: state.userProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updateToolTypeState: data => dispatch(updateToolType(data)),
    addWhiteBoardInRedux: (data) => dispatch(addWhiteBoard(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormDocPresentation)