import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SessionService from '../../services/sessionService';
import {
  newPeerSocketOn,
  activeWhiteboardOwnerSocketEmit,
  removePeerSocketOn,
  resetMediaPermissionsSocketOn,
  linkExistSocketOn,
  sessionOnHoldSocketOn,
  sessionResumeSocketOn,
} from '../../socket';
import { newActiveWhiteBoardOwner } from '../../redux/actions/whiteBoardAction';
import ListLoader from '../../components/loaders/listLoader';
import { errorToastr } from '../../utils/handelError';
import { updateOnlineAttendeeCount } from '../../redux/actions/chatAction';
import config from '../../config';

class Attendance extends Component {

  state = {
    joinedList: [],
    absentList: [],
    attendeeList: [],
    searchKey: '',
    isLoading: true,
    whiteBoardOwner: {},
    isSessionOnHold: false
  }

  componentDidMount() {
    this.loadAttendeeList();
    newPeerSocketOn(this.newAttendeeJoined);
    removePeerSocketOn(this.removeAttendee);
    resetMediaPermissionsSocketOn(this.resetMediaPermissions);
    linkExistSocketOn(this.linkExist);
    if (this.props.userReduxState.userType !== 'presenter') {
      sessionOnHoldSocketOn(this.sessionOnHoldOnSocket)
      sessionResumeSocketOn(this.sessionResumeOnSocket)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.whiteBoardReduxState.activeWhiteBoardOwner.sessionId !== this.props.whiteBoardReduxState.activeWhiteBoardOwner.sessionId) {
      this.updateWhiteBoardOwner()
    }

    if (prevProps.userReduxState !== this.props.userReduxState) {
      this.loadAttendeeList();
    }
  }

  linkExist = (data) => {
    if (data.type === 'waiting') {
      this.setState({
        isSessionOnHold: true
      })
    }
  }

  sessionOnHoldOnSocket = (data) => {
    this.setState({
      isSessionOnHold: true
    })
  }

  sessionResumeOnSocket = (data) => {
    this.setState({
      isSessionOnHold: false
    })
  }

  loadAttendeeList = async () => {
    this.setState({
      isLoading: true
    })
    let userInfo = this.props.userReduxState;
    if (Object.keys(userInfo).length) {
      try {
        let attendeeList = await SessionService.sessionAttendeeList(userInfo.sessionInfo.id, this.state.searchKey);
        if (attendeeList.data.success) {
          this.setState({
            isLoading: false,
            attendeeList: attendeeList.data.data
          }, () => {
            this.updateCountOnlineAttendee()
          })
        }
      } catch (error) {
        errorToastr(config.langLabels.pleaseTryAgain)
      }
    }
  }

  newAttendeeJoined = async (data) => {
    try {
      if (data.userType === 'attendee') {
        const { userReduxState: { sessionInfo: { id } } } = this.props
        let studentList = this.state.attendeeList;
        let searchInfo = studentList.find((item) => {
          return Number(item.attendeeId) === Number(data.userId)
        })
        let foundIndex = studentList.findIndex((item) => {
          return Number(item.attendeeId) === Number(data.userId)
        })
        if (searchInfo) {
          searchInfo.status = 'Joined';
          studentList[foundIndex] = searchInfo;

          this.setState({
            attendeeList: studentList
          }, () => {
            this.updateCountOnlineAttendee()
          })
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  removeAttendee = async (data) => {
    try {
      if (data.userType === 'attendee') {
        const { userReduxState: { sessionInfo: { id } } } = this.props
        let studentList = this.state.attendeeList;
        let searchInfo = studentList.find((item) => {
          return Number(item.attendeeId) === Number(data.userId)
        })
        let foundIndex = studentList.findIndex((item) => {
          return Number(item.attendeeId) === Number(data.userId)
        })
        if (searchInfo) {
          searchInfo.status = 'Not_Attended';
          studentList[foundIndex] = searchInfo;
          this.setState({
            attendeeList: studentList
          }, () => {
            this.updateCountOnlineAttendee()
          })
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  updateCountOnlineAttendee = () => {
    this.props.updateOnlineAttendeeCountState(this.state.attendeeList.filter((item) => {
      return item.status === 'Joined'
    }).length)
  }

  newBoardOwner = (info, time) => {
    const { userReduxState: { sessionInfo: { sessionUuid } }, whiteBoardReduxState: { activeWhiteBoardOwner: { mediaPermissions, actionTaken } } } = this.props
    let rawData = {
      sessionId: sessionUuid,
      userType: 'attendee',
      userId: Number(info.attendeeId) || Number(info.userId),
      name: info.name,
      boardTimeLimit: time === 'open' ? 'open' : Number(time),
      mediaPermissions: mediaPermissions,
      actionTaken: actionTaken
    };

    activeWhiteboardOwnerSocketEmit(rawData)
    this.props.newActiveWhiteBoardOwnerState(rawData);
    if (time !== 'open') {
      setTimeout(() => {
        this.resetOwner()
      }, 1000 * 60 * Number(time));
    }
  }

  resetOwner = () => {
    const { userReduxState: { userId, name, sessionInfo: { sessionUuid, presenterId, presenterName } }, whiteBoardReduxState: { activeWhiteBoardOwner: { mediaPermissions, actionTaken } } } = this.props
    let newOwner = {
      sessionId: sessionUuid,
      userType: 'presenter',
      userId: Number(presenterId),
      name: presenterName,
      boardTimeLimit: 'open',
      mediaPermissions: mediaPermissions,
      actionTaken: actionTaken
    };
    activeWhiteboardOwnerSocketEmit(newOwner)
    this.props.newActiveWhiteBoardOwnerState(newOwner);
  }

  updateWhiteBoardOwner = () => {
    const { whiteBoardReduxState: { activeWhiteBoardOwner } } = this.props
    this.setState({
      whiteBoardOwner: activeWhiteBoardOwner
    })
  }

  updateMediaPermissions = (info, time, mediaType) => {
    const { whiteBoardReduxState: { activeWhiteBoardOwner } } = this.props

    let tempMediaPermissions = activeWhiteBoardOwner.mediaPermissions
    let checkMediaTypeLimitAllow = tempMediaPermissions.filter(obj => obj[mediaType]).length < (mediaType === 'video' ? config.maxVideo : config.maxAudio)

    let getAttendeeIndex = tempMediaPermissions.findIndex(obj => (Number(obj.userId) === (Number(info.attendeeId) || Number(info.userId)) && obj.userType === 'attendee'))

    if (getAttendeeIndex > -1) {
      tempMediaPermissions.splice(getAttendeeIndex, 1)
    }

    if (checkMediaTypeLimitAllow) {
      tempMediaPermissions.push({
        audio: mediaType === 'audio' ? true : false,
        video: mediaType === 'video' ? true : false,
        // audio: mediaType === 'audio' || mediaType === 'video' ? true : false,
        // video: mediaType === 'video' ? true : false,
        audioTimeLimit: time === 'open' ? 'open' : Number(time),
        videoTimeLimit: time === 'open' ? 'open' : Number(time),
        userType: 'attendee',
        userId: Number(info.attendeeId) || Number(info.userId),
        name: info.name,
      })

      info.isVideoPermission = mediaType === 'video' ? true : false;
      info.isAudioPermission = mediaType === 'audio' || mediaType === 'video' ? true : false;

      let rawData = {
        ...activeWhiteBoardOwner,
        mediaPermissions: tempMediaPermissions,
        actionTaken: { userInfo: info, action: 'start' }
      };

      activeWhiteboardOwnerSocketEmit(rawData)
      this.props.newActiveWhiteBoardOwnerState(rawData);
      if (time !== 'open') {
        let userId = Number(info.attendeeId) || Number(info.userId);
        let mediaInterval = setTimeout(() => {
          this.resetMediaPermissions({
            userId: Number(info.attendeeId) || Number(info.userId),
            userType: 'attendee'
          })
        }, 1000 * 60 * Number(time));
        this.setState({
          [`mediaInterval_${userId}`]: mediaInterval
        })
      }
    } else {
      errorToastr(config.langLabels.audioVideoLimitExceed(mediaType))
    }
  }

  resetMediaPermissions = (data) => {
    const { userReduxState: { userId, name, sessionInfo: { presenterId, presenterName } }, whiteBoardReduxState: { activeWhiteBoardOwner } } = this.props

    let tempMediaPermissions = activeWhiteBoardOwner.mediaPermissions
    let getAttendeeIndex = tempMediaPermissions.findIndex(obj => (Number(obj.userId) === Number(data.userId) && obj.userType === data.userType))

    if (getAttendeeIndex > -1) {
      tempMediaPermissions.splice(getAttendeeIndex, 1)
    }

    let newOwner = {
      ...activeWhiteBoardOwner,
      mediaPermissions: tempMediaPermissions,
      actionTaken: { userInfo: data, action: 'stop' }
    };

    if (this.state[`mediaInterval_${Number(data.userId)}`]) {
      clearInterval(this.state[`mediaInterval_${Number(data.userId)}`]);
    }

    activeWhiteboardOwnerSocketEmit(newOwner)
    // this.props.newActiveWhiteBoardOwnerState(newOwner);
  }

  /**
   * @param
   * userIdParam: user Id Eg: (1/2/3...)
   * checkAVParam: To check user permission for Eg: (audio/video)
   */
  checkMediaPermissionUserExist = (userIdParam, checkAVParam) => {
    const { whiteBoardReduxState: { activeWhiteBoardOwner: { mediaPermissions } } } = this.props;

    if (
      'activeWhiteBoardOwner' in this.props.whiteBoardReduxState &&
      'mediaPermissions' in this.props.whiteBoardReduxState.activeWhiteBoardOwner &&
      this.props.whiteBoardReduxState.activeWhiteBoardOwner.mediaPermissions.length > 0
    ) {
      let getAttendee = mediaPermissions.find(obj => Number(obj.userId) === Number(userIdParam))

      if (getAttendee) {
        return (
          Number(getAttendee.userId) === Number(userIdParam) &&
          getAttendee.userType === 'attendee' &&
          getAttendee[checkAVParam]
        )
      }
    }
    return false
  }

  render() {
    const { attendeeList, whiteBoardOwner, isLoading, isSessionOnHold } = this.state
    const { whiteBoardReduxState } = this.props
    return (
      <>

        {
          // Object.keys(whiteBoardOwner).length > 0 && <ul className="attendanceList attendanceList--forAll list-unstyled p-0">
          //   <li>
          //     <ul className="attendanceList__left list-inline">
          //       <li className="list-inline-item">{whiteBoardOwner.userType}</li>
          //     </ul>
          //     <div className="attendanceList__right">
          //       <p className="mb-0">{whiteBoardOwner.name}</p>
          //     </div>
          //   </li>
          // </ul>
        }

        <div className="searchForm" dir="rtl">
          <form className="position-relative">
            <div className="form-group mb-0">
              <input type="text" onKeyDown={(e) => {
                //e.preventDefault();
                if (e.keyCode === 13) {
                  this.loadAttendeeList();
                }
              }} onKeyUp={(e) => {
                this.setState({
                  searchKey: e.target.value
                }, () => {
                  this.loadAttendeeList();
                });
              }} className="form-control rounded-0 border-left-0 border-right-0" placeholder={config.langLabels.searchAttendee} />
              <button type="button" onClick={(e) => {
                e.preventDefault();
                this.loadAttendeeList();
              }} className="btn btn-light p-0 border-0"><i className="icon-search"></i></button>

            </div>
          </form>
        </div>

        {
          isLoading ? <div className="attendelistloader"><ListLoader /></div> : <>
            <div className="attendancelisting">
              <h3 className="text-right my-2">{config.langLabels.available} ({attendeeList.filter((item) => {
                return item.status === 'Joined'
              }).length})</h3>
              <ul className="attendanceList list-unstyled">
                {
                  attendeeList.filter((item) => {
                    return item.status === 'Joined'
                  }).map((userInfo, index) => {
                    return <li key={`present${index}`}>
                      <ul className="attendanceList__left list-inline">
                        {
                          !isSessionOnHold && <>
                            {
                              (
                                Number(whiteBoardReduxState.activeWhiteBoardOwner.userId) === Number(userInfo.attendeeId) &&
                                whiteBoardReduxState.activeWhiteBoardOwner.userType === 'attendee'
                              ) ? <li className="list-inline-item"><Link
                                to=""
                                onClick={(e) => {
                                  e.preventDefault()
                                  this.resetOwner()
                                }}
                                className={'active'}
                              >
                                <i className="icon-mouse-countrol"></i>
                              </Link></li> : <>
                                  <li className="list-inline-item"><Link
                                    to=""
                                    onClick={(e) => {
                                      e.preventDefault()
                                    }}
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    id={`dropdownMenuLinkBoard_list_board_${userInfo.attendeeId}`}
                                  >
                                    <i className="icon-mouse-countrol"></i>
                                  </Link>
                                    <div className="dropdown-menu" aria-labelledby={`dropdownMenuLinkBoard_list_board_${userInfo.attendeeId}`}>
                                      <Link
                                        className="dropdown-item"
                                        to=""
                                        onClick={(e) => {
                                          e.preventDefault()
                                          this.newBoardOwner(userInfo, 10)
                                        }}
                                      >{config.langLabels.minutesInTime(10)}</Link>
                                      <Link
                                        className="dropdown-item"
                                        to=""
                                        onClick={(e) => {
                                          e.preventDefault()
                                          this.newBoardOwner(userInfo, 5)
                                        }}
                                      >{config.langLabels.minutesInTime(5)}</Link>
                                      <Link
                                        className="dropdown-item"
                                        to=""
                                        onClick={(e) => {
                                          e.preventDefault()
                                          this.newBoardOwner(userInfo, 'open')
                                        }}
                                      >{config.langLabels.open}</Link>
                                    </div>
                                  </li>
                                </>
                            }
                            {
                              (
                                'mediaPermissions' in whiteBoardReduxState.activeWhiteBoardOwner &&
                                this.checkMediaPermissionUserExist(userInfo.attendeeId, 'video')
                              ) ? <li className="list-inline-item">
                                  <Link
                                    to=""
                                    onClick={(e) => {
                                      e.preventDefault()
                                      this.resetMediaPermissions({
                                        userId: Number(userInfo.attendeeId),
                                        userType: 'attendee'
                                      })
                                    }}
                                    className={'active'}
                                  >
                                    <i className="icon-videocam"></i>
                                  </Link>
                                </li> : ((this.checkMediaPermissionUserExist(userInfo.attendeeId, 'audio')) ?
                                  <li className="list-inline-item disabled">
                                    <Link
                                      to=""
                                      onClick={(e) => {
                                        e.preventDefault()
                                      }}
                                      className={'disabled'}
                                    >
                                      <i className="icon-videocam"></i>
                                    </Link>
                                  </li> : <li className="list-inline-item">
                                    <a
                                      href=""
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      id={`dropdownMenuLinkBoard_list_video_${userInfo.attendeeId}`}
                                    >
                                      <i className="icon-videocam"></i>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby={`dropdownMenuLinkBoard_list_video_${userInfo.attendeeId}`}>
                                      <Link
                                        className="dropdown-item"
                                        to=""
                                        onClick={(e) => {
                                          e.preventDefault()
                                          this.updateMediaPermissions(userInfo, 10, 'video')
                                        }}
                                      >{config.langLabels.minutesInTime(10)}</Link>
                                      <Link
                                        className="dropdown-item"
                                        to=""
                                        onClick={(e) => {
                                          e.preventDefault()
                                          this.updateMediaPermissions(userInfo, 5, 'video')
                                        }}
                                      >{config.langLabels.minutesInTime(5)}</Link>
                                      <Link
                                        className="dropdown-item"
                                        to=""
                                        onClick={(e) => {
                                          e.preventDefault()
                                          this.updateMediaPermissions(userInfo, 'open', 'video')
                                        }}
                                      >{config.langLabels.open}</Link>
                                    </div>
                                  </li>
                                )
                            }
                            {
                              (
                                'mediaPermissions' in whiteBoardReduxState.activeWhiteBoardOwner &&
                                this.checkMediaPermissionUserExist(userInfo.attendeeId, 'audio')
                              ) ? <li className="list-inline-item">
                                  <Link
                                    to=""
                                    onClick={(e) => {
                                      e.preventDefault()
                                      this.resetMediaPermissions({
                                        userId: Number(userInfo.attendeeId),
                                        userType: 'attendee'
                                      })
                                    }}
                                    className={'active'}
                                  >
                                    <i className="icon-voice"></i>
                                  </Link>
                                </li> : ((this.checkMediaPermissionUserExist(userInfo.attendeeId, 'video')) ?
                                  <li className="list-inline-item disabled">
                                    <Link
                                      to=""
                                      onClick={(e) => {
                                        e.preventDefault()
                                      }}
                                      className={'disabled'}
                                    >
                                      <i className="icon-voice"></i>
                                    </Link>
                                  </li> : <li className="list-inline-item">
                                    <a
                                      href=""
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      id={`dropdownMenuLinkBoard_list_audio_${userInfo.attendeeId}`}
                                    >
                                      <i className="icon-voice"></i>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby={`dropdownMenuLinkBoard_list_audio_${userInfo.attendeeId}`}>
                                      <Link
                                        className="dropdown-item"
                                        to=""
                                        onClick={(e) => {
                                          e.preventDefault()
                                          this.updateMediaPermissions(userInfo, 10, 'audio')
                                        }}
                                      >{config.langLabels.minutesInTime(10)}</Link>
                                      <Link
                                        className="dropdown-item"
                                        to=""
                                        onClick={(e) => {
                                          e.preventDefault()
                                          this.updateMediaPermissions(userInfo, 5, 'audio')
                                        }}
                                      >{config.langLabels.minutesInTime(5)}</Link>
                                      <Link
                                        className="dropdown-item"
                                        to=""
                                        onClick={(e) => {
                                          e.preventDefault()
                                          this.updateMediaPermissions(userInfo, 'open', 'audio')
                                        }}
                                      >{config.langLabels.open}</Link>
                                    </div>
                                  </li>
                                )
                            }
                          </>
                        }
                      </ul>
                      <div className="attendanceList__right">
                        <p className="mb-0">{userInfo.name}</p>
                      </div>
                    </li>
                  })
                }
              </ul>

              <h3 className="text-right mt-4">{config.langLabels.absent} ({attendeeList.filter((item) => {
                return item.status === 'Not_Attended'
              }).length})</h3>
              <ul className="attendanceList list-unstyled">
                {
                  attendeeList.filter((item) => {
                    return item.status === 'Not_Attended'
                  }).map((userInfo, index) => {
                    return <li className="justify-content-end" key={`absent${index}`}>
                      <div className="attendanceList__right">
                        <p className="mb-0">{userInfo.name}</p>
                      </div>
                    </li>
                  })
                }
              </ul>
            </div>
          </>
        }

      </>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps,
    whiteBoardReduxState: state.whiteBoardProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    newActiveWhiteBoardOwnerState: data => dispatch(newActiveWhiteBoardOwner(data)),
    updateOnlineAttendeeCountState: data => dispatch(updateOnlineAttendeeCount(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Attendance)