import React from 'react'
import AddModal from './addModal'
import AddPollModal from './addPollModal'
import AddVideoModal from './addVideoModal'
import AddWebsiteModal from './addWebsiteModal'
import LibraryModal from './libraryModal'
import AddDocumentModal from './addDocumentModal'
import AddPresentationModal from './addPresentationModal'
import Settings from './setting'

export default function Modals() {
  return (
    <>
      <AddModal />
      <AddPollModal />
      <AddVideoModal />
      <AddWebsiteModal />
      <LibraryModal />
      <AddDocumentModal />
      <AddPresentationModal />
      <Settings />
    </>
  )
}
