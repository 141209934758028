import React from 'react';
import YouTubePlayer from 'youtube-player';
import { YOUTUBE_REGEXP } from '../../utils/constants';
import { videoEventSocketEmit, videoEventSocketOn } from '../../socket';
import { connect } from 'react-redux';


class YoutubeIframe extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      player: '',
      done: false
    }
  }

  componentDidMount() {
    if (this.props.videoUrl) {
      this.initLoad()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.videoUrl !== this.props.videoUrl) {
      this.initLoad()
    }
  }

  initLoad = () => {
    let match = this.props.videoUrl.match(YOUTUBE_REGEXP);
    let userInfo = this.props.userReduxState;
    let player = YouTubePlayer(`video-player${this.props.boardId}`, {
      videoId: match[1],
      playerVars: {
        autoplay: 0,
        disablekb: 0,
        controls: (userInfo.userType === 'presenter') ? 1 : 0
      },
    });
    this.setState({
      player: player
    }, () => {
      const { userReduxState, whiteBoardReduxState } = this.props
      if (
        userReduxState.userId !== whiteBoardReduxState.activeWhiteBoardOwner.userId &&
        userReduxState.userType !== whiteBoardReduxState.activeWhiteBoardOwner.userType
      ) {
        videoEventSocketOn(this.playerAction);
      }
      this.state.player.on('stateChange', (event) => {
        let playerStatus = event.data;
        if (playerStatus === -1) {
          //  unstarted
        } else if (playerStatus === 0) {
          //   ended 
        } else if (playerStatus === 1) {
          this.playVideo();
          //   playing 
        } else if (playerStatus === 2) {
          this.pauseVideo();
          //  // paused 
        } else if (playerStatus === 3) {
          // buffering = purple
        } else if (playerStatus === 5) {
          //color = "#FF6DOO"; // video cued = orange
        }
      });

    })
  }

  playerAction = (data) => {
    if (data.boardId === this.props.boardId) {
      if (data.action === 'play') {
        this.state.player.seekTo(data.duration);
        this.state.player.playVideo();
      } else if (data.action === 'pause') {
        this.state.player.pauseVideo();
      }
    }
  }


  // 5. The API calls this function when the player's state changes.
  //    The function indicates that when playing a video (state=1),
  //    the player should play for six seconds and then stop.
  pauseVideo = () => {
    if (this.state.player) {
      this.state.player
        .pauseVideo()
        .then(() => {
          const { userReduxState, whiteBoardReduxState } = this.props
          if (
            userReduxState.userId === whiteBoardReduxState.activeWhiteBoardOwner.userId &&
            userReduxState.userType === whiteBoardReduxState.activeWhiteBoardOwner.userType
          ) {
            videoEventSocketEmit({ action: 'pause', duration: 0, boardId: this.props.boardId })
          }
          // Every function returns a promise that is resolved after the target function has been executed.
        });
    }
  }

  playVideo = () => {
    if (this.state.player) {
      let duration = 0;
      this.state.player.getCurrentTime().then((data) => {
        duration = data
      })

      this.state.player
        .playVideo()
        .then(() => {
          const { userReduxState, whiteBoardReduxState } = this.props
          if (
            userReduxState.userId === whiteBoardReduxState.activeWhiteBoardOwner.userId &&
            userReduxState.userType === whiteBoardReduxState.activeWhiteBoardOwner.userType
          ) {
            videoEventSocketEmit({ action: 'play', duration: duration, boardId: this.props.boardId });
          }

          // Every function returns a promise that is resolved after the target function has been executed.
        });
    }
  }

  render() {
    const { boardId, userReduxState, whiteBoardReduxState } = this.props
    return (
      <>
        <div>
          <div id={`video-player${boardId}`}></div>
          {
            (
              userReduxState.userId !== whiteBoardReduxState.activeWhiteBoardOwner.userId &&
              userReduxState.userType !== whiteBoardReduxState.activeWhiteBoardOwner.userType
            ) && <div className="youtube-video-hide"></div>
          }
        </div>
      </>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps,
    whiteBoardReduxState: state.whiteBoardProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(YoutubeIframe)