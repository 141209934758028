import React, { Component } from 'react'
import FormDocPresentation from './formDocPresentation'
import config from '../../config'

/**
 * add document modal
 */
export default class AddPresentationModal extends Component {
  render() {
    return (
      <div className="modal fade" id="addPresentationModal" tabIndex="-1" role="dialog" aria-labelledby="settingModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered w-570 add-video-modal" role="document">
          <div className="modal-content bg-grey">
            <div className="modal-header border-bottom-0 pb-0">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="icon-close"></i>
              </button>
              <h5 className="modal-title font-neomd">{config.langLabels.addPPT}</h5>
            </div>
            <div className="modal-body pt-2" dir="rtl">
              <p className="font-hrg title text-center">
                {config.langLabels.allowPptMsg}
              </p>
              <FormDocPresentation formType={'presentation'} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}