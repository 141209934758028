import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ChatTabs from './chatTabs';
import config from '../../config';

class Chat extends Component {

  state = {
    tabName: 'chat'
  }

  renderTabs(tabName) {
    this.setState({
      tabName: tabName
    })
  }

  render() {

    const { tabName } = this.state;
    const { chatReduxState: { requestCount, questionCount } } = this.props

    return (<div className="chatBox h-100">
      <ul className="chatBox__options list-inline d-flex justify-content-between mb-0">
        <li className="list-inline-item">
          <Link to=""
            onClick={(e) => {
              e.preventDefault();
              this.renderTabs('request');
            }}
            className={`${(tabName === 'request') ? 'active' : ''}`}><span className="badge badge-light">{requestCount}</span> {config.langLabels.requests}</Link>
        </li>
        <li className="list-inline-item">
          <Link to=""
            onClick={(e) => {
              e.preventDefault();
              this.renderTabs('question');
            }}
            className={`${(tabName === 'question') ? 'active' : ''}`}><span className="badge badge-light">{questionCount}</span> {config.langLabels.questions}</Link>
        </li>
        <li className="list-inline-item">
          <Link to=""
            onClick={(e) => {
              e.preventDefault();
              this.renderTabs('chat');
            }} className={`${(tabName === 'chat') ? 'active' : ''}`}>
            {config.langLabels.all}
          </Link>
        </li>
      </ul>
      {
        <ChatTabs tabName={tabName} />
      }
    </div>);
  }
}

let mapStateToProps = (state) => {
  return {
    chatReduxState: state.chatProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat)