import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { successToastr } from '../../utils/handelError';
import { updateUser } from '../../redux/actions/userAction';
import { connect } from 'react-redux';
import { extendTimeRequestSocketEmit, extendTimeSocketEmit } from '../../socket';
import { showAdminChat, scrollToBottomInList, hideBootstrapModal } from '../../utils/commonJsFunctions';
import SessionService from '../../services/sessionService';
import config from '../../config';

class ExtendTimeRequestModal extends Component {

  constructor(props) {
    super(props)

    this.state = {
      btnStatus: false,
      extendTime: 0
    }
  }

  extendTime = () => {
    let time = this.state.extendTime;
    if (time) {
      this.setState({
        btnStatus: true
      }, async () => {
        let userReduxState = this.props.userReduxState;
        userReduxState.sessionDuration = userReduxState.sessionDuration + this.state.extendTime
        userReduxState.extendedTime = userReduxState.extendedTime + time
        let updateBoardIdRes = await SessionService.updateActiveBoardId(userReduxState.sessionInfo.id, { sessionDuration: userReduxState.sessionDuration, extended_time: userReduxState.extendedTime })
        if (updateBoardIdRes.data.success) {
          this.props.updateUserState(userReduxState);
          this.setState({
            extendTime: 0,
            btnStatus: false
          }, () => {
            successToastr(config.langLabels.timeExtended);
            hideBootstrapModal('extendTimeRequestModal')
            extendTimeSocketEmit({ sessionDuration: userReduxState.sessionDuration });
          })
        }

        // this.setState({
        //   remainingTime: 20 - time,
        //   extendTime: 0,
        //   btnStatus: false
        // }, () => {
        //   hideBootstrapModal('extendTimeRequestModal')
        //   // extendTimeRequestSocketEmit({ duration: time });
        //   successToastr(config.langLabels.timeExtendRequested);
        // })
      })
    }
  }

  render() {
    const { extendTime } = this.state
    let remainingTime = (config.maxTimeExtendByPresenter - this.props.userReduxState.extendedTime)
    return (
      <div className="modal fade" id="extendTimeRequestModal" tabIndex="-1" role="dialog" aria-labelledby="extendTimeModal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered w-570 extendtime-modal" role="document">
          <div className="modal-content bg-grey">
            <div className="modal-header border-bottom-0 pb-0">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="icon-close"></i>
              </button>
              <h5 className="modal-title font-neomd">{config.langLabels.toolTipExtendTime}</h5>
            </div>
            <div className="modal-body text-center" dir="rtl">
              <form className="timeList mt-4">
                <label htmlFor="extendtime1" className={`${remainingTime < 5 ? 'disabled' : ''}`}>
                  <input
                    type="radio"
                    onChange={(e) => {
                      this.setState({
                        extendTime: 5
                      })
                    }}
                    name="time"
                    id="extendtime1"
                    className="d-none"
                    value={5}
                    checked={5 === extendTime ? true : false}
                  />
                  <span>{config.langLabels.timeInminutes(5)}</span>
                </label>

                <label htmlFor="extendtime2" className={`${remainingTime < 10 ? 'disabled' : ''}`}>
                  <input
                    type="radio"
                    onChange={(e) => {
                      this.setState({
                        extendTime: 10
                      })
                    }}
                    name="time"
                    id="extendtime2"
                    className="d-none"
                    value={10}
                    checked={10 === extendTime ? true : false}
                  />
                  <span>{config.langLabels.timeInminutes(10)}</span>
                </label>

                <label htmlFor="extendtime3" className={`${remainingTime < 15 ? 'disabled' : ''}`}>
                  <input
                    type="radio"
                    onChange={(e) => {
                      this.setState({
                        extendTime: 15
                      })
                    }}
                    name="time"
                    id="extendtime3"
                    className="d-none"
                    value={15}
                    checked={15 === extendTime ? true : false}
                  />
                  <span>{config.langLabels.timeInminutes(15)}</span>
                </label>

                <label htmlFor="extendtime4" className={`${remainingTime < 20 ? 'disabled' : ''}`}>
                  <input
                    type="radio"
                    onChange={(e) => {
                      this.setState({
                        extendTime: 20
                      })
                    }}
                    name="time"
                    id="extendtime4"
                    className="d-none"
                    value={20}
                    checked={20 === extendTime ? true : false}
                  />
                  <span>{config.langLabels.timeInminutes(20)}</span>
                </label>


                <Link
                  to=""
                  onClick={(e) => {
                    e.preventDefault();
                    showAdminChat();
                    scrollToBottomInList('chatList');
                    hideBootstrapModal('extendTimeRequestModal')
                  }}
                  className="messageLink d-inline-block font-hrg">
                  <i className="icon-chat"></i> <span>{config.langLabels.extTimeReqMsg}</span>
                </Link>

                {
                  remainingTime > 0 && <div className="btn-row">
                    <button type="button"
                      disabled={this.state.btnStatus}
                      onClick={(e) => {
                        this.extendTime()
                      }} className="btn btn-primary ripple-effect w_170">{config.langLabels.save}</button>
                  </div>
                }
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updateUserState: data => dispatch(updateUser(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtendTimeRequestModal)