import React, { Component } from 'react'
import { getBrowserDetails, measureConnectionSpeed, resetReactModalForm, onOpenReactModalForm } from '../../utils/commonJsFunctions'
import config from '../../config'

class TestConnection extends Component {

  constructor(props) {
    super(props)

    this.state = {
      selectedOpt: 'browser',
      showReTest: false,
      isBrowserCompatible: false,
      isCamMic: false,
      isDownloadSpeedIsGood: false,
      isUploadSpeedIsGood: false,
      isTested: false,
      browserDetails: getBrowserDetails() || {}
    }
  }

  componentDidMount() {
    this.initLoad()
    resetReactModalForm('settingModal', this.resetState)
    onOpenReactModalForm('settingModal', this.checkTest)
  }

  checkTest = () => {
    let isUserConnected = window.navigator.onLine
    let isDownloadSpeedIsGood = false;
    let isUploadSpeedIsGood = false;
    let isBrowserCompatible = false;

    if (isUserConnected) {
      isDownloadSpeedIsGood = true;
      isUploadSpeedIsGood = true;
    }
    if (
      Object.keys(this.state.browserDetails).length > 0 &&
      ['Chrome', 'Firefox'].includes(this.state.browserDetails.browserName) &&
      this.state.browserDetails.majorVersion > 70
    ) {
      isBrowserCompatible = true;
    }

    let newState = {
      isBrowserCompatible,
      isDownloadSpeedIsGood,
      isUploadSpeedIsGood,
      isTested: true,
    }

    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(
      (stream) => this.gotStream(stream, newState)
    ).then(this.gotDevices).catch(
      (error) => this.handleError(error, newState)
    );
  }

  gotStream = (stream, newState) => {
    let isCamMic = false;
    if (stream.getVideoTracks().length > 0 || stream.getAudioTracks().length > 0) {
      isCamMic = true;
    }
    setTimeout(() => {
      this.setState({
        ...newState,
        isCamMic
      })
    }, 1000 * 1);
  }

  gotDevices = (deviceInfos) => { }

  handleError = (error, newState) => {
    setTimeout(() => {
      this.setState({
        ...newState,
        isCamMic: false
      })
    }, 1000 * 1);
  }

  resetState = () => {
    this.setState({
      isBrowserCompatible: false,
      isDownloadSpeedIsGood: false,
      isUploadSpeedIsGood: false,
      isTested: false,
    })
  }

  initLoad = () => {
    this.checkTest()
    this.setState({
      selectedOpt: 'browser'
    })
  }

  viewOfSelectedOpt = () => {
    const { selectedOpt } = this.state
    let returnViewOfSelectedOpt = <></>
    switch (selectedOpt) {
      case 'browser':
        returnViewOfSelectedOpt = <BrowserDetails />
        break;

      case 'downloadSpeed':
        returnViewOfSelectedOpt = <></>
        break;

      case 'uploadSpeed':
        returnViewOfSelectedOpt = <></>
        break;

      case 'downloadUploadSpeed':
        returnViewOfSelectedOpt = <SpeedTest />
        break;

      default:
        break;
    }
    return returnViewOfSelectedOpt
  }

  handleOptChange = (selectedOpt) => {
    this.setState({
      selectedOpt
    })
  }

  render() {
    const {
      selectedOpt,
      showReTest,
      isBrowserCompatible,
      isCamMic,
      isDownloadSpeedIsGood,
      isUploadSpeedIsGood,
      isTested,
    } = this.state
    return (
      <div className="tab-pane fade show active" id="connection" role="tabpanel" aria-labelledby="connection-tab">
        <form>
          <div className="row align-items-center">
            <div className="col-6">
              {/* {this.viewOfSelectedOpt()} */}
              <div className="connectionDetail text-center">
                {
                  isTested ? <>
                    <i className="icon-check-circle"></i>
                    {/* <h5 className="font-neomd">Good connectivity</h5> */}
                    <h5 className="font-neomd">{config.langLabels.testCompleted}</h5>
                  </> : <div><img src="/assets/images/spinner.gif" /></div>
                }
              </div>
            </div>
            <div className="col-6">
              <ul className="connectionList list-unstyled p-0">
                <li>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadioInline1"
                      name="customRadioInline1"
                      className={`custom-control-input ${!isBrowserCompatible && isTested ? 'errorIcon' : ''}`}
                      // checked={selectedOpt === 'browser' ? true : false}
                      checked={isBrowserCompatible ? 'checked' : false}
                      value={'browser'}
                      disabled
                      onChange={(e) => {
                        this.handleOptChange('browser')
                      }}
                    />
                    <label htmlFor="customRadioInline1">
                      <h5>{config.langLabels.browser}</h5>
                      {/* <p className="mb-0">Description</p> */}
                    </label>
                  </div>
                </li>
                <li>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadioInline2"
                      name="customRadioInline2"
                      className={`custom-control-input ${!isCamMic && isTested ? 'errorIcon' : ''}`}
                      // checked={selectedOpt === 'browser' ? true : false}
                      checked={isCamMic ? 'checked' : false}
                      value={'browser'}
                      disabled
                      onChange={(e) => {
                        this.handleOptChange('camMic')
                      }}
                    />
                    <label htmlFor="customRadioInline1">
                      <h5>{config.langLabels.camMic}</h5>
                      {/* <p className="mb-0">Description</p> */}
                    </label>
                  </div>
                </li>
                <li>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadioInline3"
                      name="customRadioInline11"
                      className={`custom-control-input ${!isDownloadSpeedIsGood && isTested ? 'errorIcon' : ''}`}
                      // checked={selectedOpt === 'downloadSpeed' ? true : false}
                      checked={isDownloadSpeedIsGood ? 'checked' : false}
                      value={'downloadSpeed'}
                      disabled
                      onChange={(e) => {
                        this.handleOptChange('downloadSpeed')
                      }}
                    />
                    <label htmlFor="customRadioInline3">
                      <h5>{config.langLabels.downloadSpeed}</h5>
                      {/* <p className="mb-0">Description</p> */}
                    </label>
                  </div>
                </li>
                <li>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadioInline4"
                      name="customRadioInline111"
                      className={`custom-control-input ${!isUploadSpeedIsGood && isTested ? 'errorIcon' : ''}`}
                      // checked={selectedOpt === 'uploadSpeed' ? true : false}
                      checked={isUploadSpeedIsGood ? 'checked' : false}
                      value={'uploadSpeed'}
                      disabled
                      onChange={(e) => {
                        this.handleOptChange('uploadSpeed')
                      }}
                    />
                    <label htmlFor="customRadioInline4">
                      <h5>{config.langLabels.uploadspeed}</h5>
                      {/* <p className="mb-0">Description</p> */}
                    </label>
                  </div>
                </li>
                {/* <li>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadioInline4"
                      name="customRadioInline1"
                      className="custom-control-input"
                      checked={selectedOpt === 'uploadSpeed' ? true : false}
                      value={'uploadSpeed'}
                      onChange={(e) => {
                        this.handleOptChange('downloadUploadSpeed')
                      }}
                    />
                    <label htmlFor="customRadioInline4">
                      <h5>Download & Upload speed</h5>
                      <p className="mb-0">Description</p>
                    </label>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
          {
            // showReTest && <div className="text-center btn-row">
            //   <button type="button" className="btn btn-primary w_170 ripple-effect">Re Test</button>
            // </div>
          }
        </form>
      </div>
    )
  }
}


export default TestConnection


class BrowserDetails extends Component {

  constructor(props) {
    super(props)
    // Firefox
    this.state = {
      browserDetails: getBrowserDetails() || {}
    }
  }

  render() {

    const { browserDetails: {
      browserName,
      fullVersion,
      majorVersion
    }
    } = this.state

    if (Object.keys(this.state.browserDetails).length === 0) {
      return <div>
        Loading ...
      </div>
    }

    return (
      <>
        <p className="font-hrg">Browser: {browserName}</p>
        <p className="font-hrg">Full Version: {fullVersion}</p>
        <p className="font-hrg">Major Version: {majorVersion}</p>
      </>
    )
  }
}

class DownloadSpeed extends Component {

  componentDidMount() {
    this.initLoad()
  }

  initLoad = () => {
    // let speedRes = await measureConnectionSpeed()
    // let speedRes = window.InitiateSpeedDetection()
    window.InitiateSpeedDetection()
  }

  render() {
    return (
      <div id="downloadSpeedTest">
      </div>
    )
  }
}

class SpeedTest extends Component {

  render() {
    return (
      <div id="downloadSpeedTest">
        <iframe className="mx-auto my-0" frameBorder="0" src="https://www.bing.com/widget/t/speedtest"></iframe>
      </div>
    )
  }
}
