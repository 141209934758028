import { HttpClient } from "../utils/httpClient";

const PRESENTER_FOLDER_LIST = '/presenter-library/folder-list';
const DELETE_SINGLE_MULTI_FILE = '/presenter-library/file/delete-multiple';

let getFolderList = (data = {}) => HttpClient.getApi(`${PRESENTER_FOLDER_LIST}`, data);

let postFolderList = (data = {}) => HttpClient.postApi(`${PRESENTER_FOLDER_LIST}`, data);

let uploadFileInFolder = (folderId, data = {}) => HttpClient.postApi(`${PRESENTER_FOLDER_LIST}/${folderId}/upload-file`, data);

let deleteSingleFolder = (folderId) => HttpClient.deleteApi(`${PRESENTER_FOLDER_LIST}/${folderId}`);

let updateFolderName = (folderId, data = {}) => HttpClient.putApi(`${PRESENTER_FOLDER_LIST}/${folderId}`, data);

let updateFileName = (folderId, data = {}) => HttpClient.putApi(`${PRESENTER_FOLDER_LIST}/rename-file/${folderId}`, data);

let deleteSingleMultiFiles = (data) => HttpClient.deleteApi(`${DELETE_SINGLE_MULTI_FILE}`, data);

const FolderService = {
  getFolderList,
  postFolderList,
  uploadFileInFolder,
  deleteSingleFolder,
  deleteSingleMultiFiles,
  updateFolderName,
  updateFileName
};

export default FolderService;