import React, { Component } from 'react'
import { connect } from 'react-redux';
import SessionService from '../../services/sessionService';
import ListLoader from '../loaders/listLoader';
import { newRequestSocketOn } from '../../socket';
import { updateRequestCount } from '../../redux/actions/chatAction';
import RequestBox from './requestBox';
import { scrollToBottomInList } from '../../utils/commonJsFunctions';


class RequestList extends Component {

  state = {
    isLoading: true,
    requestList: []
  }

  componentDidMount() {
    this.loadRequestList();
    let userInfo = this.props.userReduxState;
    if (userInfo.userType === 'presenter') {
      newRequestSocketOn(this.appendRequestList);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userReduxState !== this.props.userReduxState) {
      this.loadRequestList();
    }
  }

  appendRequestList = (data) => {
    const { chatReduxState: { requestCount } } = this.props
    let requestList = this.state.requestList
    requestList.push({ ...data, requestInfo: data })
    this.setState({
      requestList
    }, () => {
      scrollToBottomInList('listingWrapper')
      this.props.updateRequestCountInRedux(Number(requestCount) + 1)
    })
  }

  loadRequestList = async () => {
    let userInfo = this.props.userReduxState;
    let requestList = await SessionService.sessionAttendeeRequestList(userInfo.sessionInfo.id);
    if (requestList.data.success) {
      this.setState({
        requestList: requestList.data.data
      }, () => {
        scrollToBottomInList('listingWrapper')
        setTimeout(() => {
          this.setState({
            isLoading: false
          })
        }, 1000 * 2);
        let requestCount = this.state.requestList.filter(obj => (obj.status === 'Pending')).length
        this.props.updateRequestCountInRedux(requestCount)
      })
    }
  }


  render() {
    let { requestList, isLoading } = this.state
    return (
      <>
        {
          isLoading && <ListLoader />
        }
        <div className={`listingWrapper requestList ${isLoading ? 'loaderHeight' : ''}`}>
          <>
            <ul className="list-unstyled chatBox__listing text-right mb-0" id="requestsChat">
              {
                requestList.map((request, index) => {
                  return <li key={index}>
                    <RequestBox request={request} chat={request.requestInfo} index={index} loadRequestList={this.loadRequestList} />
                  </li>
                })
              }
            </ul>
          </>
        </div>
      </>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    chatReduxState: state.chatProps,
    userReduxState: state.userProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updateRequestCountInRedux: (data) => dispatch(updateRequestCount(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestList)