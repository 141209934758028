export const TWITTER_REGEXP = /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)$/; // https://stackoverflow.com/a/4138539
export const YOUTUBE_REGEXP = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/; // https://regex101.com/r/rN1qR5/2
export const FACEBOOK_REGEXP = /(?:https?:\/\/)?(?:www.|web.|m.)?facebook.com\/(?:video.php\?v=\d+|photo.php\?v=\d+|\?v=\d+)|\S+\/videos\/((\S+)\/(\d+)|(\d+))\/?/; // https://www.regextester.com/96820
export const URL_REGEXP = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/; // https://www.regextester.com/94502
export const ALLOW_IMAGES = ['png', 'jpg', 'jpeg']; //'gif', 
export const ALLOW_WORD = ['doc', 'docx', 'odt'];
export const ALLOW_PDF = ['pdf'];
export const ALLOW_EXCEL = ['xls', 'xlsx', 'ods'];
export const ALLOW_PRESENTATION = ['ppt', 'pptx', 'odp'];
export const ALLOW_TEXT = ['txt'];
export const ALLOW_DOC = ['doc', 'docx', 'odt', 'pdf', 'xls', 'xlsx', 'ods', 'ppt', 'pptx', 'odp', 'txt'];
export const ALLOW_ALL = ['png', 'jpg', 'jpeg', 'doc', 'docx', 'odt', 'pdf', 'xls', 'xlsx', 'ods', 'ppt', 'pptx', 'odp', 'txt']; //'gif',
export const ACCEPT_IMAGES_TYPE = ".png, .jpg, .jpeg"; //.gif,
export const ACCEPT_WORD = ".doc, .docx, .odt";
export const ACCEPT_PDF = ".pdf";
export const ACCEPT_EXCEL = ".xls, .xlsx, .ods";
export const ACCEPT_PRESENTATION = ".ppt, .pptx, .odp";
export const ACCEPT_TEXT = ".txt";
export const ACCEPT_DOC_TYPE = ".doc, .docx, .odt, .pdf, .xls, .xlsx, .ods, .ppt, .pptx, .odp, .txt";
export const ACCEPT_ALL_TYPE = ".png, .jpg, .jpeg, .doc, .docx, .odt, .pdf, .xls, .xlsx, .ods, .ppt, .pptx, .odp, .txt"; //.gif,
export const MAX_FILE_SIZE = 1024 * 1024 * 15 // In MB