import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Countdown, { zeroPad } from 'react-countdown-now';
import config from '../../config';

class AudioGrantedView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      allowDuration: Date.now()
    }
  }

  renderer = ({ hours, minutes, seconds, completed }) => {
    let returnHTML = <></>

    if (completed) {
      returnHTML = <></>;
      // Render a complete state
    } else {
      // Render a countdown      
      returnHTML = <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    }
    return returnHTML
  };

  rendererProgress = ({ hours, minutes, seconds, completed }) => {
    let completePercentage = 0

    if (completed) {
      // Render a complete state
    } else {
      // Render a countdown
      completePercentage = ((1000 * (60 * Number(minutes) + seconds)) / (1000 * 60 * Number(this.props.data.audioTimeLimit))) * 100
    }
    return <div className="progress-bar" role="progressbar" style={{ width: `${completePercentage}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
  };

  componentDidMount() {
    if (this.props.data.nowDate) {
      this.setState({
        allowDuration: this.props.data.nowDate
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loadingState !== this.props.loadingState) {
      this.setState({
        isLoading: this.props.loadingState === 'connected' ? false : true
      })
    }
  }

  render() {
    const { data, key, resetMediaPermissionsByStopButton, muteAudioToggle, userReduxState: { userId, userType }, audioEnabled } = this.props
    const { isLoading } = this.state
    return (
      <div className="requestTime" key={key}>
        <ul className="list-inline d-flex align-items-center mb-0 pr-0">
          {
            (Number(data.userId) === Number(userId) && data.userType === userType) && <li>
              <Link to="" onClick={(e) => {
                e.preventDefault();
                muteAudioToggle()
              }} >
                <i className={(audioEnabled) ? "icon-volume" : "icon-volume-off"}></i>
              </Link>
            </li>
          }
          {
            (userType !== "attendee") && <li>
              <Link to="" onClick={(e) => {
                e.preventDefault();
                resetMediaPermissionsByStopButton({
                  userId: Number(data.userId),
                  userType: 'attendee'
                })
              }} >
                <i className={"icon-stop"}></i>
              </Link>
            </li>
          }
          <li className="ml-auto text-right">
            <span className="">{data.name}</span>
            <span>{(isLoading) ? 'Loading...' : ''}</span>
            {
              data.audioTimeLimit !== 'open' ? <h4 className="requestTime__count font-neomd mb-0">
                <Countdown
                  date={this.state.allowDuration + (1000 * 60 * Number(data.audioTimeLimit))}
                  renderer={this.renderer}
                />
              </h4> : <h4 className="requestTime__count font-neomd mb-0">{config.langLabels.open}</h4>
            }
          </li>
        </ul>
        {
          data.audioTimeLimit !== 'open' && <div className="progress" dir="rtl">
            <Countdown
              date={this.state.allowDuration + (1000 * 60 * Number(data.audioTimeLimit))}
              renderer={this.rendererProgress}
            />
          </div>
        }
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    whiteBoardReduxState: state.whiteBoardProps,
    userReduxState: state.userProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioGrantedView)