import React, { Component } from 'react'
import { connect } from 'react-redux';
import kurentoUtils from "kurento-utils";
import { receiveScreenOfferSocketOn, iceCandidateScreenSocketOn, sendPresenterScreen, screenShareStartedSocketEmit, screenShareStopedSocketEmit, disConnectSocketOn } from '../../socket';
import { updateRecordingOn, updateStartScreenShare } from '../../redux/actions/whiteBoardAction';
import config from '../../config';

var webRtcPeer;

function onMessageReceived(message, webRtcPeer) {
  var parsedMessage = JSON.parse(message);
  switch (parsedMessage.id) {
    case 'presenterResponse':
      presenterResponse(parsedMessage, webRtcPeer);
      break;
    case 'viewerResponse':
      viewerResponse(parsedMessage, webRtcPeer);
      break;
    case 'stopCommunication':
      dispose();
      break;
    case 'iceCandidate':
      webRtcPeer.addIceCandidate(parsedMessage.candidate)
      break;
    default:
      console.error('Unrecognized message', parsedMessage);
  }
}

function presenterResponse(message, webRtcPeer) {
  if (message.response != 'accepted') {
    var errorMsg = message.message ? message.message : 'Unknow error';
    console.warn('Call not accepted for the following reason: ' + errorMsg);
    dispose();
  } else {
    webRtcPeer.processAnswer(message.sdpAnswer);
  }
}

function dispose() {
  if (webRtcPeer) {
    webRtcPeer.dispose();
    webRtcPeer = null;
  }
}

function viewerResponse(message) {
  if (message.response != 'accepted') {
    var errorMsg = message.message ? message.message : 'Unknow error';
    console.warn('Call not accepted for the following reason: ' + errorMsg);
    dispose();
  } else {
    webRtcPeer.processAnswer(message.sdpAnswer);
  }
}

function sendMessage(message) {
  var jsonMessage = JSON.stringify(message);
  sendPresenterScreen(jsonMessage);
}

function onError(error) {
  console.error(error);
}

function getDisplayMediaError(error) {
  console.error(error);
}


class ScreenRecording extends Component {

  state = {
    videoScreen: false,
    webRtcPeer: '',
    audioEnabled: true,
    videoEnabled: true,
    stream: null
  }

  componentDidMount() {
    // disConnectSocketOn();
    this.shareScreen();

    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  shareScreen = () => {
    if (navigator.mediaDevices.getDisplayMedia) {
      navigator.getUserMedia = navigator.mozGetUserMedia || navigator.webkitGetUserMedia;
      navigator.mediaDevices.getDisplayMedia({ video: true, audio: false }).then(stream => {

        this.setState({
          stream
        }, () => {
          this.initiateScreenSharing(stream);
          this.props.updateRecordingOnState(true)
        })
        stream.getVideoTracks()[0].onended = () => {
          dispose();
          this.updateStopSharingRedux()
        }
      }, getDisplayMediaError).catch(getDisplayMediaError);
    }
  }

  /**
   * Helpful links
   * https://github.com/kriasoft/react-starter-kit/issues/909#issuecomment-252969542
   * https://stackoverflow.com/a/34232071
   */

  stopScreenSharing = () => {
    let streamToStop = this.state.stream
    let getVideoTracks = streamToStop.getVideoTracks()
    if (getVideoTracks) {
      getVideoTracks[0].stop()
    }
    this.setState({
      stream: null
    }, () => {
      dispose();
      this.updateStopSharingRedux()
    })
  }

  updateStopSharingRedux = () => {
    screenShareStopedSocketEmit()
    this.props.updateRecordingOnState(false)
    this.props.updateStartScreenShareState(false)
  }

  initiateScreenSharing = (stream) => {
    let that = this
    if (!webRtcPeer) {
      var options = {
        videoStream: stream,
        onicecandidate: this.onIceCandidate,
        configuration: config.ICE_SERVER_CONFIG
      };
      webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options, function (error) {
        if (error) return onError(error);

        this.generateOffer(that.onOfferPresenter);
      });
      this.setState({
        webRtcPeer
      });
      receiveScreenOfferSocketOn(onMessageReceived, webRtcPeer);
      iceCandidateScreenSocketOn(onMessageReceived, webRtcPeer);
    }
  }

  onIceCandidate = (candidate) => {
    let userInfo = this.props.userReduxState;
    var message = {
      id: 'onIceCandidate',
      candidate: candidate,
      userId: Number(userInfo.userId),
      userType: userInfo.userType
    }
    sendMessage(message);
  }

  onOfferPresenter = (error, offerSdp) => {
    let userInfo = this.props.userReduxState;
    if (error) return onError(error);

    var message = {
      id: 'presenter',
      sdpOffer: offerSdp,
      userId: Number(userInfo.userId),
      userType: userInfo.userType
    };
    sendMessage(message);
  }

  render() {
    return (
      <>
      </>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps,
    whiteBoardReduxState: state.whiteBoardProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updateRecordingOnState: data => dispatch(updateRecordingOn(data)),
    updateStartScreenShareState: data => dispatch(updateStartScreenShare(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenRecording)