import { HttpClient } from "../utils/httpClient";

const CREATE_POLL = '/poll';
let createPoll = (data = {}) => HttpClient.postApi(`${CREATE_POLL}`, data);

const POLL_ANSWER = '/submit-poll-answer';
let submitPollAnswer = (data = {}) => HttpClient.postApi(`${POLL_ANSWER}`, data);

const POLL_RESULT = '/publish-poll-result/';
let displayResult = (pollId, data = {}) => HttpClient.putApi(`${POLL_RESULT}${pollId}`, data);


const PollService = {
  createPoll,
  submitPollAnswer,
  displayResult
};

export default PollService;