import React, { Component } from 'react'
import { connect } from 'react-redux';
import { toolTypeData } from '../../utils/toolData';
import { updateToolType } from '../../redux/actions/toolTypeAction';
import { resetReactModalForm, hideBootstrapModal } from '../../utils/commonJsFunctions';
import { validateSocialMediaURL, generateSocialMediaIFrameURL, getSiteTypeURL } from '../../utils/customValidator';
import { iframeSocketEmit, addWhiteboardSocketEmit } from '../../socket';
import { nowTimestamp } from '../../utils/customTimeFunctions';
import SessionService from '../../services/sessionService';
import { addWhiteBoard } from '../../redux/actions/whiteBoardAction';
import { YOUTUBE_REGEXP, FACEBOOK_REGEXP } from '../../utils/constants';
import $ from 'jquery';
import commonConfig from '../../config/common';
import ButtonLoader from '../loaders/buttonLoader';
import { errorToastr } from '../../utils/handelError';
import Axios from 'axios';
import config from '../../config';
/**
 * add video modal
 */
class AddVideoModal extends Component {

  constructor(props) {
    super(props)

    this.state = {
      urlVideo: '',
      imgUrl: '',
      title: '',
      description: '',
      btnLoader: false
    }
  }

  componentDidMount() {
    resetReactModalForm('addVideosModal', this.setVideoURL)
  }

  updateToolTypeInRedux = async (tool = '', toolOptions = {}) => {
    let result = await this.addNewBoard(toolOptions.url_video);
    if (result) {
      let data = toolTypeData(tool, toolOptions, this.props.toolTypeReduxState)
      this.props.updateToolTypeState(data)
      this.setVideoURL()
      iframeSocketEmit({ tool: tool, toolOptions: toolOptions });
    }
    hideBootstrapModal('addVideosModal');
  }

  addNewBoard = async (title) => {
    const { userReduxState: { sessionInfo: { id } }, whiteBoardReduxState: { whiteBoards } } = this.props
    if (whiteBoards.length < 10) {
      this.setState({
        btnLoader: true
      })
      let boardId = nowTimestamp();
      let boardType = getSiteTypeURL(title)
      let payload = {
        boardId: boardId,
        title: title,
        boardType: boardType,
        frameData: title
      }
      let resAddNewBoard = await SessionService.addNewBoard(id, payload)
      if (resAddNewBoard.data.success) {
        this.setState({
          btnLoader: false
        })
        this.props.addWhiteBoardInRedux(payload)
        addWhiteboardSocketEmit(payload);
        return true;
      }
    } else {
      errorToastr(config.langLabels.boardAddLimitExceed)
    }
    return false;
  }


  loadInfo = (videoId, setVideoDetails) => {
    $.getJSON(`https://www.googleapis.com/youtube/v3/videos?key=${commonConfig.youtubeKey}&part=snippet&id=${videoId}`, (data) => {
      setVideoDetails({
        imgUrl: data.items[0].snippet.thumbnails.default.url,
        title: data.items[0].snippet.localized.title,
        description: data.items[0].snippet.localized.description,
      })
    });
  };

  loadFbVideoInfo = async (videoId) => {
    return {
      imgUrl: `/assets/images/NO_IMG_600x600.png`,
      title: '',
      description: '',
    }
    // try {
    //   let fbThumbnailRes = await Axios.get(`https://graph.facebook.com/v5.0/${videoId}?fields=id%2Cdescription%2Cthumbnails&access_token=${config.facebookAccessToken}`)
    //   return {
    //     imgUrl: fbThumbnailRes.data.thumbnails.data[0].uri,
    //     title: fbThumbnailRes.data.description,
    //     description: '',
    //   }
    // } catch (error) {
    //   return {
    //     imgUrl: `/assets/images/NO_IMG_600x600.png`,
    //     title: '',
    //     description: '',
    //   }
    // }
  };



  setVideoDetails = (res) => {
    this.setState(res)
  }

  setVideoURL = async (urlVideo = '') => {
    let title = '';
    let description = '';
    let imgUrl = '';
    if (urlVideo) {
      let key = getSiteTypeURL(urlVideo);
      if (key === 'youtube') {
        let match = urlVideo.match(YOUTUBE_REGEXP);
        this.loadInfo(match[1], this.setVideoDetails);
      } else if (key === 'facebook') {
        let match = urlVideo.match(FACEBOOK_REGEXP);
        let videoId = match.length ? match[1] : false
        let fbData = await this.loadFbVideoInfo(videoId)
        imgUrl = fbData.imgUrl;
      }
    }
    this.setState({
      urlVideo,
      title,
      description,
      imgUrl
    })
  }

  render() {
    const { urlVideo, title, description, imgUrl, btnLoader } = this.state
    return (
      <div className="modal fade" id="addVideosModal" tabIndex="-1" role="dialog" aria-labelledby="settingModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered w-570 add-video-modal" role="document">
          <div className="modal-content bg-grey">
            <div className="modal-header border-bottom-0 pb-0">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="icon-close"></i>
              </button>
              <h5 className="modal-title font-neomd">{config.langLabels.addVideo}</h5>
            </div>
            <div className="modal-body pt-2" dir="rtl">
              <p className="font-hrg title text-center">
                {config.langLabels.allowedVideosFrom}
              </p>
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={config.langLabels.videoLink}
                    value={urlVideo}
                    dir="ltr"
                    onChange={(e) => {
                      this.setVideoURL(e.target.value)
                    }}
                  />
                  <button value="Refresh" type="button" className="valueRefresh p-0 btn btn-light bg-transparent border-0">
                    <i className="icon-cancel"></i>
                  </button>
                  {
                    (urlVideo.length > 0 && !validateSocialMediaURL(urlVideo)) && <span className="text-danger">{config.langLabels.errMsgValidVideoURL}</span>
                  }
                </div>

                {
                  (urlVideo.length > 0 && validateSocialMediaURL(urlVideo)) && <div className="previewVideo d-flex">
                    <div className="previewVideo__videobox">
                      <img title="preview-frame" src={imgUrl} className='previewVideo__videobox' />
                    </div>
                    <div className="previewVideo__videodetail text-right">
                      <h3 className="font-neomd">{title}</h3>
                      <p className="font-hrg">{description.substr(0, description.lastIndexOf(' ', 100)) + '...'}</p>
                      <a
                        href="/"
                        className="font-hrg link d-block"
                        onClick={(e) => {
                          e.preventDefault()
                        }}
                      >
                        {urlVideo}
                      </a>
                    </div>
                  </div>
                }

                <div className="text-center btn-row">
                  <button
                    className="btn btn-dark ripple-effect w_170 ml-2"
                    type="button"
                    data-target="#addModal"
                    data-toggle="modal"
                    onClick={(e) => {
                      e.preventDefault()
                      hideBootstrapModal('addVideosModal')
                    }}
                  >
                    {config.langLabels.back}
                  </button>
                  <button
                    disabled={btnLoader}
                    className="btn btn-primary ripple-effect w_170"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      if (validateSocialMediaURL(urlVideo)) {
                        this.updateToolTypeInRedux('video', { url_video: urlVideo })
                      }
                    }}
                  >
                    {config.langLabels.addVideo} {
                      btnLoader && <ButtonLoader />
                    }
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    toolTypeReduxState: state.toolTypeProps,
    whiteBoardReduxState: state.whiteBoardProps,
    userReduxState: state.userProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updateToolTypeState: data => dispatch(updateToolType(data)),
    addWhiteBoardInRedux: (data) => dispatch(addWhiteBoard(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddVideoModal)