import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import parser from 'react-html-parser';
import { getFileIconByType } from '../../utils/customValidator';
import ChatService from '../../services/chatService';
import { updateQuestionCount } from '../../redux/actions/chatAction';
import RequestBox from '../requestList/requestBox';

class ChatMessage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isStared: false
    }
  }

  componentDidMount() {
    this.initLoad()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.chat !== this.props.chat) {
      this.initLoad()
    }
  }

  initLoad = () => {
    const { chat } = this.props
    this.setState({
      isStared: chat.isMarked
    })
  }

  updateIsStared = async (isStared, chatId) => {
    const { chatReduxState: { questionCount } } = this.props

    isStared = isStared ? 'yes' : 'no'
    this.setState({
      isStared: isStared
    })

    if (isStared === 'yes') {
      this.props.updateQuestionCountInRedux(Number(questionCount) + 1)
    } else {
      this.props.updateQuestionCountInRedux(Number(questionCount) - 1)
    }
    await ChatService.putChat(chatId, { isMarked: isStared })
  }

  render() {
    const { chat, index, addChatReply, userReduxState } = this.props
    const { isStared } = this.state
    return (
      <>
        {
          ['text', 'file'].includes(chat.messageType) &&

          <div
            id={`div-${index}`}
            className={`chatBox__listing__msgBox ${isStared === 'yes' ? 'chatBox__listing__msgBox--favorite' : ''} ${('parentMessage' in chat && chat.parentMessage !== null && Object.keys(chat.parentMessage).length > 0) ? 'chatBox__listing__msgBox--Creply' : ''} ${chat.messageType === 'file' ? 'fileBox' : ''}`}>
            {
              (userReduxState.userType !== chat.userType ? userReduxState.userType !== chat.userType : userReduxState.userId !== chat.userId) && <ul className="chatBox__listing__msgBox__share list-unstyled">
                {
                  !['attendee'].includes(userReduxState.userType) && <li>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" id={`star_${index}`} className="checked" value={isStared} checked={isStared === 'yes' ? true : false} onChange={(e) => this.updateIsStared(e.target.checked, chat.id)} />
                        <span className="checkbox-icon-wrapper">
                          <span className="checkbox-icon icon-star-outline"></span>
                        </span>
                      </label>
                    </div>
                  </li>
                }
                {
                  (chat.messageType === 'text' && chat.parentMessage === null) && <li>
                    <Link
                      to=""
                      onClick={(e) => {
                        e.preventDefault()
                        addChatReply(chat)
                      }}
                      className="d-flex justify-content-center align-items-center"
                    ><span className="icon-reply"></span></Link>
                  </li>
                }
              </ul>
            }
            {
              chat.messageType === 'text' && (
                ('parentMessage' in chat && chat.parentMessage !== null && Object.keys(chat.parentMessage).length > 0) ? (
                  <div>
                    <span>{chat.parentMessage.fromName}</span>
                    <div className="mb-0">
                      {parser(chat.parentMessage.message)}
                    </div>
                  </div>
                ) : (
                    <div>
                      <span>{chat.fromName}</span>
                      <div className="mb-0">
                        {parser(chat.message)}
                      </div>
                    </div>
                  )
              )
            }
            {
              chat.messageType === 'file' && (
                <div className="documentBox d-flex align-items-center justify-content-between">
                  <div className="documentBox__left">
                    <a href={chat.mediaUrl} target="_blank" download>
                      <img src={`/assets/images/download.svg`} alt="download icon" className="img-fluid" />
                    </a>
                  </div>
                  <div className="documentBox__right d-flex align-items-center">
                    <div className="filename">
                      <span>{chat.fromName}</span>
                      {/* <span className="d-block">number of pages 4</span> */}
                    </div>
                    <img src={`/assets/images/${getFileIconByType(chat.mediaUrl)}`} className="img-fluid" alt="icon" />
                  </div>
                </div>
              )
            }
          </div>
        }
        {
          (
            chat.messageType === 'text' &&
            'parentMessage' in chat &&
            chat.parentMessage !== null &&
            Object.keys(chat.parentMessage).length > 0
          ) && (
            <div className="replyBox ml-0">
              <div className="replyBox__cnt">
                {parser(chat.message)}
              </div>
            </div>
          )
        }
        {
          (userReduxState.userType && userReduxState.userType === 'presenter' && chat.messageType === 'request') && <RequestBox request={chat.requestInfo} chat={chat} />
        }
      </>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    chatReduxState: state.chatProps,
    userReduxState: state.userProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updateQuestionCountInRedux: (data) => dispatch(updateQuestionCount(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatMessage)