import React from 'react';
import { Link } from 'react-router-dom';
import FacebookPlayer from 'react-facebook-player';
import { FACEBOOK_REGEXP } from '../../utils/constants';
import { videoEventSocketEmit, videoEventSocketOn } from '../../socket';
import config from '../../config';
import { connect } from 'react-redux';

class FacebookIframe extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      player: null,
      isPlay: true,
      isMuted: true
    };
  }

  componentDidMount() {
    if (this.props.videoUrl) {
      this.initLoad()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.videoUrl !== this.props.videoUrl) {
      this.initLoad()
    }
  }

  initLoad = () => {
    let userInfo = this.props.userReduxState;
    if (userInfo.userType !== 'presenter') {
      videoEventSocketOn(this.playerAction);
    }
  }

  playerAction = (data) => {
    if (data.action === 'play') {
      this.state.player.seek(Number(data.duration));
      this.setState({
        isPlay: false
      }, () => {
        this.state.player.play();
      })
      // this.state.player.unmute();
    } else if (data.action === 'pause') {
      this.setState({
        isPlay: true
      }, () => {
        this.state.player.pause();
      })
    } else if (data.action === 'mute') {
      // this.state.player.mute()
    } else if (data.action === 'unmute') {
      // this.state.player.unmute()
      // this.state.player.play()
    }
  }

  onReady = (id, player) => {
    this.setState({
      player: player
    });
  }

  playVideo = () => {
    const { player } = this.state;
    if (this.state.isPlay) {
      this.setState({
        isPlay: false
      }, () => {
        if (player) {
          player.play();
          videoEventSocketEmit({ action: 'play', duration: player.getCurrentPosition(), boardId: this.props.boardId })
        }
      })
    }
  }

  pauseVideo = () => {
    const { player } = this.state;
    if (player) {
      player.pause();
      videoEventSocketEmit({ action: 'pause', duration: 0, boardId: this.props.boardId });
      this.setState({
        isPlay: true
      });
    }
  }

  onFinishedPlaying = () => {
    const { player } = this.state;
    if (player) {
      this.state.player.mute();
      player.pause();
      this.setState({
        isPlay: true,
        isMuted: true
      });
    }
  }

  toggleMute = () => {
    this.setState(prevState => {
      return {
        isMuted: !prevState.isMuted
      }
    }, () => {
      if (this.state.isMuted) {
        this.state.player.mute()
        videoEventSocketEmit({ action: 'mute', duration: 0, boardId: this.props.boardId });
      } else {
        this.state.player.unmute()
        videoEventSocketEmit({ action: 'unmute', duration: 0, boardId: this.props.boardId });
      }
    })
  }

  render() {
    let match = this.props.videoUrl.match(FACEBOOK_REGEXP);
    let videoId = match.length ? match[1] : false
    let userInfo = this.props.userReduxState;
    const { userReduxState, whiteBoardReduxState, boardId } = this.props
    const { isMuted, isPlay } = this.state
    const fbPlayerHeight = Math.min((document.getElementById("whiteBoard").offsetWidth - 35), (document.getElementById("whiteBoard").offsetHeight - 35))
    return (
      <>
        <div className="fb-video text-center">
          {
            // (userInfo.userType === 'presenter') ?
            //   <FacebookPlayer
            //     appId={config.facebookAppId}
            //     videoId={`${videoId}`}
            //     id={`fb-videoId${this.props.boardId}`}
            //     allowfullscreen={false}
            //     autoplay={false}
            //     onReady={this.onReady}
            //     onStartedPlaying={this.playVideo}
            //     onPaused={this.pauseVideo}
            //   /> : <FacebookPlayer
            //     appId={config.facebookAppId}
            //     videoId={`${videoId}`}
            //     id={`fb-videoId${this.props.boardId}`}
            //     allowfullscreen={false}
            //     autoplay={false}
            //     onReady={this.onReady}
            //   />
            (
              userReduxState.userId !== whiteBoardReduxState.activeWhiteBoardOwner.userId &&
              userReduxState.userType !== whiteBoardReduxState.activeWhiteBoardOwner.userType
            ) ? <FacebookPlayer
                appId={config.facebookAppId}
                videoId={`${videoId}`}
                id={`fb-videoId${this.props.boardId}`}
                allowfullscreen={false}
                autoplay={Number(boardId) === Number(whiteBoardReduxState.activeWhiteBoardId) ? true : false}
                onReady={this.onReady}
                onFinishedPlaying={this.onFinishedPlaying}
                width={fbPlayerHeight}
                data-controls="false"
              /> : <FacebookPlayer
                appId={config.facebookAppId}
                videoId={`${videoId}`}
                id={`fb-videoId${this.props.boardId}`}
                allowfullscreen={false}
                autoplay={Number(boardId) === Number(whiteBoardReduxState.activeWhiteBoardId) ? true : false}
                onReady={this.onReady}
                onStartedPlaying={this.playVideo}
                onPaused={this.pauseVideo}
                onFinishedPlaying={this.onFinishedPlaying}
                width={fbPlayerHeight}
              />
          }
          {
            (
              userReduxState.userId !== whiteBoardReduxState.activeWhiteBoardOwner.userId &&
              userReduxState.userType !== whiteBoardReduxState.activeWhiteBoardOwner.userType
            ) && <div className="fb-video-hide"></div>
          }
          {
            // !isPlay && <Link
            //   className="videoView__videoIcon rounded-circle text-center fb-speaker"
            //   to=""
            //   onClick={(e) => {
            //     e.preventDefault();
            //     this.toggleMute()
            //   }}>
            //   <i className={`${isMuted ? "icon-volume-off" : "icon-volume"}`}></i>
            // </Link>
          }
        </div>
      </>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps,
    whiteBoardReduxState: state.whiteBoardProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacebookIframe)