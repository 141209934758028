import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Chat from '../../containers/chat';
import Attendance from '../../containers/attendance';
import SessionService from '../../services/sessionService';
import { errorToastr } from '../../utils/handelError';
import { connect } from 'react-redux';
import config from '../../config';

class PresenterSideBar extends Component {

  constructor(props) {
    super(props)

    this.state = {
      tabName: 'attendance',
      attendeeList: []
    }
  }

  componentDidMount() {
    // this.initLoad()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userReduxState !== this.props.userReduxState) {
      // this.initLoad()
    }
  }

  initLoad = async () => {
    let userInfo = this.props.userReduxState;
    if (Object.keys(userInfo).length) {
      try {
        let attendeeList = await SessionService.sessionAttendeeList(userInfo.sessionInfo.id);
        if (attendeeList.data.success) {
          this.setState({
            attendeeList: attendeeList.data.data
          })
        }
      } catch (error) {
        // errorToastr('Please try again.');
      }
    }
  }

  changeTab = (tabName) => {
    this.setState({
      tabName
    })
  }

  render() {
    const { tabName, attendeeList } = this.state
    const { chatReduxState: { onlineAttendeeCount } } = this.props
    return (
      <section className="chatSidebar">
        <ul className="nav nav-tabs chatSidebar__tabs border-0" id="myTab" role="tablist">
          <li className="nav-item">
            <Link
              className={`nav-link ${tabName === 'attendance' ? 'active' : ''}`}
              id="attendance-tab"
              to=""
              onClick={(e) => {
                e.preventDefault()
                this.changeTab('attendance')
              }}
            >
              <span className="count"><strong className="mr-1 font-neomd">{onlineAttendeeCount}</strong></span>  <span className="text">{config.langLabels.attendance}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${tabName === 'chat' ? 'active' : ''}`}
              id="chat-tab"
              to=""
              onClick={(e) => {
                e.preventDefault()
                this.changeTab('chat')
              }}
            >
              <span className="text">{config.langLabels.chat}</span>
            </Link>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          {
            tabName === 'attendance' ? <Attendance /> : <Chat />
          }
        </div>
      </section>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps,
    chatReduxState: state.chatProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PresenterSideBar)