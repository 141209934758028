import { HttpClient } from "../utils/httpClient";

const SESSION_AUTH = '/session-authentication';
let sessionAuthentication = (data = {}) => HttpClient.postApi(`${SESSION_AUTH}`, data);

const UPDATE_BOARD_ID_PATH = '/update-active-whiteboard'
let updateActiveBoardId = (sessionId, data = {}) => HttpClient.putApi(`${UPDATE_BOARD_ID_PATH}/${sessionId}`, data);

const SESSION_PATH = '/session'
let addNewBoard = (sessionId, data = {}) => HttpClient.postApi(`${SESSION_PATH}/${sessionId}/board`, data);

let deleteBoard = (sessionId, data = {}) => HttpClient.deleteApi(`${SESSION_PATH}/${sessionId}/board`, data);

const SESSION_ATTENDEE_LIST = '/session-attendee';
let sessionAttendeeList = (sessionId, searchKey = '', data = {}) => HttpClient.getApi(`${SESSION_ATTENDEE_LIST}/${sessionId}?searchKey=${searchKey}`, data);

const ATTENDEE_REQUEST = '/attendee-request';
let sessionAttendeeRequestList = (sessionId, data = {}) => HttpClient.getApi(`${ATTENDEE_REQUEST}/${sessionId}`, data);

const APPROVE_REQUEST = '/approve-request';
let approveRequest = (sessionId, data = {}) => HttpClient.postApi(`${APPROVE_REQUEST}/${sessionId}`, data);

const SEND_REQUEST = '/send-request';
let sendRequest = (sessionId, data = {}) => HttpClient.postApi(`${SEND_REQUEST}/${sessionId}`, data);


const WEB_DATA = '/get-dom';
let getWebContent = (data = {}) => HttpClient.getApi(`${WEB_DATA}`, data);

const UPDATE_ATTENDEE_STATUS = '/update-attendee-status';
let putAttendeeStatus = (data = {}) => HttpClient.putApi(`${UPDATE_ATTENDEE_STATUS}/${data.id}`, data);

let updateBoardSheetById = (data = {}) => HttpClient.putApi(`/update-whiteboard-sheet`, data);


const SessionService = {
  sessionAuthentication,
  updateActiveBoardId,
  addNewBoard,
  deleteBoard,
  sessionAttendeeList,
  sessionAttendeeRequestList,
  approveRequest,
  sendRequest,
  getWebContent,
  updateBoardSheetById,
  putAttendeeStatus
};

export default SessionService;