import React from 'react';
import { websiteSocketOn, websiteSocketEmit } from '../../socket';
import config from '../../config';
import $ from 'jquery'
import { connect } from 'react-redux';

class WebsiteIframe extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      prevUrl: '',
      html: ''
    }
  }

  componentDidMount() {
    websiteSocketOn(this.updateIframeUrl);

    this.initLoad()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.webUrl !== this.props.webUrl) {
      this.initLoad()
    }
  }

  initLoad = () => {
    this.setState({
      html: `${config.API_URL}/get-dom?url=${encodeURI(this.props.webUrl)}&pUrl=${encodeURI(this.props.webUrl)}`
    }, () => {
      this.detectScroll();
    })
  }
  updateIframeUrl = (data) => {
    if (data.boardId === this.props.boardId) {
      if (data.action === 'weburlchange') {
        this.setState({
          html: data.url
        })
      } else if (data.action === 'scrollchange') {
        var iframe = document.getElementById(`${this.props.boardId}myiframe`).contentWindow;
        $(iframe).scrollTop(data.scrollPosition)
      }
    }
  }

  load = (e) => {
    let url = document.getElementById(`${this.props.boardId}myiframe`).contentWindow.location.href;
    if (this.state.prevUrl !== url) {
      this.setState({
        prevUrl: url
      }, () => {
        let userInfo = this.props.userReduxState;
        if (userInfo.userType === 'presenter') {
          websiteSocketEmit({ action: 'weburlchange', url: url, sessionId: userInfo.sessionInfo.id, mainUrl: this.props.webUrl, boardId: this.props.boardId });
        }
        this.detectScroll();
      });
    }
  }

  detectScroll = () => {
    var iframe = document.getElementById(`${this.props.boardId}myiframe`).contentWindow;
    const { boardId, whiteBoardReduxState, userReduxState } = this.props
    if (
      Number(boardId) === Number(whiteBoardReduxState.activeWhiteBoardId) && (
        Number(userReduxState.userId) === Number(whiteBoardReduxState.activeWhiteBoardOwner.userId) &&
        userReduxState.userType === whiteBoardReduxState.activeWhiteBoardOwner.userType
      )
    ) {
      $(iframe).scroll(function () {
        websiteSocketEmit({ action: 'scrollchange', scrollPosition: $(iframe).scrollTop(), boardId: boardId });
      });
    }
  }

  render() {
    const { userReduxState, whiteBoardReduxState } = this.props
    return (
      <>
        <iframe
          id={`${this.props.boardId}myiframe`}
          width={`${this.props.cWidth}`}
          height={`${this.props.cHeight}`}
          onLoad={this.load}
          src={this.state.html}
        ></iframe>
        {
          (
            userReduxState.userId !== whiteBoardReduxState.activeWhiteBoardOwner.userId &&
            userReduxState.userType !== whiteBoardReduxState.activeWhiteBoardOwner.userType
          ) && <div className="web-site-hide"></div>
        }
      </>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps,
    whiteBoardReduxState: state.whiteBoardProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteIframe)