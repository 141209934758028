import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LibraryModal from '../modals/libraryModal';
import { onEnterKeyPressInChat, resetReactModalForm, showBootstrapModal } from '../../utils/commonJsFunctions';
import config from '../../config';

export default class ChatForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isOpenLibrary: false
    }
  }

  componentDidMount() {
    window.initEmoji();
    onEnterKeyPressInChat(this.sendMessage)
    resetReactModalForm("libraryModal", this.resetLibState)
  }

  resetLibState = () => {
    this.setState({
      isOpenLibrary: false
    })
  }

  setLibState = () => {
    this.setState({
      isOpenLibrary: true
    }, () => {
      showBootstrapModal("libraryModal")
    })
  }

  sendMessage = () => {
    this.props.updateChatList()
    window.initEmoji();
  }

  getSelectedFile = (data) => {
    this.setState({
      isOpenLibrary: false
    }, () => {
      this.props.updateChatList(data.selectedFileURL)
    })
  }

  render() {
    const { userReduxState } = this.props
    const { isOpenLibrary } = this.state
    return (
      <div className="chatBox__footer bg-white">
        <div className="d-flex align-items-center">
          {
            (userReduxState.userType && userReduxState.userType !== 'attendee') && <ul className="list-inline chatBox__footer__option mb-0">
              <li className="list-inline-item">
                <Link
                  to=""
                  // data-target="#libraryModal"
                  // data-toggle="modal"
                  onClick={(e) => {
                    e.preventDefault()
                    this.setLibState()
                  }}
                >
                  <span className="icon-send"></span>
                </Link>
              </li>
            </ul>
          }
          <div className="form-group mb-0 w-100 text-right">
            <input
              id="chatMessage"
              className="form-control text-right"
              placeholder={config.langLabels.reply}
              data-emojiable="true"
            />
          </div>
        </div>
        {
          isOpenLibrary && <LibraryModal type={'chat'} getSelectedFile={this.getSelectedFile} />
        }
      </div>
    )
  }
}
