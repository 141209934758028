import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from '../../header';
import PresenterSideBar from '../../sidebar/presenterSideBar';
import Footer from '../../footer';
import Modals from '../../modals';
import PageLoader from '../../loaders/pageLoader';
import { menuInResp } from '../../../utils/commonJsFunctions';

const PresenterLayout = (props) => {
  const { children } = props;
  return (
    <>
      <Header />
      <main className="main-content homePage">
        <div className="mainWrapper">
          <div className="mainWrapper__left">
            <PresenterSideBar />
          </div>
          <div className="mainWrapper__right bg-white">
            {children}
            <Footer />
            <Modals />
            <PageLoader />
          </div>
        </div>
      </main>
    </>
  );
};

PresenterLayout.propTypes = {
  children: PropTypes.node
};

export default PresenterLayout;
