
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import bootbox from 'bootbox';
import { connect } from 'react-redux';
import HoverTable from 'react-hovertable';
import { SketchPicker } from 'react-color';
import Countdown, { zeroPad } from 'react-countdown-now';
import { updateToolType } from '../../redux/actions/toolTypeAction';
import { toolTypeData } from '../../utils/toolData';
import Config from '../../config';
import { openFullscreen, showBootstrapModal, showAdminChat, scrollToBottomInList, extendTimeConfirm, hideShowAllAudioJs, hideShowAllVideoJs } from '../../utils/commonJsFunctions';
import moment from 'moment';
import ExtendTimeModal from '../modals/extendTimeModal';
import {
  extendTimeSocketOn,
  endSessionSocketEmit,
  endSessionSocketOn,
  extendTimeRequestSocketOn,
  extendTimeSocketEmit,
  hideShowAllAudioSocketOn,
  hideShowAllVideoSocketOn,
  hideShowAllAudioSocketEmit,
  hideShowAllVideoSocketEmit,
} from '../../socket';
import { updateUser } from '../../redux/actions/userAction';
import SessionService from '../../services/sessionService';
import ExtendTimeRequestModal from '../modals/extendTimeRequestModal';
import { successToastr, errorToastr } from '../../utils/handelError';
import ScreenRecording from '../video/screenRecording';
import ReactTooltip from 'react-tooltip'
import TimerClock from './timerClock';

let endSession = async (userInfo) => {
  if (userInfo.userType === 'presenter') {
    endSessionSocketEmit({ userType: userInfo.userType, userId: userInfo.userId });
    let updateBoardIdRes = await SessionService.updateActiveBoardId(userInfo.sessionInfo.id, { endSession: 'yes' })
    if (updateBoardIdRes.data.success) {
      window.location.href = "/";
    }
  }
}

class Header extends Component {

  state = {
    textTool: false,
    userType: '',
    extedndRequest: 0,
    isHideAllAudio: false,
    isHideAllVideo: false,
    defaultColor: {
      r: '241',
      g: '112',
      b: '19',
      a: '1',
    },
    sessionStartTimeRemaining: false
  }

  componentDidMount() {
    this.initLoad()
  }

  initLoad = () => {
    extendTimeSocketOn(this.extendTime);
    extendTimeRequestSocketOn(this.extendTimeRequest)
    endSessionSocketOn(this.endSessionSocketOn);
    hideShowAllAudioSocketOn(this.hideShowAllAudioOnSocket)
    hideShowAllVideoSocketOn(this.hideShowAllVideoOnSocket)
    // presenterDisconnectedSocketOn(this.presenterDisconnected);
    this.checkSessionTime()
  }

  checkSessionTime = () => {
    const { userReduxState } = this.props
    if ('sessionInfo' in userReduxState) {
      if (moment().utc().valueOf() < moment(userReduxState.sessionInfo.startDateTime).valueOf()) {
        let sessionStartDateTimeUTC = moment(userReduxState.sessionInfo.startDateTime)
        let currentDateTimeUTC = moment().utc()
        let diffInMinutes = moment.duration(sessionStartDateTimeUTC.diff(currentDateTimeUTC)).asMinutes()
        diffInMinutes = Math.round(diffInMinutes)

        if (diffInMinutes === 0) {
          diffInMinutes = 1
        } else {
          diffInMinutes = diffInMinutes + 1
        }

        this.setState({
          sessionStartTimeRemaining: diffInMinutes
        })

        setTimeout(() => {
          window.location.reload()
        }, 1000 * 60 * diffInMinutes);
      }
    }
  }

  // presenterDisconnected = () => {
  //   removeLSLocalStorage('userInfo');
  //   window.location.href = "/";
  // }

  endSessionSocketOn = (data) => {
    errorToastr(Config.langLabels.sessionCompleted);
    window.location.reload();
  }

  extendTimeRequest = (data) => {
    let userReduxState = this.props.userReduxState;
    if (userReduxState.userType === 'supervisor') {
      extendTimeConfirm(this.extendTimeOnConfirm, data)
    }
  }

  extendTimeOnConfirm = async (result, data) => {
    let userReduxState = this.props.userReduxState;
    userReduxState.sessionDuration = Number(userReduxState.sessionDuration) + Number(data.duration);
    let updateBoardIdRes = await SessionService.updateActiveBoardId(userReduxState.sessionInfo.id, { sessionDuration: userReduxState.sessionDuration })
    if (updateBoardIdRes.data.success) {
      this.props.updateUserState(userReduxState);
      successToastr(Config.langLabels.timeExtended);
      extendTimeSocketEmit({ sessionDuration: userReduxState.sessionDuration });
    }
  }

  extendTime = (data) => {
    let userReduxState = this.props.userReduxState;
    userReduxState.sessionDuration = Number(data.sessionDuration);
    this.props.updateUserState(userReduxState);
  }

  updateToolTypeInRedux = (tool = '', toolOptions = {}) => {
    if (tool === 'text' || tool === 'updatetext') {
      this.setState({
        textTool: true
      })
    } else {
      this.setState({
        textTool: false
      })
    }
    let data = toolTypeData(tool, toolOptions, this.props.toolTypeReduxState);
    this.props.updateToolTypeState(data)
  }

  endSession() {
    bootbox.confirm({
      message: Config.langLabels.endSessionMesg,
      // centerVertical: true,
      buttons: {
        confirm: {
          label: 'Yes',
          className: 'btn-primary ripple-effect'
        },
        cancel: {
          label: 'No',
          className: 'btn-dark ripple-effect'
        }
      },
      callback: (result) => {
        if (result) {
          endSession(this.props.userReduxState)
        }
      }
    });
  }

  getWhiteBoardType = (activeWhiteBoardId) => {
    const { whiteBoardReduxState } = this.props
    let board = whiteBoardReduxState.whiteBoards.find(obj => Number(obj.boardId) === Number(activeWhiteBoardId))
    if (board) {
      return board.boardType
    }

    return 'youtube'
  }

  hideAllAudio = () => {
    let display = "none"
    if (this.state.isHideAllAudio) {
      display = "block"
    }
    this.setState({
      isHideAllAudio: !this.state.isHideAllAudio
    }, () => {
      hideShowAllAudioJs(display)
      hideShowAllAudioSocketEmit({ display })
    })
  }

  hideAllVideo = () => {
    let display = "none"
    if (this.state.isHideAllVideo) {
      display = "block"
    }
    this.setState({
      isHideAllVideo: !this.state.isHideAllVideo
    }, () => {
      hideShowAllVideoJs(display)
      hideShowAllVideoSocketEmit({ display })
    })
  }

  hideShowAllAudioOnSocket = (data) => {
    hideShowAllAudioJs(data.display)
  }

  hideShowAllVideoOnSocket = (data) => {
    hideShowAllVideoJs(data.display)
  }

  render() {
    const { toolTypeReduxState, userReduxState, whiteBoardReduxState } = this.props
    const { isHideAllAudio, isHideAllVideo, defaultColor, sessionStartTimeRemaining } = this.state
    let selectedTool = toolTypeReduxState.tool
    let selectedToolFill = toolTypeReduxState.options.fill
    let selectedToolAngle = toolTypeReduxState.options.angle
    let selectedTooledge = toolTypeReduxState.options.edge
    let selectedToolCornerradius = toolTypeReduxState.options.cornerradius
    let allowToolBarAccess = (
      whiteBoardReduxState.activeWhiteBoardOwner.userType === userReduxState.userType &&
      whiteBoardReduxState.activeWhiteBoardOwner.userId === userReduxState.userId &&
      !['youtube', 'facebook', 'twitter', 'website'].includes(this.getWhiteBoardType(whiteBoardReduxState.activeWhiteBoardId))
    ) ? true : false
    if (allowToolBarAccess) {
      setTimeout(() => {
        if (document.getElementById("dropdownMenuLink03")) {
          document.getElementById("dropdownMenuLink03").onmousedown = () => {
            this.updateToolTypeInRedux('highliter', { strokeWidth: this.props.toolTypeReduxState.options.strokeWidth });
            return true; // Not needed, as long as you don't return false
          };

          // document.getElementById("dropdownMenuLink").onmousedown = () => {
          //   this.updateToolTypeInRedux('heartFill');
          //   return true; // Not needed, as long as you don't return false
          // };

          // document.getElementById("dropdownMenuLink01").onmousedown = () => {
          //   this.updateToolTypeInRedux('double-head-arrow');
          //   return true; // Not needed, as long as you don't return false
          // };
        }
      }, 10000);
    }

    return (
      <>
        <header className="bg-white" id="mainHeader">
          <div className="headerWrapper d-flex align-items-center">
            <div className="headerWrapper__left">
              <ul className="list-inline mb-0">
                {
                  (userReduxState.userType) ? (userReduxState.userType === 'presenter') ? <li className="list-inline-item">
                    <Link
                      to=""
                      data-tip={Config.langLabels.toolTipEndSession}
                      data-for="toolTipEndSession"
                      onClick={(e) => {
                        e.preventDefault();
                        this.endSession()
                      }} >
                      <i className="icon-logout"></i>
                    </Link>
                    <ReactTooltip className="headerTooltip" id="toolTipEndSession" place="right" type="dark" effect='solid' />
                  </li> : '' : ''
                }


                <li className="list-inline-item">
                  <Link
                    data-tip={Config.langLabels.toolTipSettings}
                    data-for="toolTipSettings"
                    to=""
                    onClick={(e) => {
                      e.preventDefault();
                      showBootstrapModal('settingModal')
                    }} >
                    <i className="icon-setting"></i>
                  </Link>
                  <ReactTooltip className="headerTooltip" id="toolTipSettings" place="bottom" type="dark" effect='solid' />
                </li>
                {
                  !['attendee', undefined].includes(userReduxState.userType) && <li className="list-inline-item">
                    <Link
                      to=""
                      data-tip={Config.langLabels.toolTipChat}
                      data-for="toolTipChat"
                      onClick={(e) => {
                        e.preventDefault();
                        showAdminChat();
                        scrollToBottomInList('chatList')
                      }} >
                      <i className="icon-chat"></i>
                    </Link>
                    <ReactTooltip className="headerTooltip" id="toolTipChat" place="bottom" type="dark" effect='solid' />
                  </li>
                }
                <li className="list-inline-item">
                  <Link
                    to=""
                    data-tip={Config.langLabels.toolTipFullScreen}
                    data-for="toolTipFullScreen"
                    onClick={(event) => {
                      event.preventDefault();
                      openFullscreen(event);
                    }}>
                    <i className="icon-fullscreen"></i>
                  </Link>
                  <ReactTooltip className="headerTooltip" id="toolTipFullScreen" place="bottom" type="dark" effect='solid' />
                </li>
                {
                  (userReduxState.userType === 'presenter') && <><li className="list-inline-item">
                    <Link
                      to=""
                      data-tip={Config.langLabels.toolTipHideShowAllAudios}
                      data-for="toolTipHideShowAllAudios"
                      onClick={(event) => {
                        event.preventDefault();
                        this.hideAllAudio()
                      }}
                      className={`${isHideAllAudio ? "active" : ''}`}
                    >
                      <i className="icon-voice"></i>
                    </Link>
                    <ReactTooltip className="headerTooltip" id="toolTipHideShowAllAudios" place="bottom" type="dark" effect='solid' />
                  </li>
                    <li className="list-inline-item">
                      <Link
                        to=""
                        data-tip={Config.langLabels.toolTipHideShowAllVideos}
                        data-for="toolTipHideShowAllVideos"
                        onClick={(event) => {
                          event.preventDefault();
                          this.hideAllVideo()
                        }}
                        className={`${isHideAllVideo ? "active" : ''}`}
                      >
                        <i className="icon-videocam"></i>
                      </Link>
                      <ReactTooltip className="headerTooltip" id="toolTipHideShowAllVideos" place="bottom" type="dark" effect='solid' />
                    </li>
                  </>
                }

              </ul>
            </div>
            <div className="headerWrapper__middle">
              <ul className="list-inline mb-0 d-flex align-items-center">
                <li className="list-inline-item">
                  <label className="headerWrapper__count font-neomd blue-color mb-0">
                    <TimerClock userReduxState={userReduxState} />
                  </label>
                </li>
                {
                  (userReduxState.userType === 'supervisor') && <li className="list-inline-item ml-3">
                    {
                      moment(userReduxState.sessionInfo.startDateTime).valueOf() <= moment().utc().valueOf() && <><Link
                        to=""
                        data-tip={Config.langLabels.toolTipExtendTime}
                        data-for="toolTipExtendTime"
                        className="addBtn font-hrg"
                        onClick={(e) => {
                          e.preventDefault();
                          showBootstrapModal('extendTimeModal');
                        }}
                      >
                        <i className="icon-add"></i>
                        <span>{Config.langLabels.toolTipExtendTime}</span>
                      </Link>
                        <ReactTooltip className="headerTooltip" id="toolTipExtendTime" place="bottom" type="dark" effect='solid' />
                      </>
                    }
                  </li>
                }
                {
                  (userReduxState.userType === 'presenter') && <>
                    <li className="list-inline-item ml-3">
                      {
                        moment(userReduxState.sessionInfo.startDateTime).valueOf() <= moment().utc().valueOf() && <><Link
                          to=""
                          data-tip={Config.langLabels.toolTipExtendTime}
                          data-for="toolTipExtendTime"
                          className="addBtn font-hrg"
                          onClick={(e) => {
                            e.preventDefault();
                            showBootstrapModal('extendTimeRequestModal');
                          }}
                        >
                          <i className="icon-add"></i>
                          <span>{Config.langLabels.toolTipExtendTime}</span>
                        </Link>
                          <ReactTooltip className="headerTooltip" id="toolTipExtendTime" place="bottom" type="dark" effect='solid' />
                        </>
                      }
                    </li>

                    {
                      (
                        userReduxState.userType === 'presenter' &&
                        // whiteBoardReduxState.startScreenShareModal &&
                        whiteBoardReduxState.isRecordingOn
                      ) && <li className="list-inline-item ml-3">

                        <Link
                          to=""
                          data-tip={Config.langLabels.toolTipStopScreenShare}
                          data-for="toolTipStopScreenShare"
                          className="addBtn font-hrg"
                          onClick={(e) => {
                            e.preventDefault();
                            // https://github.com/kriasoft/react-starter-kit/issues/909#issuecomment-252969542
                            this.screenRecording.stopScreenSharing()
                          }}
                        >
                          <i className="icon-videocam"></i>
                          <span>{Config.langLabels.toolTipStopScreenShare}</span>
                        </Link>
                        <ReactTooltip className="headerTooltip" id={"toolTipStopScreenShare"} place="bottom" type="dark" effect='solid' />
                      </li>
                    }

                  </>
                }
              </ul>
            </div>
            {
              allowToolBarAccess && (userReduxState.userType) ? (
                <div className="headerWrapper__right ml-auto">
                  <ul className="list-inline mb-0">
                    {/* <li className="list-inline-item">
                      <Link
                        to=""
                        className={`border_right ${selectedTool === 'browse' ? 'active' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.updateToolTypeInRedux(`${selectedTool === 'browse' ? 'pencil' : 'browse'}`)
                        }}
                      >
                        <i className="icon-select"></i>
                      </Link>
                    </li> */}
                    {
                      userReduxState.userType === 'presenter' && <li className="list-inline-item">
                        <Link
                          to=""
                          className={`border_right ${selectedTool === 'clear' ? 'active' : ''}`}
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('clear')
                          }}
                          data-tip={Config.langLabels.toolTipClearBoard}
                          data-for="toolTipClearBoard"
                        >
                          <i className="icon-blackboarderaser"></i>
                        </Link>
                        <ReactTooltip className="headerTooltip" id="toolTipClearBoard" place="bottom" type="dark" effect='solid' />
                      </li>
                    }
                    <li className="list-inline-item">
                      <Link
                        to=""
                        className={`border_right ${selectedTool === 'undo' ? 'active' : ''}`}
                        onClick={(e) => {
                          e.preventDefault()
                          this.updateToolTypeInRedux('undo')
                        }}
                        data-tip={Config.langLabels.toolTipUndo}
                        data-for="toolTipUndo"
                      >
                        <i className="icon-undo"></i>
                      </Link>
                      <ReactTooltip className="headerTooltip" id="toolTipUndo" place="bottom" type="dark" effect='solid' />
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to=""
                        className={`border_right ${selectedTool === 'redo' ? 'active' : ''}`}
                        onClick={(e) => {
                          e.preventDefault()
                          this.updateToolTypeInRedux('redo')
                        }}
                        data-tip={Config.langLabels.toolTipRedo}
                        data-for="toolTipRedo"
                      >
                        <i className="icon-redo"></i>
                      </Link>
                      <ReactTooltip className="headerTooltip" id="toolTipRedo" place="bottom" type="dark" effect='solid' />
                    </li>
                    <li className="list-inline-item inputColor">
                      <Link
                        to=""
                        style={{ color: toolTypeReduxState.options.color }}
                        className={`dropdown-toggle  ${selectedTool === 'color' ? 'active' : ''}`}
                        id="dropdownMenuLinkColor"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={(e) => {
                          e.preventDefault()
                        }}
                        data-tip={Config.langLabels.toolTipColorPicker}
                        data-for="toolTipColorPicker"
                      >
                        <i className="icon-color-shape" style={(toolTypeReduxState.options.color === '#ffffff') ? { background: '#edf0f4' } : { background: '' }}></i>
                      </Link>
                      <ReactTooltip className="headerTooltip" id="toolTipColorPicker" place="bottom" type="dark" effect='solid' />
                      <div className="dropdown-menu colordropdown" aria-labelledby="dropdownMenuLinkColor">
                        <SketchPicker
                          color={defaultColor}
                          onChange={(color) => {
                            this.setState({
                              defaultColor: color.rgb
                            })
                          }}
                          onChangeComplete={(color) => {
                            this.updateToolTypeInRedux('color', { color: color.hex });
                          }}
                        />
                      </div>
                    </li>
                    <li className="list-inline-item dropdown">
                      <Link
                        to=""
                        className={`dropdown-toggle ${selectedTool === 'table' ? 'active' : ''}`}
                        id="dropdownMenuLink04"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={(e) => {
                          e.preventDefault()
                        }}
                        data-tip={Config.langLabels.toolTipTable}
                        data-for="toolTipTable"
                      >
                        <i className="icon-table"></i>
                      </Link>
                      <ReactTooltip className="headerTooltip" id="toolTipTable" place="bottom" type="dark" effect='solid' />
                      <div className="dropdown-menu tableDropdown" aria-labelledby="dropdownMenuLink04">
                        <HoverTable
                          column={10}
                          row={10}
                          width={150}
                          height={150}
                          onClick={(e, data) => {
                            this.updateToolTypeInRedux('table', data)
                          }}
                          showDimension={true} // show dimension or not
                        // theme={'light'}
                        />
                      </div>
                    </li>
                    <li className="list-inline-item dropdown">
                      <Link
                        to=""
                        onClick={(e) => {
                          e.preventDefault()
                          this.updateToolTypeInRedux('double-head-arrow')
                        }}
                        className={`dropdown-toggle ${['double-head-arrow', 'curved-arrow', 'single-head-arrow'].includes(selectedTool) ? 'active' : ''}`}
                        id="dropdownMenuLink01"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-tip={Config.langLabels.toolTipArrows}
                        data-for="toolTipArrows"
                      >
                        <i className="icon-a1"></i>
                      </Link>
                      <ReactTooltip className="headerTooltip" id="toolTipArrows" place="bottom" type="dark" effect='solid' />
                      <div className="dropdown-menu arrowDropdown" aria-labelledby="dropdownMenuLink01">
                        <Link to=""
                          data-tip={Config.langLabels.toolTipDoubleHeadArrow}
                          data-for="toolTipDoubleHeadArrow"
                          className={`dropdown-item ${selectedTool === 'double-head-arrow' ? 'active' : ''}`}
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('double-head-arrow')
                          }}
                        >
                          <i className="icon-a3"></i>
                        </Link>
                        <Link to=""
                          data-tip={Config.langLabels.toolTipCurvedArrow}
                          data-for="toolTipCurvedArrow"
                          className={`dropdown-item ${selectedTool === 'curved-arrow' ? 'active' : ''}`}
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('curved-arrow')
                          }}
                        >
                          <i className="icon-a2"></i>
                        </Link>
                        <Link to=""
                          data-tip={Config.langLabels.toolTipSingleHeadArrow}
                          data-for="toolTipSingleHeadArrow"
                          className={`dropdown-item ${selectedTool === 'single-head-arrow' ? 'active' : ''}`}
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('single-head-arrow')
                          }}
                        >
                          <i className="icon-a1"></i>
                        </Link>

                        <ReactTooltip className="headerTooltip" id="toolTipDoubleHeadArrow" place="bottom" type="dark" effect='solid' />
                        <ReactTooltip className="headerTooltip" id="toolTipCurvedArrow" place="bottom" type="dark" effect='solid' />
                        <ReactTooltip className="headerTooltip" id="toolTipSingleHeadArrow" place="bottom" type="dark" effect='solid' />

                      </div>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to=""
                        data-tip={Config.langLabels.toolTipLine}
                        data-for="toolTipLine"
                        className={`${selectedTool === 'line' ? 'active' : ''}`}
                        onClick={(e) => {
                          e.preventDefault()
                          this.updateToolTypeInRedux('line')
                        }}
                      >
                        <i className="icon-linexpand"></i>
                      </Link>
                      <ReactTooltip className="headerTooltip" id="toolTipLine" place="bottom" type="dark" effect='solid' />
                    </li>
                    <li className="list-inline-item dropdown">
                      <Link
                        to=""
                        onClick={(e) => {
                          e.preventDefault()
                          this.updateToolTypeInRedux('heartFill')
                        }}
                        className={`dropdown-toggle ${[
                          'heart',
                          'triangle',
                          'polygon',
                          'circle',
                          'rectangle',
                        ].includes(selectedTool) ? 'active' : ''}`}
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-tip={Config.langLabels.toolTipShapes}
                        data-for="toolTipShapes"
                      >
                        <i className="icon-dubble-shape"></i>
                      </Link>
                      <ReactTooltip className="headerTooltip" id="toolTipShapes" place="bottom" type="dark" effect='solid' />
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <Link
                          to=""
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('heartFill')
                          }}
                          data-tip={Config.langLabels.toolTipHeartFill}
                          data-for="toolTipHeartFill"
                          className={`dropdown-item ${(selectedTool === 'heart' && selectedToolFill) ? 'active' : ''}`}
                        >
                          <i className="icon-heart-fill"></i>
                        </Link>
                        <Link
                          to=""
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('pentagonFill')
                          }}
                          data-tip={Config.langLabels.toolTipPentagonFill}
                          data-for="toolTipPentagonFill"
                          className={`dropdown-item ${(selectedTool === 'polygon' && selectedTooledge === 5 && selectedToolFill) ? 'active' : ''}`}
                        >
                          <i className="icon-pantagon-fill"></i>
                        </Link>
                        <Link
                          to=""
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('triangleFill')
                          }}
                          data-tip={Config.langLabels.toolTipTriangleFill}
                          data-for="toolTipTriangleFill"
                          className={`dropdown-item ${(selectedTool === 'triangle' && selectedToolFill) ? 'active' : ''}`}
                        >
                          <i className="icon-triangle-fill"></i>
                        </Link>
                        <Link
                          to=""
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('squareFill')
                          }}
                          data-tip={Config.langLabels.toolTipSquareFill}
                          data-for="toolTipSquareFill"
                          className={`dropdown-item ${(selectedTool === 'rectangle' && selectedToolFill && selectedToolAngle === 0 && selectedToolCornerradius === 'no') ? 'active' : ''}`}
                        >
                          <i className="icon-square-fill"></i>
                        </Link>
                        <Link
                          to=""
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('polygonFill')
                          }}
                          data-tip={Config.langLabels.toolTipPolygonFill}
                          data-for="toolTipPolygonFill"
                          className={`dropdown-item ${(selectedTool === 'polygon' && selectedTooledge === 6 && selectedToolFill) ? 'active' : ''}`}
                        >
                          <i className="icon-polygon-fill"></i>
                        </Link>
                        <Link
                          to=""
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('fontagonFill')
                          }}
                          data-tip={Config.langLabels.toolTipFontagonFill}
                          data-for="toolTipFontagonFill"
                          className={`dropdown-item ${(selectedTool === 'rectangle' && selectedToolAngle > 0 && selectedToolFill) ? 'active' : ''}`}
                        >
                          <i className="icon-fontagon-fill"></i>
                        </Link>
                        <Link
                          to=""
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('circleFill')
                          }}
                          data-tip={Config.langLabels.toolTipCircleFill}
                          data-for="toolTipCircleFill"
                          className={`dropdown-item ${(selectedTool === 'circle' && selectedToolFill) ? 'active' : ''}`}
                        >
                          <i className="icon-circle-fill"></i>
                        </Link>
                        <Link
                          to=""
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('rectangleFill')
                          }}
                          data-tip={Config.langLabels.toolTipRectangleFill}
                          data-for="toolTipRectangleFill"
                          className={`dropdown-item ${(selectedTool === 'rectangle' && selectedToolCornerradius === 'yes' && selectedToolFill) ? 'active' : ''}`}
                        >
                          <i className="icon-rectangle-fill"></i>
                        </Link>
                        <Link
                          to=""
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('heartOutline')
                          }}
                          data-tip={Config.langLabels.toolTipHeartOutline}
                          data-for="toolTipHeartOutline"
                          className={`dropdown-item ${(selectedTool === 'heart' && selectedToolFill === '') ? 'active' : ''}`}
                        >
                          <i className="icon-heart-outline"></i>
                        </Link>
                        <Link
                          to=""
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('pentagonOutline')
                          }}
                          data-tip={Config.langLabels.toolTipPentagonOutline}
                          data-for="toolTipPentagonOutline"
                          className={`dropdown-item ${(selectedTool === 'polygon' && selectedTooledge === 5 && selectedToolFill === '') ? 'active' : ''}`}
                        >
                          <i className="icon-pantagon-outline"></i>
                        </Link>
                        <Link
                          to=""
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('triangleOutline')
                          }}
                          data-tip={Config.langLabels.toolTipTriangleOutline}
                          data-for="toolTipTriangleOutline"
                          className={`dropdown-item ${(selectedTool === 'triangle' && selectedToolFill === '') ? 'active' : ''}`}
                        >
                          <i className="icon-triangle-outline"></i>
                        </Link>
                        <Link
                          to=""
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('squareOutline')
                          }}
                          data-tip={Config.langLabels.toolTipSquareOutline}
                          data-for="toolTipSquareOutline"
                          className={`dropdown-item ${(selectedTool === 'rectangle' && selectedToolFill === '' && selectedToolAngle === 0 && selectedToolCornerradius === 'no') ? 'active' : ''}`}
                        >
                          <i className="icon-square-outline"></i>
                        </Link>
                        <Link
                          to=""
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('polygonOutline')
                          }}
                          data-tip={Config.langLabels.toolTipPolygonOutline}
                          data-for="toolTipPolygonOutline"
                          className={`dropdown-item ${(selectedTool === 'polygon' && selectedTooledge === 6 && selectedToolFill === '') ? 'active' : ''}`}
                        >
                          <i className="icon-polygon-outline"></i>
                        </Link>
                        <Link
                          to=""
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('fontagonOutline')
                          }}
                          data-tip={Config.langLabels.toolTipFontagonOutline}
                          data-for="toolTipFontagonOutline"
                          className={`dropdown-item ${((selectedTool === 'rectangle' && selectedToolFill === '' && selectedToolAngle > 0)) ? 'active' : ''}`}
                        >
                          <i className="icon-fontagon-outline"></i>
                        </Link>
                        <Link
                          to=""
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('circleOutline')
                          }}
                          data-tip={Config.langLabels.toolTipCircleOutline}
                          data-for="toolTipCircleOutline"
                          className={`dropdown-item ${(selectedTool === 'circle' && selectedToolFill === '') ? 'active' : ''}`}
                        >
                          <i className="icon-circle-outline"></i>
                        </Link>
                        <Link
                          to=""
                          onClick={(e) => {
                            e.preventDefault()
                            this.updateToolTypeInRedux('rectangleOutline')
                          }}
                          data-tip={Config.langLabels.toolTipRectangleOutline}
                          data-for="toolTipRectangleOutline"
                          className={`dropdown-item ${((selectedTool === 'rectangle' && selectedToolFill === '' && selectedToolCornerradius === 'yes')) ? 'active' : ''}`}
                        >
                          <i className="icon-rectangle-outline"></i>
                        </Link>

                        <ReactTooltip className="headerTooltip" id="toolTipHeartFill" place="bottom" type="dark" effect='solid' />
                        <ReactTooltip className="headerTooltip" id="toolTipPentagonFill" place="bottom" type="dark" effect='solid' />
                        <ReactTooltip className="headerTooltip" id="toolTipTriangleFill" place="bottom" type="dark" effect='solid' />
                        <ReactTooltip className="headerTooltip" id="toolTipSquareFill" place="bottom" type="dark" effect='solid' />
                        <ReactTooltip className="headerTooltip" id="toolTipPolygonFill" place="bottom" type="dark" effect='solid' />
                        <ReactTooltip className="headerTooltip" id="toolTipFontagonFill" place="bottom" type="dark" effect='solid' />
                        <ReactTooltip className="headerTooltip" id="toolTipCircleFill" place="bottom" type="dark" effect='solid' />
                        <ReactTooltip className="headerTooltip" id="toolTipRectangleFill" place="bottom" type="dark" effect='solid' />

                        <ReactTooltip className="headerTooltip" id="toolTipHeartOutline" place="bottom" type="dark" effect='solid' />
                        <ReactTooltip className="headerTooltip" id="toolTipPentagonOutline" place="bottom" type="dark" effect='solid' />
                        <ReactTooltip className="headerTooltip" id="toolTipTriangleOutline" place="bottom" type="dark" effect='solid' />
                        <ReactTooltip className="headerTooltip" id="toolTipSquareOutline" place="bottom" type="dark" effect='solid' />
                        <ReactTooltip className="headerTooltip" id="toolTipPolygonOutline" place="bottom" type="dark" effect='solid' />
                        <ReactTooltip className="headerTooltip" id="toolTipFontagonOutline" place="bottom" type="dark" effect='solid' />
                        <ReactTooltip className="headerTooltip" id="toolTipCircleOutline" place="bottom" type="dark" effect='solid' />
                        <ReactTooltip className="headerTooltip" id="toolTipRectangleOutline" place="bottom" type="dark" effect='solid' />

                      </div>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to=""
                        className={`${selectedTool === 'eraser' ? 'active' : ''}`}
                        data-tip={Config.langLabels.toolTipEraser}
                        data-for="toolTipEraser"
                        onClick={(e) => {
                          e.preventDefault()
                          this.updateToolTypeInRedux('eraser')
                        }}
                      >
                        <i className="icon-eraser_inactive"></i>
                      </Link>
                      <ReactTooltip className="headerTooltip" id="toolTipEraser" place="bottom" type="dark" effect='solid' />
                    </li>
                    <li className="list-inline-item dropdown">
                      <Link
                        to=""
                        onClick={(e) => {
                          e.preventDefault();
                          this.updateToolTypeInRedux('highliter', { strokeWidth: toolTypeReduxState.options.strokeWidth })
                        }}
                        data-tip={Config.langLabels.toolTipHighliter}
                        data-for="toolTipHighliter"
                        className={`${selectedTool === 'highliter' ? 'active' : ''}`}
                        id="dropdownMenuLink03"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="icon-highliter"></i>
                      </Link>
                      <ReactTooltip className="headerTooltip" id="toolTipHighliter" place="bottom" type="dark" effect='solid' />
                      <div className="dropdown-menu range" aria-labelledby="dropdownMenuLink03">
                        <form>
                          <div className="slidecontainer">
                            <input
                              type="range"
                              min="10"
                              max="50"
                              step="5"
                              value={toolTypeReduxState.options.strokeWidth || 10}
                              className="slider"
                              id="myRange"
                              onChange={(e) => {
                                this.updateToolTypeInRedux('highliter', { strokeWidth: e.target.value })
                              }}
                            />
                          </div>
                        </form>
                      </div>
                    </li>
                    <li className="list-inline-item dropdown">
                      <Link
                        to=""
                        data-tip={Config.langLabels.toolTipText}
                        data-for="toolTipText"
                        className={`${(selectedTool === 'text' || selectedTool === 'updatetext') ? 'active' : ''}`}
                        onClick={(e) => {
                          e.preventDefault()
                          this.updateToolTypeInRedux('text')
                        }}>
                        <i className="icon-text"></i>
                      </Link>
                      <ReactTooltip className="headerTooltip" id="toolTipText" place="bottom" type="dark" effect='solid' />
                    </li>
                    <li className="list-inline-item dropdown">
                      <Link
                        to=""
                        data-tip={Config.langLabels.toolTipPencil}
                        data-for="toolTipPencil"
                        className={`${selectedTool === 'pencil' ? 'active' : ''}`}
                        onClick={(e) => {
                          e.preventDefault()
                          this.updateToolTypeInRedux('pencil')
                        }}
                      // className="dropdown-toggle"
                      // id="dropdownMenuLink02"
                      // data-toggle="dropdown"
                      // aria-haspopup="true"
                      // aria-expanded="false"
                      >
                        <i className="icon-pencil"></i>
                      </Link>
                      <ReactTooltip className="headerTooltip" id="toolTipPencil" place="bottom" type="dark" effect='solid' />
                      {/* <div className="dropdown-menu range" aria-labelledby="dropdownMenuLink02">
                  <form>
                    <div className="slidecontainer">
                      <input type="range" min="1" max="100" defaultValue="50" className="slider" id="myRange" />
                    </div>
                  </form>
                </div> */}
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to=""
                        className={`${selectedTool === 'pane' ? 'active' : ''}`}
                        data-tip={Config.langLabels.toolTipSelector}
                        data-for="toolTipSelector"
                        onClick={(e) => {
                          e.preventDefault()
                          this.updateToolTypeInRedux('select')
                        }}
                      >
                        <i className="icon-near-me"></i>
                      </Link>
                      <ReactTooltip className="headerTooltip" id="toolTipSelector" place="bottom" type="dark" effect='solid' />
                    </li>
                  </ul>
                </div>
              ) : <div className="headerWrapper__right ml-auto mr-4">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <span className="addBtn content border-0">{("sessionInfo" in userReduxState) ? userReduxState.sessionInfo.title : ''}</span>
                    </li>
                  </ul>
                </div>
            }
          </div>
        </header>
        {
          (userReduxState.userType === 'supervisor') && <ExtendTimeModal extendRequest={this.state.extedndRequest} />
        }
        {
          (userReduxState.userType === 'presenter') && <ExtendTimeRequestModal />
        }
        {
          (userReduxState.userType === 'presenter' && whiteBoardReduxState.startScreenShareModal) && <ScreenRecording onRef={ref => (this.screenRecording = ref)} /> //https://github.com/kriasoft/react-starter-kit/issues/909#issuecomment-252969542
        }
      </>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    toolTypeReduxState: state.toolTypeProps,
    userReduxState: state.userProps,
    whiteBoardReduxState: state.whiteBoardProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updateToolTypeState: data => dispatch(updateToolType(data)),
    updateUserState: data => dispatch(updateUser(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)