import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { docImageChangeSocketEmit, docImageChangeSocketOn, zoomInOutSocketEmit, zoomInOutSocketOn } from '../../socket';

class DocImages extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      docImages: [],
      activeImage: 0,
      zoomVal: 20,
    }
  }

  componentDidMount() {
    this.props.onRef(this)
    this.initLoad()
    docImageChangeSocketOn(this.updateActiveImageBySocket)
    zoomInOutSocketOn(this.socketZoom)
  }


  socketZoom = (val) => {
    this.props.setZoom(val / 20);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.doc_images !== this.props.doc_images &&
      prevProps.whiteBoardReduxState.activeWhiteBoardId !== this.props.whiteBoardReduxState.activeWhiteBoardId
    ) {
      // this.initLoad()
    }
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  resetZoomLevel = () => {
    this.setState({
      zoomVal: 20,
    })
  }

  initLoad = () => {
    const { doc_images } = this.props
    this.setState({
      docImages: doc_images,
      activeImage: 0,
      zoomVal: 20,
    }, () => {
      if (doc_images.length) {
        this.props.changeImg(0, this.state.docImages);
      }
    })
  }

  changeImg = (type) => {
    if (this.props.currentWhiteBoardId === this.props.whiteBoardReduxState.activeWhiteBoardId) {
      this.setState(prevState => {
        this.props.prevImag(prevState.activeImage, this.state.docImages);
        let newActiveImage = prevState.activeImage + (type === 'inc' ? 1 : -1)
        docImageChangeSocketEmit({ boardId: this.props.currentWhiteBoardId, newActiveImage: newActiveImage, cavasData: localStorage.getItem(this.state.docImages[newActiveImage]) })
        return {
          activeImage: newActiveImage,
          zoomVal: 20,
        }
      }, () => {
        this.props.changeImg(this.state.activeImage, this.state.docImages);
      })
    }
  }

  updateActiveImageBySocket = (data) => {
    if (this.props.currentWhiteBoardId === data.boardId) {
      this.setState({
        activeImage: data.newActiveImage,
        zoomVal: 20,
      }, () => {
        this.props.attendeeDoc(data, this.state.docImages);
      })
    }
  }

  setZoomInOut = (val = 0) => {
    this.props.setZoom(val / 20);
    this.setState({
      zoomVal: val,
    }, () => {
      zoomInOutSocketEmit(val)
    })
  }

  render() {
    const { activeImage, docImages, zoomVal } = this.state
    const { userReduxState: { userType } } = this.props
    return (
      <>
        {
          (
            docImages &&
            docImages.length > 0 &&
            this.props.whiteBoardReduxState.activeWhiteBoardOwner.userType === this.props.userReduxState.userType &&
            this.props.whiteBoardReduxState.activeWhiteBoardOwner.userId === this.props.userReduxState.userId
          ) && <div className="slideControl mx-auto">
            <nav aria-label="Page navigation example">
              <ul className="pagination mx-auto mb-0">
                <li className="page-item">
                  <span className="page-link zoomSlider d-flex align-items-center">
                    <span
                      className="icon-zoom_out mr-2"
                      onClick={(e) => {
                        e.preventDefault()
                        if (zoomVal > 5) {
                          this.setZoomInOut(Number(zoomVal) - 5)
                        }
                      }}
                    ></span>
                    <input
                      type="range"
                      className="customRange"
                      id="customRange1"
                      min={5}
                      max={35}
                      step={5}
                      value={zoomVal}
                      onChange={(e) => {
                        this.setZoomInOut(e.target.value)
                      }}
                    />
                    <span
                      className="icon-zoom_in ml-2"
                      onClick={(e) => {
                        e.preventDefault()
                        if (zoomVal < 35) {
                          this.setZoomInOut(Number(zoomVal) + 5)
                        }
                      }}
                    ></span>
                  </span>
                </li>
                <li className="page-item">
                  <Link
                    className={`page-link ${activeImage === (docImages.length - 1) ? 'disabled' : ''}`}
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      if (activeImage < (docImages.length - 1)) {
                        this.changeImg('inc')
                      }

                    }}
                  >
                    <span className="icon-arrow-thin-left"></span>
                  </Link>
                </li>
                <li className="page-item"><span className="page-link slideInfo text-center">{activeImage + 1}/{docImages.length}</span></li>
                <li className="page-item">
                  <Link
                    className={`page-link ${activeImage === 0 ? 'disabled' : ''}`}
                    to=""
                    onClick={(e) => {
                      e.preventDefault()
                      if (activeImage > 0) {
                        this.changeImg('dec')
                      }
                    }}
                  >
                    <span className="icon-arrow-thin-right"></span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        }
      </>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps,
    whiteBoardReduxState: state.whiteBoardProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocImages)