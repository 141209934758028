import React, { Component } from "react";
import Countdown, { zeroPad } from 'react-countdown-now';
import moment from "moment";
import Config from "../../config";
import SessionService from "../../services/sessionService";
import { endSessionSocketEmit } from "../../socket";

const Completionist = () => <span>{Config.langLabels.sessionCompleted}</span>

let endSession = async (userInfo) => {
  if (userInfo.userType === 'presenter' || (Number(userInfo.userId) === Number(userInfo.sessionInfo.sessionUuid))) {
    endSessionSocketEmit({ userType: userInfo.userType, userId: userInfo.userId });
    let updateBoardIdRes = await SessionService.updateActiveBoardId(userInfo.sessionInfo.id, { endSession: 'yes' })
    if (updateBoardIdRes.data.success) {
      window.location.href = "/";
    }
  }
}

export default class TimerClock extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sessionStartTimeRemaining: false
    }
  }

  componentDidMount() {
    this.checkSessionTime()
  }

  checkSessionTime = () => {
    const { userReduxState } = this.props
    if ('sessionInfo' in userReduxState) {
      if (moment().utc().valueOf() < moment(userReduxState.sessionInfo.startDateTime).valueOf()) {
        let sessionStartDateTimeUTC = moment(userReduxState.sessionInfo.startDateTime)
        let currentDateTimeUTC = moment().utc()
        let diffInMinutes = moment.duration(sessionStartDateTimeUTC.diff(currentDateTimeUTC)).asMinutes()
        diffInMinutes = Math.round(diffInMinutes)

        if (diffInMinutes === 0) {
          diffInMinutes = 1
        } else {
          diffInMinutes = diffInMinutes + 1
        }

        this.setState({
          sessionStartTimeRemaining: diffInMinutes
        })

        setTimeout(() => {
          window.location.reload()
        }, 1000 * 60 * diffInMinutes);
      }
    }
  }

  // Renderer callback with condition
  renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {

      endSession(this.props.userReduxState);

      return <Completionist />;
      // Render a complete state
    } else {
      // Render a countdown
      return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    }
  };

  rendererBeforeSessionStart = ({ hours, minutes, seconds, completed }) => {
    if (completed) {

      return <div>{Config.langLabels.preSessionStartMsg}</div>
      // Render a complete state
    } else {
      // Render a countdown
      return <span>{Config.langLabels.sessionStartAfter(`${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`)}</span>;
    }
  };

  render() {
    const { sessionStartTimeRemaining } = this.state;
    const { userReduxState } = this.props;

    return (
      <>
        {
          ('sessionInfo' in userReduxState) && <>
            {
              moment(userReduxState.sessionInfo.startDateTime).valueOf() <= moment().utc().valueOf() ? <Countdown
                date={moment(userReduxState.sessionInfo.startDateTime).add(Number(userReduxState.sessionDuration), 'minutes').valueOf()}
                renderer={this.renderer}
                now={this.getCurrentTime}
                onTick={this.onTick}
              /> : <>
                  {
                    sessionStartTimeRemaining && <Countdown
                      date={moment().utc().add(sessionStartTimeRemaining, 'minutes').valueOf()}
                      renderer={this.rendererBeforeSessionStart}
                      now={this.getCurrentTime}
                      onTick={this.onTick}
                    />
                  }
                </>
            }
          </>
        }
      </>
    )
  }
}