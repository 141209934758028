import React from 'react';
import { TwitterVideoEmbed } from 'react-twitter-embed';
import { TWITTER_REGEXP } from '../../utils/constants';

export default class TwitterIframe extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    let match = this.props.videoUrl.match(TWITTER_REGEXP);
    let videoId = match.length ? match[3] : false
    return (
      <>
        <TwitterVideoEmbed
          id={videoId}
        />
      </>
    );
  }
}