import { UPDATE_USER } from "../reduxConst/userConst";
import userState from "../reduxStates/userState";

let updateUser = (state, action) => {
  return {
    ...state,
    ...action.data
  }
}

let userReducer = (state = userState, action) => {

  switch (action.type) {

    case UPDATE_USER:

      return updateUser(state, action)

    default:
      return state;
  }

}

export default userReducer;