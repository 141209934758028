import React, { Component } from 'react'
import { connect } from 'react-redux';
import ChatService from '../../services/chatService';
import { scrollToBottomInAdminChat, getAdminChatMessage, resetAdminChatMessage, onEnterKeyPressInAdminChat, showAdminChat } from '../../utils/commonJsFunctions';
import { adminMessageSocketEmit, adminMessageSocketOn } from '../../socket';
import $ from 'jquery';
import ListLoader from '../../components/loaders/listLoader';
import config from '../../config';

class PSChatList extends Component {

  constructor(props) {
    super(props)

    this.state = {
      chatList: [],
      isLoading: true,
    }
  }

  componentDidMount() {
    adminMessageSocketOn(this.adminMessageOnSocket)
    this.initLoad()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userReduxState.sessionId !== this.props.userReduxState.sessionId) {
      this.initLoad()
    }
  }

  initLoad = () => {
    const {
      userReduxState: {
        sessionInfo
      }
    } = this.props

    if (sessionInfo) {
      try {
        let rawData = {
          adminChat: true
        }

        this.setState({
          isLoading: true
        }, async () => {
          let getInitLoad = await ChatService.getChatQuestionList(sessionInfo.id, rawData)
          if (getInitLoad.data.success) {
            this.setState({
              chatList: getInitLoad.data.data.rows
            }, () => {
              scrollToBottomInAdminChat('chatList')
              setTimeout(() => {
                this.setState({
                  isLoading: false
                }, () => {
                  onEnterKeyPressInAdminChat(this.updateChatList)
                })
              }, 1000 * 2);
            })
          }
        })
      } catch (error) {

      }
    }
  }

  updateChatList = async (fileUrl = '') => {
    const {
      userReduxState: {
        userId,
        userType,
        name,
        sessionInfo
      }
    } = this.props

    let { chatList } = this.state
    let textMessage = fileUrl || getAdminChatMessage()

    if (textMessage) {

      let rawData = {
        sessionId: sessionInfo.id,
        userId: userId,
        fromName: name,
        userType: userType,
        message: textMessage,
        messageType: fileUrl ? 'file' : 'text',
        mediaUrl: fileUrl,
        parentId: null,
        isMarked: 'no',
        requestId: null,
        parentMessage: null,
        isAdminChat: true
      }

      chatList.push(rawData);
      this.setState({
        chatList
      }, () => {
        scrollToBottomInAdminChat('chatList')
        resetAdminChatMessage()
      })

      try {

        let postedMsgRes = await ChatService.postChat(rawData)
        if (postedMsgRes.data.success) {
          let resData = postedMsgRes.data.data
          adminMessageSocketEmit(resData);
        }
      } catch (error) {

      }
    }
  }

  adminMessageOnSocket = (data) => {
    $('#adminChat').removeClass('d-none');
    $('#adminChat').addClass('d-block');
    this.setState(prevState => {
      prevState.chatList.push(data.message)
      return {
        chatList: prevState.chatList
      }
    }, () => {
      scrollToBottomInAdminChat('chatList')
      if ($("#adminLoader").hasClass("d-block")) {
        setTimeout(() => {
          $("#adminLoader").removeClass("d-block");
          $("#adminLoader").addClass("d-none");
        }, 500);
      }
    })
  }

  render() {
    const { chatList, isLoading } = this.state
    return (
      <>
        <div id="adminChat" className="adminChat text-right" dir="rtl">
          <>
            <div id="adminLoader" className="d-block">
              <ListLoader />
            </div>
            {
              isLoading && <ListLoader />
            }
            <div className="chatList">
              {
                (chatList && chatList.length > 0) && chatList.map((psChat, index) => {
                  return <div className="adminChat__chat" key={index}>
                    <div className="d-flex align-items-center">
                      {/* <img src="/assets/images/user1.jpg" className="rounded-circle img-fluid adminChat__chat__userImg" alt="user img" /> */}
                      <div className="adminChat__chat__name">
                        <h4>{psChat.fromName}</h4>
                        <span className="d-block">{psChat.userType}</span>
                      </div>
                    </div>
                    <p className="font-hrg mb-0">
                      {psChat.message}
                    </p>
                  </div>
                })
              }
            </div>
            <div className="adminChat__reply">
              <form>
                <div className="form-group mb-0">
                  <textarea className="form-control border-0 chatSupervisorPresenter" placeholder={config.langLabels.reply}></textarea>
                </div>
              </form>
            </div>
          </>
        </div>
      </>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PSChatList)