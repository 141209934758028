import React from "react"
import config from "../../config";

const ListLoader = () => (
  <div
    className="d-flex align-items-center justify-content-center h-100 listLoaders">
    <div className="spinner-border spinner-border-sm" role="status">
      <span className="sr-only">{config.langLabels.loading}</span>
    </div>
  </div>
)

export default ListLoader;

