import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import RootReducer from '../reducers';

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  trace: true
});

const Store = createStore(RootReducer, /* preloadedState, */ composeEnhancers(
  applyMiddleware(ReduxThunk),
  // other store enhancers if any
));

// const Store = createStore(
//   RootReducer,
//   applyMiddleware(ReduxThunk)
// );

export default Store;