let whiteBoardStateInit = () => {
  return {
    whiteBoards: [],
    activeWhiteBoardId: '',
    activeWhiteBoardOwner: {},
    isRecordingOn: false,
    startScreenShareModal: false
  }
}

let whiteBoardState = whiteBoardStateInit()

export default whiteBoardState;