import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { hideBootstrapModal } from '../../utils/commonJsFunctions';
import FormDocPresentation from './formDocPresentation';
import { updateRecordingOn, updateStartScreenShare } from '../../redux/actions/whiteBoardAction';
import config from '../../config';

/**
 * add content Modal
 */
class AddModal extends Component {
  render() {
    const { userReduxState: { userType }, whiteBoardReduxState } = this.props
    return (
      <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="settingModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered w-570 add-modal" role="document">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <button type="button" className="close pt-0" data-dismiss="modal" aria-label="Close">
                <i className="icon-close"></i>
              </button>
              <h5 className="modal-title font-neomd m-0">{config.langLabels.addContent}</h5>
            </div>
            <div className="modal-body p-0">
              <ul className="list-inline mb-0">
                <li>
                  <Link
                    to=""
                    data-target="#addVideosModal"
                    data-toggle="modal"
                    onClick={(e) => {
                      e.preventDefault()
                      hideBootstrapModal('addModal')
                    }}>
                    <img src="/assets/images/videos.svg" alt="video icon" className="img-fluid" />
                    <span className="d-block">{config.langLabels.videos}</span>
                  </Link>
                </li>

                <li>
                  <Link
                    to=""
                    // data-target="#addDocumentModal"
                    // data-toggle="modal"
                    onClick={(e) => {
                      e.preventDefault()
                    }}>
                    <img src="/assets/images/document.svg" alt="document icon" className="img-fluid" />
                    <span className="d-block">{config.langLabels.documents}</span>
                  </Link>
                  <FormDocPresentation formType={'document'} />
                </li>

                <li className="border-right-0">
                  <Link
                    to=""
                    // data-target="#addPresentationModal"
                    // data-toggle="modal"
                    onClick={(e) => {
                      e.preventDefault()
                    }}>
                    <img src="/assets/images/doc-icon.svg" alt="Presentation icon" className="img-fluid" />
                    <span className="d-block">{config.langLabels.presentation}</span>
                  </Link>
                  <FormDocPresentation formType={'presentation'} />
                </li>

                {
                  userType === 'presenter' && <>
                    <li>
                      <Link
                        to=""
                        onClick={(e) => {
                          e.preventDefault()
                          //alert('under development');
                          if (!whiteBoardReduxState.isRecordingOn) {
                            this.props.updateStartScreenShareState(!whiteBoardReduxState.startScreenShareModal)
                          }
                          this.props.updateRecordingOnState(!whiteBoardReduxState.isRecordingOn)
                          hideBootstrapModal('addModal')
                        }}>
                        <img src="/assets/images/screenshare.svg" alt="screenshare icon" className="img-fluid" />
                        <span className="d-block">{whiteBoardReduxState.isRecordingOn ? 'Screen sharing' : 'Screen share'}</span>
                      </Link>
                    </li>

                    <li>
                      <Link
                        to=""
                        data-target="#addWebsitesModal"
                        data-toggle="modal"
                        onClick={(e) => {
                          e.preventDefault()
                          hideBootstrapModal('addModal')
                        }}>
                        <img src="/assets/images/website.svg" alt="website icon" className="img-fluid" />
                        <span className="d-block">{config.langLabels.websiteSurfing}</span>
                      </Link>
                    </li>

                    <li className="border-right-0">
                      <Link
                        to=""
                        data-target="#addPollModal"
                        data-toggle="modal"
                        onClick={(e) => {
                          e.preventDefault()
                          hideBootstrapModal('addModal')
                        }}>
                        <img src="/assets/images/poll.svg" alt="poll icon" className="img-fluid" />
                        <span className="d-block">{config.langLabels.polls}</span>
                      </Link>
                    </li>
                  </>
                }
              </ul>
              <div className="uploadfile">
                <Link
                  to=""
                  data-target="#libraryModal"
                  data-toggle="modal"
                  className="text-center d-block"
                  onClick={(e) => {
                    e.preventDefault()
                    hideBootstrapModal('addModal')
                  }}>
                  <span className="d-inline-block">{config.langLabels.browseUploadLib}</span>
                  <img src="/assets/images/library.svg" alt="library icon" className="img-fluid" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    userReduxState: state.userProps,
    whiteBoardReduxState: state.whiteBoardProps,
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updateRecordingOnState: data => dispatch(updateRecordingOn(data)),
    updateStartScreenShareState: data => dispatch(updateStartScreenShare(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddModal)