import { UPDATE_TOOL_TYPE } from "../reduxConst/toolTypeConst";
import toolTypeState from "../reduxStates/toolTypeState";

let updateToolType = (state, action) => {
  return {
    ...state,
    ...action.data
  }
}

let toolTypeReducer = (state = toolTypeState, action) => {

  switch (action.type) {

    case UPDATE_TOOL_TYPE:

      return updateToolType(state, action)

    default:
      return state;
  }

}

export default toolTypeReducer;