let commonConfig = {
  maxBoards: 10,
  maxVideo: 5,
  maxAudio: 10,
  maxTimeExtendByPresenter: 20, // In minutes
  facebookAppId: '847746268961814',
  facebookAppSecret: '885036c02ce9e545a3d175ed68e5c027',
  facebookAccessToken: 'EAAMDBU84nBYBALgkGUo9tD2CW9NrwCwNITF6c6qN4DssDBVGhoajQGnXay8ugZAXHh5f29fhxplBndJvqIh5f5GHeW9KuQ5TGrb5kaR2OZB3LXQJpd1zEhlkvaQ358anVPGcOwJDk0hj4useRiZA8pSstMvCO54Frvaiq9J5gZDZD',
  youtubeKey: "AIzaSyDn4zBAa-YhAmRshLr6-OVCkAJnLMlU-DM",
  facebookToken: ""
}

export default commonConfig;