import { UPDATE_REQUEST_COUNT, UPDATE_QUESTION_COUNT, UPDATE_ONLINE_ATTENDEE_COUNT } from "../reduxConst/chatConst";

export const updateRequestCount = (data) => {
  return {
    type: UPDATE_REQUEST_COUNT,
    data
  }
}

export const updateQuestionCount = (data) => {
  return {
    type: UPDATE_QUESTION_COUNT,
    data
  }
}

export const updateOnlineAttendeeCount = (data) => {
  return {
    type: UPDATE_ONLINE_ATTENDEE_COUNT,
    data
  }
}