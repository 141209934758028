import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  autoClose: 3000,
  draggable: false,
});

export let handelApiError = (error) => {
  return error.response;
}

export let handelApiRes = (res) => {
  return res
  if (res.status === 200) {
    return res
  } else {
    errorToastr(res.data.message)
  }
}

export let successToastr = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
}

export let errorToastr = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
}