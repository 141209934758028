
import React from 'react';
import { Provider } from 'react-redux';

import Routes from './components/routes';
import appStore from './redux/store';

import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/main.scss';

import 'bootstrap';
import 'bootbox';

export default function RootApp() {
  return (
    <Provider store={appStore}>
      <Routes />
    </Provider>
  )
}
