import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import toolTypeReducer from './toolTypeReducer';
import whiteBoardCollectionReducer from './whiteBoardReducer';
import userReducer from './userReducer';
import chatReducer from './chatReducer';
import audioVideoReducer from './audioVideoReducer';


const RootReducer = combineReducers({
  form: formReducer,
  toolTypeProps: toolTypeReducer,
  whiteBoardProps: whiteBoardCollectionReducer,
  userProps: userReducer,
  chatProps: chatReducer,
  audioVideoProps: audioVideoReducer,
});

export default RootReducer;