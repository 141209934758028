import React, { Component } from 'react'
import bootbox from 'bootbox';
import { resetReactModalForm } from '../../utils/commonJsFunctions';
import config from '../../config';

function SoundMeter(context) {
  this.context = context;
  this.instant = 0.0;
  this.slow = 0.0;
  this.clip = 0.0;
  this.script = context.createScriptProcessor(2048, 1, 1);
  const that = this;
  this.script.onaudioprocess = function (event) {
    const input = event.inputBuffer.getChannelData(0);
    let i;
    let sum = 0.0;
    let clipcount = 0;
    for (i = 0; i < input.length; ++i) {
      sum += input[i] * input[i];
      if (Math.abs(input[i]) > 0.99) {
        clipcount += 1;
      }
    }
    that.instant = Math.sqrt(sum / input.length);
    that.slow = 0.95 * that.slow + 0.05 * that.instant;
    that.clip = clipcount / input.length;
  };
}

SoundMeter.prototype.connectToSource = function (stream, callback) {
  try {
    this.mic = this.context.createMediaStreamSource(stream);
    this.mic.connect(this.script);
    // necessary to make sample run, but should not be.
    this.script.connect(this.context.destination);
    if (typeof callback !== 'undefined') {
      callback(null);
    }
  } catch (e) {
    console.error(e);
    if (typeof callback !== 'undefined') {
      callback(e);
    }
  }
};

SoundMeter.prototype.stop = function () {
  this.mic.disconnect();
  this.script.disconnect();
};

class MediaSettings extends Component {

  state = {
    audioInputSelect: '',
    audioOutputSelect: '',
    videoSelect: '',
    selectors: [],
    instantMeter: '',
    slowMeter: '',
    clipMeter: '',
    instantValueDisplay: '',
    slowValueDisplay: '',
    clipValueDisplay: ''
  }

  componentDidMount() {
    this.checkMedia();
    resetReactModalForm('settingModal', this.resetAudio)
  }

  resetAudio = () => {
    if (this.mic) {
      this.mic.disconnect();
    }
    if (window.stream) {
      window.stream.getTracks().forEach(track => {
        track.stop();
      });
    }
  }

  checkMedia = () => {
    const videoElement = document.getElementById('video-test');
    const audioInputSelect = document.querySelector('select#audioSource');
    const videoSelect = document.querySelector('select#videoSource');
    const selectors = [audioInputSelect, videoSelect]; //audioOutputSelect


    // const instantMeter = document.querySelector('#instant meter');
    // const slowMeter = document.querySelector('#slow meter');
    // const clipMeter = document.querySelector('#clip meter');

    // const instantValueDisplay = document.querySelector('#instant .value');
    // const slowValueDisplay = document.querySelector('#slow .value');
    // const clipValueDisplay = document.querySelector('#clip .value');


    this.setState({
      videoElement,
      audioInputSelect,
      videoSelect,
      selectors: selectors,
      // instantMeter,
      // slowMeter,
      // clipMeter,
      // instantValueDisplay,
      // slowValueDisplay,
      // clipValueDisplay
    }, () => {
      try {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        window.audioContext = new AudioContext();
      } catch (e) {
        alert('Web Audio API not supported.');
      }
      if (navigator.mediaDevices.getDisplayMedia) {
        navigator.getUserMedia = navigator.mozGetUserMedia || navigator.webkitGetUserMedia;
        navigator.mediaDevices.enumerateDevices().then(this.gotDevices).catch(this.handleError);
        audioInputSelect.onchange = this.start;
        videoSelect.onchange = this.start;
        this.start();
      }
    });

  }

  handleError = (error) => {
    // https://stackoverflow.com/a/47551322
    // bootbox.alert("Your webcam is already in use by another app.");
    bootbox.alert(config.langLabels.errMsgMedia);
  }

  gotDevices = (deviceInfos) => {
    const { audioInputSelect, videoSelect, selectors } = this.state;
    // Handles being called several times to update labels. Preserve values.
    const values = selectors.map((select, index) => {
      return select.value
    });
    selectors.forEach(select => {

      while (select.firstChild) {
        select.removeChild(select.firstChild);
      }
    });
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'audioinput') {
        option.text = deviceInfo.label || `microphone ${audioInputSelect.length + 1}`;
        audioInputSelect.appendChild(option);
      } else if (deviceInfo.kind === 'videoinput') {
        option.text = deviceInfo.label || `camera ${videoSelect.length + 1}`;
        videoSelect.appendChild(option);
      } else {
        console.log('Some other kind of source/device: ', deviceInfo);
      }
    }
    selectors.forEach((select, selectorIndex) => {
      if (Array.prototype.slice.call(select.childNodes).some(n => n.value === values[selectorIndex])) {
        select.value = values[selectorIndex];
      }
    });
  }

  start = () => {
    const { audioInputSelect, videoSelect, } = this.state;
    if (window.stream) {
      window.stream.getTracks().forEach(track => {
        track.stop();
      });
    }
    const audioSource = audioInputSelect.value;
    const videoSource = videoSelect.value;
    const constraints = {
      audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
      video: { deviceId: videoSource ? { exact: videoSource } : undefined }
    };
    navigator.mediaDevices.getUserMedia(constraints).then(this.gotStream).then(this.gotDevices).catch(this.handleError);
    //navigator.mediaDevices.getUserMedia(constraints).then(this.handleSuccess).catch(this.handleError);
  }



  gotStream = (stream) => {

    let paths = document.getElementsByTagName('path');
    let visualizer = document.getElementById('visualizer');
    let mask = visualizer.getElementById('mask');
    let path;

    const { videoElement } = this.state;
    window.stream = stream; // make stream available to console
    videoElement.srcObject = stream;
    // Refresh button list in case labels have become available

    const {
      instantMeter,
      slowMeter,
      clipMeter,
      instantValueDisplay,
      slowValueDisplay,
      clipValueDisplay
    } = this.state
    // Put variables in global scope to make them available to the
    // browser console.
    // const soundMeter = window.soundMeter = new SoundMeter(window.audioContext);
    // soundMeter.connectToSource(stream, function (e) {
    // setInterval(() => {
    //   instantMeter.value = instantValueDisplay.innerText =
    //     soundMeter.instant.toFixed(2);
    //   slowMeter.value = slowValueDisplay.innerText =
    //     soundMeter.slow.toFixed(2);
    //   clipMeter.value = clipValueDisplay.innerText =
    //     soundMeter.clip;
    // }, 200);
    // });


    let audioContent = window.audioContext;
    let audioStream = this.mic = audioContent.createMediaStreamSource(stream);
    let analyser = audioContent.createAnalyser();
    audioStream.connect(analyser);
    analyser.fftSize = 1024;

    let frequencyArray = new Uint8Array(analyser.frequencyBinCount);
    visualizer.setAttribute('viewBox', '0 0 255 255');

    for (let i = 0; i < 255; i++) {
      path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      path.setAttribute('stroke-dasharray', '4,1');
      mask.appendChild(path);
    }
    let doDraw = function () {
      requestAnimationFrame(doDraw);
      analyser.getByteFrequencyData(frequencyArray);
      let adjustedLength;
      for (let i = 0; i < 255; i++) {
        adjustedLength = Math.floor(frequencyArray[i]) - (Math.floor(frequencyArray[i]) % 5);
        if (paths[i]) {
          paths[i].setAttribute('d', 'M ' + (i) + ',255 l 0,-' + adjustedLength);
        }
      }

    }
    doDraw();

    return navigator.mediaDevices.enumerateDevices();
  }

  render() {
    return (
      <div className="tab-pane fade show active" id="video" role="tabpanel" aria-labelledby="video-tab">
        <form>
          <div className="row justify-content-center">
            {/* code for video */}
            <div className="col-6">
              <div className="presentationBox">
                <div className="presentationBox__box text-center">
                  <video id="video-test" muted autoPlay width="100%"></video>
                </div>
                <div className="form-group">
                  <select className="selectpicker form-control" id="videoSource"></select>
                </div>
              </div>
            </div>
            {/* code for video */}
            {/* code for audio meter */}
            <div className="col-6">
              <div className="volumeBox">
                <svg preserveAspectRatio="none" id="visualizer" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <mask id="mask">
                      <g id="maskGroup">
                      </g>
                    </mask>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                      <stop offset="0%" style={{ stopColor: "#250157", stopOpacity: 1 }} />
                      <stop offset="20%" style={{ stopColor: "#610DD6", stopOpacity: 1 }} />
                      <stop offset="90%" style={{ stopColor: "#9C55FF", stopOpacity: 1 }} />
                      <stop offset="100%" style={{ stopColor: "#654E85", stopOpacity: 1 }} />
                    </linearGradient>
                  </defs>
                  <rect x="0" y="0" width="100%" height="100%" fill="url(#gradient)" mask="url(#mask)"></rect>
                </svg>
                <div className="form-group">
                  <select className="selectpicker form-control selectOption" id="audioSource"></select>
                </div>
              </div>
              {/* <div className="volumeBox">
                <div className="meterDiv" id="meters">
                  <div className="meterDiv__instant" id="instant">
                    <div className="label">Instant:</div>
                    <meter high="0.25" max="1" value="0"></meter>
                    <div className="value"></div>
                  </div>
                  <div className="meterDiv__slow" id="slow">
                    <div className="label">Slow:</div>
                    <meter high="0.25" max="1" value="0"></meter>
                    <div className="value"></div>
                  </div>
                  <div className="meterDiv__clip" id="clip">
                    <div className="label">Clip:</div>
                    <meter max="1" value="0"></meter>
                    <div className="value"></div>
                  </div>
                </div>
              </div> */}

            </div>
            {/* code for audi meter */}
          </div>
          {/* <div className="text-center btn-row">
            <button type="button" className="btn btn-primary w_170 ripple-effect">Save</button>
          </div> */}
        </form>
      </div>
    )
  }
}


export default MediaSettings