import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import Canvas from "../../components/canvas";
import { addWhiteBoard, removeWhiteBoard, newActiveWhiteBoard, updateRecordingOn, updateStartScreenShare } from '../../redux/actions/whiteBoardAction';
import {
  addWhiteboardSocketOn,
  deleteWhiteboardSocketOn,
  activeWhiteboardSocketOn,
  showPublishPollSocketOn,
  submitPollAnswerSocketOn,
  iframeSocketOn,
  activeWhiteboardSocketEmit,
  iframeWebsiteSocketOn,
  screenShareStartedSocketOn,
  closePollModalsSocketOn
} from '../../socket';
import PostAnswerModal from '../../components/modals/postAnswerModal';
import QuestionModal from '../../components/modals/questionModal';
import { toolTypeData } from '../../utils/toolData';
import { updateToolType } from '../../redux/actions/toolTypeAction';
import SessionService from '../../services/sessionService';
import toolTypeState from '../../redux/reduxStates/toolTypeState';
import PSChatList from '../presenterSupervisorChat';
import AudioGrantedView from '../../components/audioGrantedView';

import ScreenShareViewer from '../../components/video/screenShareViewer';
import { pageLoader, hideBootstrapModal } from '../../utils/commonJsFunctions';
import GroupCallVideo from '../../components/video/groupCallVideo';

class Whiteboard extends Component {

  state = {
    addAnswer: false,
    publishResult: false,
    pollData: [],
    result: false,
    videoScreen: false,
    displayType: ''
  }

  componentDidMount() {
    addWhiteboardSocketOn(this.addWhiteboardOnSocket);
    deleteWhiteboardSocketOn(this.deleteWhiteboardOnSocket);
    activeWhiteboardSocketOn(this.activeWhiteboardOnSocket);
    showPublishPollSocketOn(this.showPublishPoll)
    submitPollAnswerSocketOn(this.showPollResult);
    iframeSocketOn(this.setIframeEvents)
    iframeWebsiteSocketOn(this.setIframeEvents)
    screenShareStartedSocketOn(this.screenShareStartedOnSocket)
    closePollModalsSocketOn(this.closePollModals);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.whiteBoardReduxState.activeWhiteBoardId !== this.props.whiteBoardReduxState.activeWhiteBoardId) {
      this.updateActiveWhiteBoardId()
      this.props.updateToolTypeState(toolTypeState)
    }
    if (prevProps.whiteBoardReduxState.activeWhiteBoardOwner !== this.props.whiteBoardReduxState.activeWhiteBoardOwner) {
      if (
        this.props.whiteBoardReduxState.activeWhiteBoardOwner.userType !== this.props.userReduxState.userType &&
        this.props.whiteBoardReduxState.activeWhiteBoardOwner.userId !== this.props.userReduxState.userId
      ) {
        this.props.updateToolTypeState(toolTypeState)
      }
    }
  }

  closePollModals = () => {
    hideBootstrapModal('addPollAnswerModal');
  }

  updateActiveWhiteBoardId = async () => {
    const { userReduxState: { userType, sessionInfo: { id } }, whiteBoardReduxState: { activeWhiteBoardId } } = this.props
    if (activeWhiteBoardId && userType === 'presenter') {
      let updateBoardIdRes = await SessionService.updateActiveBoardId(id, { boardId: activeWhiteBoardId })
      if (updateBoardIdRes.data.success) {
        activeWhiteboardSocketEmit({ boardId: activeWhiteBoardId });
        setTimeout(() => {
          pageLoader('hide');
        }, 1000 * 5);
      }
    }
  }

  showPublishPoll = (data) => {
    this.setState({
      addAnswer: true,
      pollData: data,
      displayType: 'publishPoll'
    })
  }

  showPollResult = (data) => {
    if (data.type === 'presenter') {
      this.setState({
        pollData: data.pollData,
        publishResult: true,
        result: data.result,
        displayType: 'publishResult'
      });
      hideBootstrapModal('addPollAnswerModal');
    }
  }

  setIframeEvents = (data) => {
    let toolData = toolTypeData(data.tool, data.toolOptions, this.props.toolTypeReduxState)
    this.props.updateToolTypeState(toolData);
  }

  addWhiteboardOnSocket = (data) => {
    const { userReduxState: { userType } } = this.props
    this.props.addWhiteBoardInRedux({ boardId: data.boardId, title: data.title, boardType: data.boardType, frameData: data.frameData })
    this.props.newActiveWhiteBoardInRedux(data.boardId)
  }

  deleteWhiteboardOnSocket = (data) => {
    const { userReduxState: { userType } } = this.props
    this.props.removeWhiteBoardInRedux(data.boardId)
  }

  activeWhiteboardOnSocket = (data) => {
    const { userReduxState: { userType } } = this.props
    this.props.newActiveWhiteBoardInRedux(data.boardId)
  }

  updateToolTypeInRedux = (tool = '', toolOptions = {}) => {
    let data = toolTypeData(tool, toolOptions, this.props.toolTypeReduxState)
    this.props.updateToolTypeState(data)
  }

  screenShareStartedOnSocket = (data) => {
    this.props.updateRecordingOnState(true)
    this.props.updateStartScreenShareState(true)
  }

  render() {
    const { whiteBoardReduxState: { whiteBoards, activeWhiteBoardId }, userReduxState } = this.props
    const { addAnswer, pollData, publishResult, videoScreen, displayType } = this.state;
    let allowToolBarAccess = (
      this.props.whiteBoardReduxState.activeWhiteBoardOwner.userType === userReduxState.userType &&
      this.props.whiteBoardReduxState.activeWhiteBoardOwner.userId === userReduxState.userId
    ) ? true : false
    return (
      <>
        <div className="white-board position-relative" id="whiteBoard">
          <div className="board-inner">
            {
              allowToolBarAccess && <div className="textdropdown position-absolute" id="textDropdown">
                <ul className="list-inline mb-0 itembox">
                  <li className="list-inline-item">
                    <Link
                      to=""
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation();
                        this.updateToolTypeInRedux('updatetext', { textType: 'align', textStyle: 'left' })
                      }}
                    >
                      <i className="icon-format-align-left"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to=""
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation();
                        this.updateToolTypeInRedux('updatetext', { textType: 'align', textStyle: 'center' })
                      }}
                    >
                      <i className="icon-format-align-center"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to=""
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.updateToolTypeInRedux('updatetext', { textType: 'align', textStyle: 'right' })
                      }}
                    >
                      <i className="icon-format-align-right"></i>
                    </Link>
                  </li>

                  <li className="list-inline-item borderleft selectfont">
                    <select id="font-size-tool" onChange={(e) => {
                      this.updateToolTypeInRedux('updatetext', { textType: 'size', textStyle: e.target.value });
                    }} className="form-control p-0 border-0" dir="rtl">
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="18">18</option>
                      <option value="24">24</option>
                      <option value="30">30</option>
                      <option value="35">35</option>
                      <option value="40">40</option>
                    </select>
                  </li>
                  <li className="list-inline-item borderleft">
                    <Link
                      to=""
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.updateToolTypeInRedux('updatetext', { textType: 'underline' });
                      }}
                    >
                      <i className="icon-format_underlined_inactive"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to=""
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.updateToolTypeInRedux('updatetext', { textType: 'italic' });
                      }}
                    >
                      <i className="icon-format-italic"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to=""
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.updateToolTypeInRedux('updatetext', { textType: 'bold' });
                      }}
                    >
                      <i className="icon-format-bold"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            }
            {
              whiteBoards.length > 0 && whiteBoards.map((board, index) => {
                return <div key={Number(board.boardId)} className={`canvasbox ${activeWhiteBoardId === Number(board.boardId) ? 'd-block' : 'd-none'}`}>
                  <Canvas
                    currentWhiteBoardId={Number(board.boardId)}
                    frameData={board.frameData}
                    boardType={board.boardType}
                    canvasData={board.canvas}
                    currentStateIndex={board.currentIndex}
                  />
                </div>
              })
            }
            {
              (('sessionInfo' in userReduxState) && moment(userReduxState.sessionInfo.startDateTime).utc().valueOf() <= moment().utc().valueOf()) && <>
                <GroupCallVideo />
              </>
            }

            {(addAnswer) && <PostAnswerModal pollData={pollData} displayType={displayType} />}
            {(publishResult) && <QuestionModal pollData={pollData} displayType={displayType} />}
          </div>
        </div>

        {
          (Object.keys(userReduxState).length > 0 && userReduxState.userType !== 'attendee') && <PSChatList />
        }
        {/* {
          (Object.keys(userReduxState).length > 0 && userReduxState.userType === 'attendee') && <AudioGrantedView />
        } */}
        {
          (
            Object.keys(userReduxState).length > 0 &&
            userReduxState.userType !== 'presenter' &&
            this.props.whiteBoardReduxState.startScreenShareModal &&
            this.props.whiteBoardReduxState.isRecordingOn
          ) && <ScreenShareViewer />
        }
      </>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    whiteBoardReduxState: state.whiteBoardProps,
    userReduxState: state.userProps,
    toolTypeReduxState: state.toolTypeProps
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    addWhiteBoardInRedux: (data) => dispatch(addWhiteBoard(data)),
    removeWhiteBoardInRedux: (data) => dispatch(removeWhiteBoard(data)),
    newActiveWhiteBoardInRedux: (data) => dispatch(newActiveWhiteBoard(data)),
    updateToolTypeState: data => dispatch(updateToolType(data)),
    updateRecordingOnState: data => dispatch(updateRecordingOn(data)),
    updateStartScreenShareState: data => dispatch(updateStartScreenShare(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Whiteboard)