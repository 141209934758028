import React from 'react';
import RequestList from '../../components/requestList';
import ChatList from '../../components/chatList';
// import QuestionList from '../../components/chatList/questionList';

const ChatTabs = ({ tabName }) => {
  let defaultTab = <></>;

  switch (tabName) {
    case 'request':
      defaultTab = <RequestList />;
      break;
    case 'question':
      // defaultTab = <QuestionList />;
      defaultTab = <ChatList type="question" />;
      break;
    case 'chat':
      defaultTab = <ChatList />;
      break;
    default:
      defaultTab = <></>;
      break;
  }

  return defaultTab;
};

export default ChatTabs;
