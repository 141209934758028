import React from "react"
import config from "../../config";

const PageLoader = () => (
  <div id="pageLoader" style={{ display: 'none' }}>
    <div className="pageLoader d-flex justify-content-center align-items-center">
      <div className="d-block text-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">{config.langLabels.loading}</span>
        </div>
        <span className="loading-text d-block mt-2"></span>
      </div>
    </div>
  </div>
)

export default PageLoader;

